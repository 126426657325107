import React, { useContext } from "react";

// AppContext
import { AppContext } from "../../App";

// Child components
import TopNavbarDesktop from "./TopNavbarDesktop";
import TopNavbarMobile from "./TopNavbarMobile";

const TopNavbar = (props) => {
  const { theme, isSignUp, isError } = props

  const {
    isDesktop
  } = useContext(AppContext)

  return (
    isDesktop ?
      <TopNavbarDesktop theme={theme} isSignUp={isSignUp} isError={isError} />
      :
      <TopNavbarMobile theme={theme} isSignUp={isSignUp} isError={isError} />
  )
}

export default TopNavbar