import React, { useContext, useEffect, useState } from "react";

// import context
import { AppContext } from "../../App";

// import i18n
import { useTranslation } from "react-i18next";

// import theme
import { useTheme } from "@mui/material/styles"

// import constant
import { FontFamily } from "../../Constants/FontFamily";
import { ColorTheme } from "../../Constants/ColorTheme";

// import components
import { Box, CircularProgress, Typography, circularProgressClasses } from "@mui/material";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import DivFlexCenter from "../DivFlexCenter";

// import assets
import FeatureOneBlue from "../../assets/images/feature-1-blue.png";
import FeatureOneDark from "../../assets/images/feature-1-dark.png";
import FeatureOneLight from "../../assets/images/feature-1-light.png";
import FeatureOneYellow from "../../assets/images/feature-1-yellow.png";
import FeatureOneOrange from "../../assets/images/feature-1-orange.png";
import FeatureOneGreen from "../../assets/images/feature-1-green.png";
import FeatureTwoBlue from "../../assets/images/feature-2-blue.png";
import FeatureTwoDark from "../../assets/images/feature-2-dark.png";
import FeatureTwoLight from "../../assets/images/feature-2-light.png";
import FeatureTwoYellow from "../../assets/images/feature-2-yellow.png";
import FeatureTwoOrange from "../../assets/images/feature-2-orange.png";
import FeatureTwoGreen from "../../assets/images/feature-2-green.png";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import { CORE_ACTION_EDIT_OUTLET_EMAIL_ADDRESSES, CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID, CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID_AND_CUSTOMER_NUMBER, CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID, CORE_ACTION_POST_REGISTER_SELECTED_OUTLETS } from "../../Actions/CoreActions";
import NewButton from "../NewButton/NewButton";
import { ButtonSizeEnum } from "../NewButton/ButtonSizeEnum";
import { ButtonVariantEnum } from "../NewButton/ButtonVariantEnum";
import LoadingDialog from "./LoadingDialog";
import { SHOW_OUTLET_LIST_WELCOME_DIALOG } from "../../utils/featureConfig";
import Checkbox from "../Checkbox";

const OutletList = props => {
    const {
        isLoading,
        setIsLoading,
        isDesktop,
        handleCloseDialog,
        customerName,
        outletId,
        setNoSuperAdmin,
        loggedinEmail,
        setHasRegisterSelectedOutlets
    } = useContext(AppContext)

    const { t } = useTranslation()

    // const navigate = useNavigate()

    const theme = useTheme()

    const { data, email, handleToggleOutletToRegister } = props

    const handleRegisterSelectedOutlets = () => {
        setIsLoading(true)
        let body = {
            outlets: [],
            email: loggedinEmail
        }

        data.forEach(outletToRegister => {
            body.outlets.push({
                id: outletToRegister.id,
                checked: outletToRegister.checked,
                isCurrentRegisteredOutlet: outletToRegister.isCurrentRegisteredOutlet
            })
        })

        CORE_ACTION_POST_REGISTER_SELECTED_OUTLETS(
            body,
            res => {
                if (res.data === 'OK' && res.status === 200) {
                    setIsLoading(false)
                    setHasRegisterSelectedOutlets(true)
                    handleCloseDialog()
                }
                else {
                    //TODO: SET ERROR STATE
                    setIsLoading(false)
                }
            },
            err => {
                //TODO: SET ERROR STATE
                setIsLoading(false)
            }
        )
    }

    return (
        <>
            {isLoading ? (
                <CircularProgress
                    sx={{
                        color: theme.palette.background.iconColor,
                        // animationDuration: "0ms",
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    }}
                    thickness={4.6}
                    disableShrink
                />
            ) : (
                <>
                    {data && (
                        <>
                            <DivFlexCenter
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: isDesktop ? 44 : 24,
                                        fontFamily: isDesktop ? FontFamily.PLUSJAKARTASANSBOLD : FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                        lineHeight: isDesktop ? '57px' : 'normal'
                                    }}
                                >
                                    {t("welcomeToCustomerPortal")}
                                </Typography>
                            </DivFlexCenter>

                            <DivFlexCenter
                                sx={{
                                    mt: isDesktop ? '64px' : '22px',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: isDesktop ? 20 : 14,
                                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                        lineHeight: isDesktop ? '28px' : '22px'
                                    }}
                                >
                                    {customerName ? `${t("hallo")}, ${customerName}` : `${t("hallo")}`}
                                </Typography>
                            </DivFlexCenter>

                            <Box
                                sx={{
                                    mt: isDesktop ? '24px' : '16px',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: isDesktop ? 20 : 14,
                                        fontFamily: FontFamily.PLUSJAKARTASANS,
                                        fontWeight: 400
                                    }}
                                >
                                    <span>
                                        {`${t('hereIsYourCustomerNumber')}:`}
                                    </span>
                                    <span
                                        style={{
                                            marginLeft: '6px',
                                            fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                        }}
                                    >
                                        {data[0]?.customerNumber ?? '-'}
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: isDesktop ? 20 : 14,
                                        fontFamily: FontFamily.PLUSJAKARTASANS,
                                        fontWeight: 400,
                                        letterSpacing: isDesktop ? 0 : '-0.03em'
                                    }}
                                >
                                    {t('registerAsSuperAdmin')}
                                </Typography>
                            </Box>

                            <Box sx={{ mt: isDesktop ? '32px' : '16px' }} />

                            <Box
                                sx={{
                                    // border: '1px solid red'
                                    // width: !isDesktop && '-webkit-fill-available',
                                    height: isDesktop ? '250px' : '175px',
                                    overflow: 'auto',
                                    position: 'relative'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isDesktop ? '684px' : '100%',
                                        backgroundColor: theme.palette.variables.cardHover,
                                        padding: isDesktop ? '12px 16px' : '8px 16px',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        position: 'sticky',
                                        top: 0,
                                        left: 0
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: isDesktop ? 18 : 12,
                                            fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                            fontWeight: 400,
                                            lineHeight: isDesktop ? '25px' : '17px'
                                        }}
                                    >
                                        {t("outlets")}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: isDesktop ? 18 : 12,
                                            fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                            fontWeight: 400,
                                            lineHeight: isDesktop ? '25px' : '17px'
                                        }}
                                    >
                                        {data.length}
                                    </Typography>
                                </Box>

                                {data.sort((a, b) => b.isCurrentRegisteredOutlet - a.isCurrentRegisteredOutlet).map(outlet => (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            padding: isDesktop ? '12px 16px' : '8px 16px',
                                            borderBottom: '2px solid #D1D2D1',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Checkbox
                                            disabled={outlet.isCurrentRegisteredOutlet}
                                            checked={outlet.checked}
                                            onChange={handleToggleOutletToRegister(outlet.id)}
                                            label={`${outlet.street}, ${outlet.city} ${outlet.zipcode}`}
                                        />
                                        {/* <Typography
                                            sx={{
                                                color: theme.palette.text.primary,
                                                fontSize: isDesktop ? 18 : 12,
                                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                                fontWeight: 400,
                                                lineHeight: isDesktop ? '25px' : '17px'
                                            }}
                                        >
                                            {`${outlet.street}, ${outlet.city} ${outlet.zipcode}`}
                                        </Typography> */}
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={{ mt: isDesktop ? '102px' : '16px' }} />

                            {isDesktop ? (
                                <Box
                                    sx={{
                                        width: '100%',
                                        // border: '1px solid red',
                                    }}
                                >
                                    <DivFlexSpaceBetween>
                                        {/* <Typography
                                            sx={{
                                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                                fontSize: 18,
                                                textDecorationLine: 'underline',
                                                color: '#2196F3',
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleCloseDialog}
                                        // onClick={resendActivationEmailLoading ? undefined : resendActivation}
                                        >
                                            {`Cancel`}
                                            {`${t("resendActivationEmail")} ${resendTimer > 0 ? `(${resendTimer})` : ``}`}
                                        </Typography> */}

                                        <NewButton
                                            size={ButtonSizeEnum.LARGE}
                                            variant={ButtonVariantEnum.TERTIARY}
                                            onClick={handleCloseDialog}
                                        >
                                            {t('cancel')}
                                        </NewButton>

                                        <Box
                                            sx={{ display: 'flex', gap: '16px' }}
                                        >
                                            {/* <NewButton
                                                size={ButtonSizeEnum.LARGE}
                                                variant={ButtonVariantEnum.SECONDARY}
                                                onClick={handleUpdateEmail('ADMIN')}
                                            >
                                                {t('setAsAdmin')}
                                            </NewButton> */}

                                            <NewButton
                                                size={ButtonSizeEnum.LARGE}
                                                variant={ButtonVariantEnum.PRIMARY}
                                                onClick={handleRegisterSelectedOutlets}
                                            >
                                                {t('continue')}
                                            </NewButton>
                                        </Box>
                                    </DivFlexSpaceBetween>
                                </Box>
                            ) : (
                                <DivFlexCenter
                                    sx={{
                                        flexDirection: 'column',
                                        gap: '8px',
                                        width: '100%'
                                    }}
                                >
                                    <NewButton
                                        variant={ButtonVariantEnum.PRIMARY}
                                        size={ButtonSizeEnum.SMALL}
                                        isFullWidth
                                        onClick={handleRegisterSelectedOutlets}
                                    >
                                        {t('continue')}
                                    </NewButton>
                                    {/* <NewButton
                                        variant={ButtonVariantEnum.SECONDARY}
                                        size={ButtonSizeEnum.SMALL}
                                        isFullWidth
                                        onClick={handleUpdateEmail('ADMIN')}
                                    >
                                        {t('setAsAdmin')}
                                    </NewButton> */}
                                    <NewButton
                                        variant={ButtonVariantEnum.TERTIARY}
                                        size={ButtonSizeEnum.SMALL}
                                        onClick={handleCloseDialog}
                                    >
                                        {t('cancel')}
                                    </NewButton>
                                </DivFlexCenter>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

const OldContent = props => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { isDesktop, mode, customerName } = useContext(AppContext)

    const getFeatureImage = (type) => {
        if (type === 1) {
            switch (mode) {
                case ColorTheme.BLUE:
                    return FeatureOneBlue
                case ColorTheme.DARK:
                    return FeatureOneDark
                case ColorTheme.YELLOW:
                    return FeatureOneYellow
                case ColorTheme.ORANGE:
                    return FeatureOneOrange
                case ColorTheme.GREEN:
                    return FeatureOneGreen
                default:
                    return FeatureOneLight
            }
        }

        if (type === 2) {
            switch (mode) {
                case ColorTheme.BLUE:
                    return FeatureTwoBlue
                case ColorTheme.DARK:
                    return FeatureTwoDark
                case ColorTheme.YELLOW:
                    return FeatureTwoYellow
                case ColorTheme.ORANGE:
                    return FeatureTwoOrange
                case ColorTheme.GREEN:
                    return FeatureTwoGreen
                default:
                    return FeatureTwoLight
            }
        }
    }

    return (
        <>
            <DivFlexCenter
                sx={{
                    textAlign: 'center'
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: isDesktop ? 44 : 24,
                        fontFamily: isDesktop ? FontFamily.PLUSJAKARTASANSBOLD : FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        lineHeight: isDesktop ? '57px' : 'normal'
                    }}
                >
                    {t("welcomeToCustomerPortal")}
                </Typography>
            </DivFlexCenter>

            <DivFlexCenter
                sx={{
                    mt: isDesktop ? '64px' : '22px',
                    textAlign: 'center'
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: isDesktop ? 20 : 14,
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        lineHeight: isDesktop ? '28px' : '22px'
                    }}
                >
                    {customerName ? `${t("hallo")}, ${customerName}` : `${t("hallo")}`}
                </Typography>
            </DivFlexCenter>

            <DivFlexCenter
                sx={{
                    mt: '24px',
                    p: isDesktop && '0 98px',
                    textAlign: 'center'
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: isDesktop ? 18 : 14,
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontWeight: 400,
                        letterSpacing: isDesktop ? 0 : '-0.03em'
                    }}
                >
                    {`${t("wereExcitedToHaveYouAsPart")}
                        ${isDesktop ? ` ${t("discoverOurRangeOfFeatures")}` : ''}`}
                </Typography>
            </DivFlexCenter>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    mt: isDesktop ? 10 : 6,
                    p: isDesktop ? '0 178px' : 0,
                    gap: 5
                }}
            >
                <DivFlexCenter
                    sx={{
                        gap: isDesktop ? 6 : 2,
                        maxWidth: !isDesktop ? '500px' : '100%',
                        ml: 'auto',
                        mr: 'auto'
                    }}
                >
                    <Box
                        component="img"
                        alt="feature-1"
                        src={getFeatureImage(1)}
                        sx={{
                            width: isDesktop ? '176px' : '104px',
                            height: isDesktop ? '176px' : '104px',
                            borderRadius: 2
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isDesktop ? 3 : 1
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 20 : 12,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            }}
                        >
                            {t("realTimeETADelivery")}
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 18 : 12,
                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                fontWeight: 400
                            }}
                        >
                            {t("wereKeepingYouClose")}
                        </Typography>
                    </Box>
                </DivFlexCenter>
                <DivFlexCenter
                    sx={{
                        gap: isDesktop ? 6 : 2,
                        maxWidth: !isDesktop ? '500px' : '100%',
                        ml: 'auto',
                        mr: 'auto'
                    }}
                >
                    <Box
                        component="img"
                        alt="feature-1"
                        src={getFeatureImage(2)}
                        sx={{
                            width: isDesktop ? '176px' : '104px',
                            height: isDesktop ? '176px' : '104px',
                            borderRadius: 2
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isDesktop ? 3 : 1
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 20 : 12,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            }}
                        >
                            {t("latestNewsAndPromo")}
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isDesktop ? 18 : 12,
                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                fontWeight: 400
                            }}
                        >
                            {t("showTheLatestNewsAndPromotions")}
                        </Typography>
                    </Box>
                </DivFlexCenter>
            </Box>
        </>
    )
}

const WelcomeDialog = (props) => {
    const {
        isDesktop,
        handleCloseDialog,
        outletId,
        loggedinEmail,
        setIsSuperAdmin,
        setListOfOutlets
    } = useContext(AppContext)

    const { isOpen } = props

    const theme = useTheme()

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [outletsForRegistration, setOutletsForRegistration] = useState([])

    const handleToggleOutletToRegister = id => () => {
        const newData = [...outletsForRegistration]

        newData.forEach(data => {
            if (data.id === id) {
                data.checked = !data.checked
            }
        })

        setOutletsForRegistration(newData)
    }

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true)
            CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID_AND_CUSTOMER_NUMBER(
                outletId,
                res => {
                    if (res.data) {
                        setData(res.data)
                        setIsLoading(false)
                        const newData = []

                        res.data.forEach(data => {
                            newData.push({
                                id: data.id,
                                customerNumber: data.customer_number,
                                street: data.street,
                                city: data.city,
                                zipcode: data.zipcode,
                                isCurrentRegisteredOutlet: parseInt(outletId, 10) === parseInt(data.id, 10),
                                checked: parseInt(outletId, 10) === parseInt(data.id, 10) ? true : false
                            })
                        })

                        setOutletsForRegistration(newData)

                        // CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID(
                        //     null,
                        //     outletId,
                        //     res => {
                        //         if (res.data && res.data.length > 0) {
                        //             res.data.forEach(emailObj => {
                        //                 if (emailObj.email === loggedinEmail) {
                        //                     setEmail({ ...emailObj })
                        //                 }
                        //             })
                        //         }
                        //         setIsLoading(false)
                        //     },
                        //     err => {
                        //         // console.log(err)
                        //         setIsError(true)
                        //         setIsLoading(false)
                        //     }
                        // )
                    }
                    else {
                        // setIsError(true)
                        setIsLoading(false)
                    }
                },
                err => {
                    // setIsError(true)
                    setIsLoading(false)
                })
        }
    }, [isOpen])

    return (
        <>
            {isLoading ? (
                <LoadingDialog />
            )
                :
                (
                    <CustomDialog
                        open={isOpen}
                        onClose={handleCloseDialog}
                        theme={theme}
                        maxWidth={'md'}
                    >
                        <CustomDialogContent
                            isMobile={!isDesktop}
                            theme={theme}
                            sx={{
                                padding: isDesktop ? '32px 40px 64px 40px' : '24px 16px 24px 16px',
                                backgroundColor: theme.palette.background.dialog,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                transition: '0.3s all ease-in-out'
                            }}
                        >
                            {SHOW_OUTLET_LIST_WELCOME_DIALOG ? (
                                <>
                                    {(!isError) && (
                                        <OutletList
                                            data={outletsForRegistration}
                                            handleCloseDialog={handleCloseDialog}
                                            handleToggleOutletToRegister={handleToggleOutletToRegister}
                                        />
                                    )}
                                </>
                            ) : (
                                <OldContent />
                            )}
                        </CustomDialogContent>
                    </CustomDialog>
                )
            }
        </>
    )
}

export default WelcomeDialog