import React from "react";

import { SvgIcon } from "@mui/material";

const AlertCircle = props => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M8.67317 2.7612C9.88642 2.25866 11.1868 2 12.5 2C13.8132 2 15.1136 2.25866 16.3268 2.7612C17.5401 3.26375 18.6425 4.00035 19.5711 4.92893C20.4997 5.85752 21.2362 6.95991 21.7388 8.17317C22.2413 9.38642 22.5 10.6868 22.5 12C22.5 13.3132 22.2413 14.6136 21.7388 15.8268C21.2362 17.0401 20.4997 18.1425 19.5711 19.0711C18.6425 19.9997 17.5401 20.7362 16.3268 21.2388C15.1136 21.7413 13.8132 22 12.5 22C11.1868 22 9.88642 21.7413 8.67317 21.2388C7.45991 20.7362 6.35752 19.9997 5.42893 19.0711C4.50035 18.1425 3.76375 17.0401 3.2612 15.8268C2.75866 14.6136 2.5 13.3132 2.5 12C2.5 10.6868 2.75866 9.38642 3.2612 8.17317C3.76375 6.95991 4.50035 5.85752 5.42893 4.92893C6.35752 4.00035 7.45991 3.26375 8.67317 2.7612ZM12.5 4C11.4494 4 10.4091 4.20693 9.43853 4.60896C8.46793 5.011 7.58601 5.60028 6.84315 6.34315C6.10028 7.08601 5.511 7.96793 5.10896 8.93853C4.70693 9.90914 4.5 10.9494 4.5 12C4.5 13.0506 4.70693 14.0909 5.10896 15.0615C5.511 16.0321 6.10028 16.914 6.84315 17.6569C7.58601 18.3997 8.46793 18.989 9.43853 19.391C10.4091 19.7931 11.4494 20 12.5 20C13.5506 20 14.5909 19.7931 15.5615 19.391C16.5321 18.989 17.414 18.3997 18.1569 17.6569C18.8997 16.914 19.489 16.0321 19.891 15.0615C20.2931 14.0909 20.5 13.0506 20.5 12C20.5 10.9494 20.2931 9.90914 19.891 8.93853C19.489 7.96793 18.8997 7.08602 18.1569 6.34315C17.414 5.60028 16.5321 5.011 15.5615 4.60896C14.5909 4.20693 13.5506 4 12.5 4ZM12.5 7C13.0523 7 13.5 7.44772 13.5 8V12C13.5 12.5523 13.0523 13 12.5 13C11.9477 13 11.5 12.5523 11.5 12V8C11.5 7.44772 11.9477 7 12.5 7ZM12.5 15C11.9477 15 11.5 15.4477 11.5 16C11.5 16.5523 11.9477 17 12.5 17H12.51C13.0623 17 13.51 16.5523 13.51 16C13.51 15.4477 13.0623 15 12.51 15H12.5Z" />
        </SvgIcon>
    )
}

export default AlertCircle