import React from "react"

import { SvgIcon } from "@mui/material"

export const DeleteIcon = props => {
    return (
        <SvgIcon {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M9.58579 2.58579C9.96086 2.21071 10.4696 2 11 2H15C15.5304 2 16.0391 2.21071 16.4142 2.58579C16.7893 2.96086 17 3.46957 17 4V6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20.9202L19.9997 19.0458C19.9878 19.8249 19.6732 20.5696 19.1214 21.1214C18.5588 21.684 17.7957 22.0001 17.0001 22.0001H9.00006C8.20441 22.0001 7.44134 21.684 6.87874 21.1214C6.32693 20.5696 6.01228 19.8249 6.0004 19.0458L5.07992 8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H9V4C9 3.46957 9.21071 2.96086 9.58579 2.58579ZM15 4V6H11V4H15ZM7.08685 8H10H16H18.9133L18.0035 18.917C18.0012 18.9446 18.0001 18.9723 18.0001 19.0001C18.0001 19.2653 17.8947 19.5196 17.7072 19.7072C17.5196 19.8947 17.2653 20.0001 17.0001 20.0001H9.00006C8.73484 20.0001 8.48049 19.8947 8.29295 19.7072C8.10541 19.5196 8.00006 19.2653 8.00006 19.0001C8.00006 18.9723 7.9989 18.9446 7.9966 18.917L7.08685 8ZM13 12.5858L11.7071 11.2929C11.3166 10.9024 10.6834 10.9024 10.2929 11.2929C9.90237 11.6834 9.90237 12.3166 10.2929 12.7071L11.5858 14L10.2929 15.2929C9.90237 15.6834 9.90237 16.3166 10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L13 15.4142L14.2929 16.7071C14.6834 17.0976 15.3166 17.0976 15.7071 16.7071C16.0976 16.3166 16.0976 15.6834 15.7071 15.2929L14.4142 14L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929C15.3166 10.9024 14.6834 10.9024 14.2929 11.2929L13 12.5858Z" />
        </SvgIcon>
    )

}

export default DeleteIcon