import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../App';
import { Box, Drawer, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled, useTheme } from '@mui/material';
import CloseIcon from '../../assets/icons/CloseIcon';
// import { CustomTooltip } from '../CustomTooltip';
// import DivFlexSpaceBetween from '../DivFlexSpacebetween';
// import SettingIcon from '../../assets/icons/SettingIcon';
import BellIcon from '../../assets/icons/BellIcon';
import ListIcon from '../../assets/icons/ListIcon';
import LogoutIcon from '../../assets/icons/LogoutIcon';
import { FontFamily } from '../../Constants/FontFamily';
import { useTranslation } from 'react-i18next';
import { getTextRoles } from '../../utils/util';
import { RoleEnum } from '../../Constants/RoleEnum';
import { useNavigate } from 'react-router-dom';
import { UrlPage } from '../../Constants/UrlPage';

const NavigationDrawer = (props) => {

    const { t } = useTranslation()

    // icon button component
    // const IconButton = styled("div")((props) => ({
    //     display: "flex",
    //     alignItems: "center",
    //     cursor: "pointer",
    //     width: 30,
    //     height: 30,
    //     borderRadius: "50%",
    //     justifyContent: "center",
    //     ":hover": {
    //         backgroundColor: props.theme.palette.background.hoverIconButton,
    //     },
    // }));

    const DrawerMenuItem = styled(MenuItem)((props) => ({
        ':hover': {
            backgroundColor: theme.palette.background.menuItemHover
        },
        ':active': {
            backgroundColor: theme.palette.background.menuItemClicked
        },
        ...(props.active) && {
            backgroundColor: theme.palette.background.menuItemActive,
        },
        '.MuiListItemIcon-root': {
            color: props.active ? theme.palette.background.menuItemActiveIcon : theme.palette.background.iconColor,
            minWidth: '0px'
        },
        '.MuiTouchRipple-child': {
            backgroundColor: theme.palette.background.menuItemActive
        },
        padding: isMobile ? "16px" : "16px 24px",
        gap: "16px",
        width: "100%",
        alignItems: "flex-start"
    }));

    const DrawerListItemText = styled(ListItemText)((props) => ({
        '& .MuiListItemText-primary': {
            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
            color: theme.palette.background.title,
            fontStyle: "normal",
            lineHeight: "27px",
            fontSize: props.isMobile ? 14 : 18,
            textWrap: 'wrap'
        }

    }))

    const {
        isMobile,
        isDesktop,
        userRole,
        loggedinEmail,
        emailListDialog,
        openLogoutDialog,
        emailNotificationDialog,
        handleOpenDialog,
        currentEmail
    } = useContext(AppContext);

    const { anchorNavigationDrawerEl, isNavigationDrawerOpen, handleCloseNavigationDrawer, setAnchorNavigationDrawerEl } = props;

    const theme = useTheme();
    const navigate = useNavigate();

    const handleMoveToConfigurationPage = () => {
        navigate(UrlPage.CONFIGURATION)
        handleCloseNavigationDrawer()
    }

    useEffect(() => {
        if (isDesktop) {
            setAnchorNavigationDrawerEl(null)
        }
    }, [isDesktop, setAnchorNavigationDrawerEl])

    return (
        <>
            {!isDesktop ?
                <>
                    <Drawer
                        sx={{
                            width: 260,
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: 260,
                                backgroundColor: theme.palette.background.drawerPaper
                                // background: 'red'
                            },
                            "& .MuiModal-backdrop": {
                                backdropFilter: 'blur(20px)',
                                background: theme.palette.background.dialogBlanket
                            }
                        }}
                        anchor="right"
                        open={anchorNavigationDrawerEl !== null}
                        onClose={handleCloseNavigationDrawer}
                    >
                        <Box sx={{
                            // marginTop: "72px",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingX: "24px",
                            paddingY: "20px",
                            borderBottom: `1px solid ${theme.palette.background.drawerSeparator}`,
                            marginBottom: "16px"
                        }}>
                            <Grid container sx={{ gap: 0.5 }}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                            color: theme.palette.background.title,
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                        }}
                                        fontSize={20}
                                    >
                                        {getTextRoles(currentEmail.role, t)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontFamily: FontFamily.PLUSJAKARTASANS,
                                            color: theme.palette.background.title,
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "normal",
                                            wordBreak: 'break-all'
                                        }}
                                        fontSize={12}
                                    >
                                        {loggedinEmail}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                onClick={handleCloseNavigationDrawer}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <CloseIcon
                                    color={theme.palette.background.iconColor}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                />
                            </Box>
                        </Box>

                        {/* <DrawerMenuItem active={emailNotificationDialog} onClick={() => handleOpenDialog("emailNotification")}>
                            <ListItemIcon>
                                <BellIcon color={theme.palette.background.iconColor} />
                            </ListItemIcon>
                            <DrawerListItemText isMobile>
                                {t("emailAutomation")}
                            </DrawerListItemText>
                        </DrawerMenuItem> */}
                        {(currentEmail.role === RoleEnum.ADMIN ||
                            currentEmail.role === RoleEnum.SUPER_ADMIN) &&
                            <DrawerMenuItem active={emailListDialog} onClick={handleMoveToConfigurationPage}>
                                <ListItemIcon>
                                    <ListIcon color={theme.palette.background.iconColor} />
                                </ListItemIcon>
                                <DrawerListItemText isMobile>
                                    {t("emailList")}
                                </DrawerListItemText>
                            </DrawerMenuItem>
                        }
                        <DrawerMenuItem
                            onClick={() => handleOpenDialog('logout')}
                            sx={{ position: "absolute", bottom: "16px" }}
                        >
                            <ListItemIcon>
                                <LogoutIcon color={theme.palette.background.iconColor} />
                            </ListItemIcon>
                            <DrawerListItemText isMobile>
                                {t("signOut")}
                            </DrawerListItemText>
                        </DrawerMenuItem>
                    </Drawer>
                </> : <Menu
                    id="navigation-drawer"
                    anchorEl={anchorNavigationDrawerEl}
                    open={isNavigationDrawerOpen}
                    onClose={handleCloseNavigationDrawer}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        "& .MuiMenu-paper": {
                            backgroundColor: theme.palette.background.drawerColor,
                            borderRadius: 0
                        },
                        zIndex: 1600
                    }}
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingX: "24px",
                        paddingY: "20px",
                        // borderBottom: `1px solid #a8a8a8`,
                        borderBottom: `1px solid ${theme.palette.background.drawerSeparator}`,
                        marginBottom: "16px"
                    }}>
                        <Grid container sx={{ gap: 1 }}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                        color: theme.palette.background.title,
                                        fontStyle: "normal",
                                        lineHeight: "30px",
                                    }}
                                    fontSize={22}
                                >
                                    {getTextRoles(currentEmail.role, t)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: FontFamily.PLUSJAKARTASANS,
                                        color: theme.palette.background.title,
                                        fontStyle: "normal",
                                    }}
                                    fontSize={14}
                                >
                                    {loggedinEmail}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <CustomTooltip title="Close"> */}
                        <Box>
                            <CloseIcon
                                color={theme.palette.background.iconColor}
                                onClick={handleCloseNavigationDrawer}
                            />
                        </Box>
                        {/* </CustomTooltip> */}
                    </Box>
                    {/* {currentEmail.role === RoleEnum.SUPER_ADMIN && (
                        <DrawerMenuItem active={emailNotificationDialog} onClick={() => handleOpenDialog("emailNotification")}>
                            <ListItemIcon>
                                <BellIcon color={theme.palette.background.iconColor} />
                            </ListItemIcon>
                            <DrawerListItemText>
                                {t("emailAutomation")}
                            </DrawerListItemText>
                        </DrawerMenuItem>
                    )} */}
                    {/* {(currentEmail.role === RoleEnum.ADMIN ||
                        currentEmail.role === RoleEnum.SUPER_ADMIN) &&
                        <DrawerMenuItem active={emailListDialog} onClick={() => handleOpenDialog("emailList")}>
                            <ListItemIcon>
                                <ListIcon color={theme.palette.background.iconColor} />
                            </ListItemIcon>
                            <DrawerListItemText>
                                {t("emailList")}
                            </DrawerListItemText>
                        </DrawerMenuItem>
                    }
                    <DrawerMenuItem
                        active={openLogoutDialog}
                        // onClick={() => handleOpenDialog('logout')}
                        onClick={undefined}
                        sx={{ marginTop: 8 }}
                        disabled
                    >
                    } */}
                    {(currentEmail.role === RoleEnum.ADMIN ||
                        currentEmail.role === RoleEnum.SUPER_ADMIN) &&
                        <DrawerMenuItem active={emailListDialog} onClick={handleMoveToConfigurationPage}>
                            <ListItemIcon>
                                <ListIcon color={theme.palette.background.iconColor} />
                            </ListItemIcon>
                            <DrawerListItemText>
                                {t("emailList")}
                            </DrawerListItemText>
                        </DrawerMenuItem>
                    }
                    <DrawerMenuItem
                        active={openLogoutDialog}
                        onClick={() => handleOpenDialog('logout')}
                        sx={{ marginTop: 8 }}
                    >
                        <ListItemIcon >
                            <LogoutIcon color={theme.palette.background.iconColor} />
                        </ListItemIcon>
                        <DrawerListItemText>
                            {t("signOut")}
                        </DrawerListItemText>
                    </DrawerMenuItem>
                </Menu>
            }
        </>
    )
}

export default NavigationDrawer