import React, { useContext } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import context
import { AppContext } from "../../App";

// import constant
import { FontFamily } from "../../Constants/FontFamily";

// import style and theme
import { useTheme } from "@mui/material/styles";

// import components
import { Box, Typography } from "@mui/material";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import CustomDialog from "./DialogContainer/CustomDialog";
import DivFlexCenter from "../DivFlexCenter";
import Button from "../Button";
import DivFlexStart from "../DivFlexStart";

const EmailNotificationSaveDialog = (props) => {
    const {
        handleCloseDialog,
        handleOpenDialog,
        isMobile,
        isDesktop
    } = useContext(AppContext)

    const { isOpen } = props

    const { t } = useTranslation()

    const theme = useTheme()

    const handleDiscard = () => {
        handleCloseDialog()
    }

    const handleSave = () => {
        handleCloseDialog()
    }

    const handleCancel = () => {
        handleOpenDialog('emailNotification')
    }

    return (
        <CustomDialog
            open={isOpen}
            onClose={handleCloseDialog}
            theme={theme}
            width={isDesktop && 1000}
        >
            <CustomDialogContent
                sx={{
                    padding: isMobile ? '12px 16px 24px 16px' : '0 40px 64px 40px'
                }}
            >
                <DivFlexCenter sx={{ textAlign: 'center', mb: isMobile ? '20px' : '56px' }}>
                    <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 22 : 48 }}>
                        {t("saveChanges")}
                    </Typography>
                </DivFlexCenter>
                <DivFlexStart sx={{ textAlign: 'left', mb: isMobile ? '54px' : '80px' }}>
                    <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontSize: isMobile ? 12 : 21 }}>
                        {t("saveWithoutChanges")}
                    </Typography>
                </DivFlexStart>

                {!isDesktop ?
                    <Box>
                        <Button style={{ width: '100%', mb: '16px' }} onClick={() => handleSave()}>
                            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 18 }}>
                                {t("save")}
                            </Typography>
                        </Button>
                        <Button
                            isSecondary
                            style={{ width: '100%', backgroundColor: 'transparent', border: `2px solid ${theme.palette.background.buttonDefault}`, marginBottom: '12px' }}
                            onClick={() => handleDiscard()}
                        >
                            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 18 }}>
                                {t("discard")}
                            </Typography>
                        </Button>
                        <DivFlexCenter>
                            <Typography
                                sx={{
                                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                    fontSize: isMobile ? 14 : 18,
                                    textDecorationLine: 'underline'
                                }}
                                onClick={() => handleCancel()}
                            >
                                {t("cancel")}
                            </Typography>
                        </DivFlexCenter>
                    </Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                fontSize: 21,
                                textDecorationLine: 'underline'
                            }}
                            onClick={() => handleCancel()}
                        >
                            {t("cancel")}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                            <Button
                                isSecondary
                                style={{
                                    width: '100%',
                                    padding: '16px 40px',
                                    height: 'unset',
                                    backgroundColor: 'transparent',
                                    border: `2px solid ${theme.palette.background.buttonDefault}`
                                }}
                                onClick={() => handleDiscard()}
                            >
                                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 24 }}>
                                    {t("discard")}
                                </Typography>
                            </Button>
                            <Button
                                style={{
                                    width: '100%',
                                    padding: '16px 85px',
                                    height: 'unset'
                                }}
                                onClick={() => handleSave()}
                            >
                                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 24 }}>
                                    {t("save")}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                }
            </CustomDialogContent>
        </CustomDialog>
    )
}

export default EmailNotificationSaveDialog