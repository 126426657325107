import React, { useContext } from "react";

// import router
import { useNavigate } from "react-router-dom";

// import color theme
import { useTheme } from "@mui/material/styles";

// import material Components
import { Box, Typography } from "@mui/material";

// import component
import DivFlexCenter from "../../Components/DivFlexCenter";
import DivFlexStart from "../../Components/DivFlexStart";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";
import Button from "../../Components/Button";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const LinkExpiredStatus = props => {

    const { isDesktop, isMobile } = useContext(AppContext);

    const navigate = useNavigate()

    // color theme
    const theme = useTheme()

    const { t } = useTranslation()

    return (
        <DivFlexStart sx={{ width: '100%', flexWrap: 'wrap', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box>
                <DivFlexCenter sx={{ width: "100%" }}>
                    <Box sx={{ width: "fit-content" }}>
                        <DivFlexStart sx={{ width: '100%', marginTop: isMobile ? '80px' : '20%', marginBottom: '24px' }}>
                            <Typography fontSize={isMobile ? 40 : 64} color={theme.palette.background.iconStatus} sx={{ fontFamily: FontFamily.PLUSJAKARTASANS }}>
                                {`:(`}
                            </Typography>
                        </DivFlexStart>
                        <DivFlexCenter sx={{ width: '100%', marginBottom: isMobile ? '40px' : '64px' }}>
                            <Typography fontSize={isMobile ? 14 : 28} color={theme.palette.text.primary} sx={{ fontFamily: FontFamily.PLUSJAKARTASANS }}>
                                {t("yourLinkHasExpired")}
                            </Typography>
                        </DivFlexCenter>
                    </Box>
                </DivFlexCenter>
                <DivFlexCenter>
                    <Button
                        onClick={() => navigate(`/`)} // TO DO: Update this navigation later
                        isPromo={true}
                        style={{ marginBottom: !isDesktop && '160px' }}
                        size={isMobile ? "small" : "large"}
                    >
                        {t("goToYourOrder")}
                    </Button>
                </DivFlexCenter>
            </Box>
        </DivFlexStart>
    )
}


export default LinkExpiredStatus