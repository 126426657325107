import { Typography } from "@mui/material"

import DivFlexCenter from "../Components/DivFlexCenter"

import { FontFamily } from "../Constants/FontFamily"

// import Animations
import {
    PageNotFoundDark,
    PageNotFoundLight
} from "../Components/Animations/Animations";

import Lottie from "react-lottie";

import { AppContext } from "../App";
import { useContext } from "react";
import { ColorTheme } from "../Constants/ColorTheme";
import { useTranslation } from "react-i18next";
import { isPWAiOS } from "../utils/util";

const PageNotFound = () => {
    const {
        isDesktop,
        isMobile,
        mode
    } = useContext(AppContext)

    const { t } = useTranslation()

    return (
        <DivFlexCenter
            sx={{
                height: isDesktop ? 'calc(100vh)' : '100%'
            }}
        >
            <div
                style={{
                    paddingLeft: isMobile && 16,
                    paddingRight: isMobile && 16
                }}
            >
                <Lottie
                    options={mode === ColorTheme.DARK ? PageNotFoundDark : PageNotFoundLight}
                />

                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                        fontSize: 22,
                        lineHeight: '30.36px',
                        paddingTop: '40px',
                        textAlign: 'center'
                    }}
                >
                    {isPWAiOS() ? t("pageNotFoundIosPwa") : t("pageNotFound")}
                </Typography>
            </div>

        </DivFlexCenter>
    )
}

export default PageNotFound