import React, { useContext, useEffect, useState } from 'react'
import CustomDialog from './DialogContainer/CustomDialog'
import { Box, FormGroup, Grid, Typography } from '@mui/material'
import CustomDialogContent from './DialogContainer/CustomDialogContent'
import { AppContext } from '../../App'
import { useTheme } from "@mui/material/styles";
import DivFlexCenter from '../DivFlexCenter'
import { FontFamily } from '../../Constants/FontFamily'
import Checkbox from '../Checkbox'
import DivFlexSpaceBetween from '../DivFlexSpacebetween'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import { SignUpPages } from '../../Constants/SignUpPages'
import { TimerResendActivation } from '../../Constants/TimerResendActivation'
import { SignUpContext } from '../../Pages/SignUp/SignUp'
import { CORE_ACTION_POST_SIGN_UP_SEND_ACTIVATION_EMAIL } from '../../Actions/CoreActions'
import EmailAutomationForm from '../EmailAutomationForm'
import LoadingDialog from './LoadingDialog'
import { areAllValuesFalse } from '../../utils/util'

const EmailNotificationForEmailValidationDialog = (props) => {
    const {
        isMobile,
        isDesktop,
        setCurrentPage,
        setExpiredTimer,
        setResendTimer,
        showEtaSettingList,
        etaUserConfig,
        defaultEtaUserConfig,
        etaBehaviorConfig
    } = useContext(AppContext)

    const { registrationCode, email, setIsEmailExist } = useContext(SignUpContext)

    const { isOpen, handleClose } = props

    const theme = useTheme()

    const { t } = useTranslation()

    //IMPORTANT NOTES: isHourBeforeArrrival for 15minutes Flag and isTwoHoursBeforeArrrival FOR 1 and 2 hours before arriving flag, we do this so GL do not have to do any changes.
    // const dummyData = {
    //     isDeliveryTimeFinalized: true,
    //     isDeliveryCanceled: true,
    //     isChangeInETA: false,
    //     isFifteenMinutesBeforeArrival: false,
    //     isOneHourBeforeArrival: false,
    //     isTwoHoursBeforeArrival: false,
    //     isHourAndTwoHoursBeforeArrrival: false,
    //     isDeliveryOnTheWay: false,
    //     changeInETA: null,
    //     etaTime: null
    // }
    const dummyData = {
        isDeliveryConfirmed: false,
        isDeliveryCanceled: false,
        isDeliveryStarted: false,
        isEtaAnnounced: false,
        isEtaUpdated: false,
        isEtaChanged: false
    }

    const [emailNotification, setEmailNotification] = useState(dummyData)

    const [allEtaEmailSelected, setAllEtaEmailSelected] = useState(false);

    const [initialData, setInitialData] = useState({});

    const [isSetToDefaultDisabled, setIsSetToDefaultDisabled] = useState(true);

    const [isChangesHappened, setIsChangesHappened] = useState(false)

    const [isHourAndTwoHourEtaEmailSelected, setIsHourAndTwoHourEtaEmailSelected] = useState(false);

    const [isLoading, setIsLoading] = useState(true)

    const [selectAllConfig, setSelectAllConfig] = useState(false)

    const [isAllSettingsDisabled, setIsAllSettingsDisabled] = useState(false)

    useEffect(() => {
        if (JSON.stringify(initialData) === JSON.stringify(emailNotification)) {
            setIsSetToDefaultDisabled(true)
        } else {
            setIsSetToDefaultDisabled(false)
        }
    }, [emailNotification])

    useEffect(() => {
        setIsLoading(true)
        let newData = dummyData;
        let finalData = {};
        if (etaUserConfig) {
            newData = etaUserConfig
        } else if (!etaUserConfig && defaultEtaUserConfig) {
            newData = {
                ...dummyData,
                // isDeliveryStarted: defaultEtaUserConfig?.isDeliveryStarted,
                isEtaAnnounced: defaultEtaUserConfig?.etaAnnounced,
                isEtaUpdated: defaultEtaUserConfig?.etaUpdated,
                isEtaChanged: defaultEtaUserConfig?.etaChanged
            }
        }

        if (etaBehaviorConfig) {
            finalData = {
                isDeliveryConfirmed: handleCheckForMandatoryEtaAutomationSetting(etaUserConfig, "isDeliveryConfirmed", newData),
                isDeliveryStarted: handleCheckForMandatoryEtaAutomationSetting(etaUserConfig, "isDeliveryStarted", newData),
                isDeliveryCanceled: handleCheckForMandatoryEtaAutomationSetting(etaUserConfig, "isDeliveryCanceled", newData),
                isEtaAnnounced: handleCheckForMandatoryEtaAutomationSetting(etaUserConfig, "isEtaAnnounced", newData),
                isEtaUpdated: handleCheckForMandatoryEtaAutomationSetting(etaUserConfig, "isEtaUpdated", newData),
                isEtaChanged: handleCheckForMandatoryEtaAutomationSetting(etaUserConfig, "isEtaChanged", newData)
            }
        }

        setEmailNotification(finalData)
        setInitialData(finalData)
        checkShouldChangeSelectAll(finalData)
        setIsLoading(false)
    }, [etaUserConfig, defaultEtaUserConfig, showEtaSettingList, etaBehaviorConfig])

    const handleCheckForMandatoryEtaAutomationSetting = (userConfig, type, oldData) => {
        let disabledKey = type + "Disabled"
        if (showEtaSettingList[disabledKey]) {
            return true
        } else {
            return oldData[type]
        }
    }

    const handleIsChecked = (data) => () => {
        const value = emailNotification[data]

        setEmailNotification({
            ...emailNotification,
            [data]: !value
        })
        setIsChangesHappened(true)

        let clonedData = {
            ...emailNotification,
            [data]: !value
        }

        let isHourBeforeArrrival = clonedData.isOneHourBeforeArrival
        let isTwoHoursBeforeArrrival = clonedData.isTwoHoursBeforeArrival

        if (isTwoHoursBeforeArrrival) {
            setAllEtaEmailSelected(true)
            clonedData.isOneHourBeforeArrival = isTwoHoursBeforeArrrival
            setEmailNotification(clonedData)
        }

        if (isTwoHoursBeforeArrrival) {
            setIsHourAndTwoHourEtaEmailSelected(true)
        } else {
            setIsHourAndTwoHourEtaEmailSelected(false)
        }

        if (isTwoHoursBeforeArrrival && isHourBeforeArrrival) {
            setAllEtaEmailSelected(true)
        } else if (!isTwoHoursBeforeArrrival) {
            setAllEtaEmailSelected(false)
        }

        checkShouldChangeSelectAll(clonedData)
    }

    const buildEmailNotificationPayload = () => {
        let emailNotificationPayload = {}
        if (emailNotification) {
            if (emailNotification.isDeliveryConfirmed) {
                emailNotificationPayload.deliveryConfirmed = true
            } else {
                emailNotificationPayload.deliveryConfirmed = false
            }

            if (emailNotification.isDeliveryStarted) {
                emailNotificationPayload.deliveryStarted = true
            } else {
                emailNotificationPayload.deliveryStarted = false
            }

            if (emailNotification.isDeliveryCanceled) {
                emailNotificationPayload.deliveryCanceled = true
            } else {
                emailNotificationPayload.deliveryCanceled = false
            }

            if (emailNotification.isEtaAnnounced) {
                emailNotificationPayload.etaAnnounced = true
            } else {
                emailNotificationPayload.etaAnnounced = false
            }

            if (emailNotification.isEtaUpdated) {
                emailNotificationPayload.etaUpdated = true
            } else {
                emailNotificationPayload.etaUpdated = false
            }

            if (emailNotification.isEtaChanged) {
                emailNotificationPayload.etaChanged = true
            } else {
                emailNotificationPayload.etaChanged = false
            }
        }

        if (emailNotificationPayload) {
            return emailNotificationPayload
        }
        return null
    }

    const handleSetToDefault = () => {
        setEmailNotification(initialData)
        checkShouldChangeSelectAll(initialData)
    }

    const handleSubmit = () => {
        setIsLoading(true)
        const notificationPayload = buildEmailNotificationPayload()
        CORE_ACTION_POST_SIGN_UP_SEND_ACTIVATION_EMAIL(
            registrationCode,
            email,
            notificationPayload,
            res => {
                setCurrentPage(SignUpPages.EMAIL_CONFIRMATION)
                setResendTimer(TimerResendActivation.RESEND_TIMER)
                setExpiredTimer(TimerResendActivation.EXPIRED_TIMER)

                setIsLoading(false)
                setIsAllSettingsDisabled(false)
                handleClose()
            },
            err => {
                setIsLoading(false)
                setIsAllSettingsDisabled(false)
                handleClose()

                if (err.response && err.response.status === 500) {
                    setIsEmailExist(true)
                }
            }
        )
    }

    const handleSelectAllConfig = () => {
        setIsChangesHappened(true)
        let currentConfig = { ...emailNotification }
        const nextState = !selectAllConfig

        if (currentConfig) {
            if (showEtaSettingList?.isDeliveryStarted) {
                currentConfig.isDeliveryStarted = nextState
            }
            if (showEtaSettingList?.isEtaAnnounced) {
                currentConfig.isEtaAnnounced = nextState
            }
            if (showEtaSettingList?.isEtaUpdated) {
                currentConfig.isEtaUpdated = nextState
            }
            if (showEtaSettingList?.isEtaChanged) {
                currentConfig.isEtaChanged = nextState
            }
        }

        setEmailNotification({ ...currentConfig })
        setSelectAllConfig((prevState) => !prevState)
    }

    const checkWithShowEta = (clonedData) => {
        let result = []
        const activeOptions = []
        const currentSelectAllState = selectAllConfig

        if (showEtaSettingList?.isDeliveryStarted) {
            activeOptions.push("isDeliveryStarted")
        }
        if (showEtaSettingList?.isEtaAnnounced) {
            activeOptions.push("isEtaAnnounced")
        }
        if (showEtaSettingList?.isEtaUpdated) {
            activeOptions.push("isEtaUpdated")
        }
        if (showEtaSettingList?.isEtaChanged) {
            activeOptions.push("isEtaChanged")
        }

        if (currentSelectAllState) {
            if (activeOptions?.length > 0) {
                activeOptions.map((eachOption) => {
                    if (!clonedData[eachOption]) {
                        result.push(eachOption)
                    }
                })
            }

            if (result.length > 0) {
                return true
            }
        } else {
            if (activeOptions?.length > 0) {
                activeOptions.forEach((eachOption) => {
                    if (clonedData[eachOption]) {
                        result.push(eachOption)
                    }
                })
            }

            if (JSON.stringify(result) === JSON.stringify(activeOptions)) {
                return true
            }
        }

        return false
    }

    const checkShouldChangeSelectAll = (clonedData) => {
        if ((selectAllConfig && checkWithShowEta(clonedData)) ||
            (!selectAllConfig && checkWithShowEta(clonedData))) {
            setSelectAllConfig((prevState) => !prevState)
        }
    }

    useEffect(() => {
        const allFalse = areAllValuesFalse(showEtaSettingList);
        if (allFalse) {
            setIsAllSettingsDisabled(true)
            handleSubmit()
        }
    }, [showEtaSettingList])

    if (isAllSettingsDisabled) {
        return <LoadingDialog />
    }


    return (
        <>
            <CustomDialog
                width={900}
                open={isOpen}
                theme={theme}
                withoutClose={true}
            >
                <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                    <CustomDialogContent isMobile={isMobile}>
                        <DivFlexCenter sx={{ mt: isMobile ? '18px' : '', textAlign: 'center' }}>
                            <Typography sx={{ color: theme.palette.text.heading1, fontSize: isMobile ? 20 : 40, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                {t("ETAEmailAutomation")}
                            </Typography>
                        </DivFlexCenter>
                        <Box sx={{ display: 'flex', mt: isMobile ? '42px' : '', textAlign: 'left' }}>
                            <Typography sx={{ color: theme.palette.text.heading1, fontSize: isMobile ? 14 : 21, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                {t("latestEmailEtaNotification")}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: isMobile ? '7px' : '' }}>
                            <EmailAutomationForm
                                handleIsChecked={handleIsChecked}
                                emailNotification={emailNotification}
                                isSetToDefaultDisabled={isSetToDefaultDisabled}
                                handleSetToDefault={handleSetToDefault}
                                showEtaSettingList={showEtaSettingList}
                                paddingBottom={0}
                                selectAllConfig={selectAllConfig}
                                handleSelectAllConfig={handleSelectAllConfig}
                            />
                        </Box>
                        <DivFlexSpaceBetween sx={{ mt: isMobile ? 'clamp(0px, 100%, 85px)' : 7 }}>
                            <Button
                                style={{
                                    width: '100%',
                                    padding: '16px 40px',
                                    height: 'unset'
                                }}
                                onClick={handleSubmit}
                                isLoading={isLoading}
                            >
                                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 24 }}>
                                    {t("save")}
                                </Typography>
                            </Button>
                        </DivFlexSpaceBetween>
                    </CustomDialogContent>
                </Box>
            </CustomDialog>
            {/* <Outlet /> */}
        </>
    )
}

export default EmailNotificationForEmailValidationDialog