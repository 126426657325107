// React Lib
import React, { memo, useContext } from "react";

// Material UI Core
import Slide from '@mui/material/Slide';

// Components 
import { Typography, Grid, Box, TableContainer, FormGroup } from "@mui/material";
// import {  Checkmark } from '@commsult/ontego-ui'
import CloseIcon from "../../assets/icons/CloseIcon";
import CheckedIcon from "../../assets/icons/CheckedIcon";
import AlertCircle from "../../assets/icons/AlertCircle";
import { FontFamily } from "../../Constants/FontFamily";

import Button from "../Button";

// i18n 
import { useTranslation } from 'react-i18next'

// Component Styles
import { SnackbarWrapper, SnackbarContainer, AlertIcon, FlexCenter } from './ConfirmationSnackbarStyles.js';
import { AppContext } from "../../App.js";
import { styled, useTheme } from "@mui/material/styles";


const ConfirmationSnackbar = (props) => {
    const { t } = useTranslation()
    const theme = useTheme();

    const {
        isDesktop,
        isBigPhone
    } = useContext(AppContext);
    const { id, show, onCancelClick, onSaveClick, backgroundColor, textColor } = props;

    return (
        <Slide direction="up" in={show} mountOnEnter unmountOnExit>
            <SnackbarWrapper style={{ backgroundColor: backgroundColor, height: isDesktop ? 85 : 170 }} id={id}>
                <SnackbarContainer>
                    {isBigPhone ? (
                        <>
                            <FlexCenter>
                                <Typography sx={{ textDecoration: 'underline', fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', fontSize: 18, color: theme.palette.background.buttonPrimary }} onClick={onCancelClick} >
                                    {t("cancel")}
                                </Typography>
                            </FlexCenter>
                            <div style={{ display: "flex" }}>
                                <Button
                                    id="confirmationSnackbar-cancel"
                                    alignIcon="left"
                                    onClick={onCancelClick}
                                    isSecondary={true}
                                    style={{
                                        background: "transparent",
                                        border: `2px #2F6DD2 solid`

                                    }}
                                >
                                    {"Discard Changes"}
                                </Button>
                                <Button
                                    id="confirmationSnackbar-save"
                                    type="primary"
                                    color={textColor}
                                    alignIcon="left"
                                    onClick={onSaveClick}
                                    style={{ marginLeft: "24px" }}
                                >
                                    {"Save Changes"}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Grid container spacing={2} sx={{ textAlign: { xs: 'center', md: "justify" } }}>
                                <Grid item xs={12} md={8}>
                                    <Button
                                        id="confirmationSnackbar-save"
                                        type="primary"
                                        color={textColor}
                                        alignIcon="left"
                                        onClick={onSaveClick}
                                    >
                                        {"Save Changes"}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        id="confirmationSnackbar-cancel"
                                        alignIcon="left"
                                        onClick={onCancelClick}
                                        isSecondary={true}
                                        style={{
                                            background: "transparent",
                                            border: `2px #2F6DD2 solid`

                                        }}
                                    >
                                        {"Discard Changes"}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography sx={{ textDecoration: 'underline', fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', fontSize: 18, color: theme.palette.background.buttonPrimary }} onClick={onCancelClick} >
                                        {t("cancel")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </SnackbarContainer>
            </SnackbarWrapper>
        </Slide>
    )
}

ConfirmationSnackbar.defaultProps = {
    // backgroundColor: Color.primary[400],
    textColor: 'white',
    show: false,
    leftText: null
}

export default memo(ConfirmationSnackbar);