import React from "react";

// import Constants
import { BannerVariant } from "../../Constants/BannerVariant";

// import Components
import { SvgIcon, Typography } from "@mui/material";

// import Icons
import AlertCircle from "../../assets/icons/AlertCircle";
import CloseIcon from "../../assets/icons/CloseIcon";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

const BannerIcon = ({ icon, fontSize = 24, color }) => {
    const styles = {
        fontSize: fontSize,
        color: color ? color : 'black',
        marginRight: '14px'
    }

    return (
        <SvgIcon sx={styles}>
            {icon}
        </SvgIcon>
    )
}

const Banner = props => {
    const {
        children,
        width = 'fit-content',
        marginBottom = 0,
        fontSize = 14,
        iconSize = 24,
        variant = BannerVariant.ALERT,
        icon
    } = props

    let variantProperties

    switch (variant) {
        case BannerVariant.ALERT:
            variantProperties = {
                icon: <AlertCircle />,
                iconColor: '#B28600',
                textColor: '#1A1919',
                backgroundColor: '#F1C21B'
            }
            break
        case BannerVariant.DANGER:
            variantProperties = {
                icon: <CloseIcon />,
                iconColor: '#FFBFBD',
                textColor: '#FFF',
                backgroundColor: '#EE4137'
            }
            break
        default:
            variantProperties = {
                icon: <AlertCircle />,
                iconColor: '#B28600',
                textColor: '#1A1919',
                backgroundColor: '#F1C21B'
            }
    }

    return (
        <div
            style={{
                backgroundColor: variantProperties.backgroundColor,
                boxSizing: 'border-box',
                padding: '16px',
                width: width,
                display: 'flex',
                userSelect: 'none',
                marginBottom: marginBottom,
            }}
        >
            <div style={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
                <BannerIcon
                    icon={icon ? icon : variantProperties.icon}
                    color={variantProperties.iconColor}
                    fontSize={iconSize}
                />
            </div>
            <div style={{ display: 'flex', height: 'auto', alignItems: 'center' }}>
                <Typography
                    color={variantProperties.textColor}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: fontSize
                    }}
                >
                    {children}
                </Typography>
            </div>
        </div>
    )
}


export default Banner