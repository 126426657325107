import React, { useContext } from "react";

import { AppContext } from "../../App";

// import styles
import { styled } from "@mui/system";
import { useTheme } from "@mui/styles";


// import icon
import CloseIcon from "../../assets/icons/CloseIcon";
import BackIcon from "../../assets/icons/BackIcon";
import NextIcon from "../../assets/icons/NextIcon";


// import component
import { Box, Dialog, Popover, Popper, Typography } from "@mui/material";
import DivFlexCenter from "../DivFlexCenter";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import PromoCard from "../PromoCard/PromoCard";


const DialogComponent = styled(Dialog)((props) => ({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "1100px",
      borderRadius: 0,
      boxShadow: 'none',
      position: "unset",
      cursor: 'auto'
    },
    backdropFilter: "blur(15px)",
    background: props.theme.palette.background.dialogBlanket
  },
}));

export const PromoDialog = (props) => {
  const {
    open,
    onClose,
    isFirstPromo,
    promo,
    handlePrevPromoDetail,
    handleNextPromoDetail,
    isLastPromo,
  } = props;

  const { mode } = useContext(AppContext)

  const theme = useTheme()

  return (
    <DialogComponent open={open} onClose={onClose} mode={mode} theme={theme} fullWidth maxWidth={'lg'}>
      <DivFlexSpaceBetween>
        <Box sx={{ position: "absolute", left: 36, width: 40, height: 40, background: theme.palette.background.iconColor, borderRadius: 20 }}>
          <BackIcon
            color={isFirstPromo ? "#F4F4F466" : theme.palette.background.default}
            sx={{
              fontSize: 40,
              cursor: isFirstPromo ? "default" : "pointer",
              marginLeft: "-3px"
            }}
            onClick={handlePrevPromoDetail}
          />
        </Box>
        <Box sx={{ width: '100vw' }}>
          <Box sx={{ position: "fixed", width: 1100 }}>
            <Box sx={{ float: "right", paddingTop: '32px', paddingRight: '32px', width: 'fit-content', height: 'fit-content' }}>
              <CloseIcon
                onClick={onClose}
                sx={{ color: theme.palette.text.primary, fontSize: 22, cursor: 'pointer' }}
              />
            </Box>
          </Box>
          <PromoCard
            openDetailPromo={true}
            promo={promo} // promoNewsData[promoDetail]
            isDialog={true}
          />
        </Box>
        <Box sx={{ position: "absolute", right: 36, width: 40, height: 40, background: theme.palette.background.iconColor, borderRadius: 20 }}>
          <NextIcon
            color={isLastPromo ? "#F4F4F466" : theme.palette.background.default}
            sx={{
              fontSize: 40,
              cursor: isLastPromo ? "default" : "pointer",
            }}
            onClick={handleNextPromoDetail}
          />
        </Box>
      </DivFlexSpaceBetween>
    </DialogComponent>
  );
};

export default PromoDialog;
