import React from "react"

import { SvgIcon } from "@mui/material"

export const AddUser = props => {

    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                    fill={props.color}
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.46447 2.46447C7.40215 1.52678 8.67392 1 10 1C11.3261 1 12.5979 1.52678 13.5355 2.46447C14.4732 3.40215 15 4.67392 15 6C15 7.32608 14.4732 8.59785 13.5355 9.53553C12.5979 10.4732 11.3261 11 10 11C8.67392 11 7.40215 10.4732 6.46447 9.53553C5.52678 8.59785 5 7.32608 5 6C5 4.67392 5.52678 3.40215 6.46447 2.46447ZM10 3C9.20435 3 8.44129 3.31607 7.87868 3.87868C7.31607 4.44129 7 5.20435 7 6C7 6.79565 7.31607 7.55871 7.87868 8.12132C8.44129 8.68393 9.20435 9 10 9C10.7956 9 11.5587 8.68393 12.1213 8.12132C12.6839 7.55871 13 6.79565 13 6C13 5.20435 12.6839 4.44129 12.1213 3.87868C11.5587 3.31607 10.7956 3 10 3ZM8 15C7.20435 15 6.44129 15.3161 5.87868 15.8787C5.31607 16.4413 5 17.2043 5 18V20C5 20.5523 4.55228 21 4 21C3.44772 21 3 20.5523 3 20V18C3 16.6739 3.52678 15.4021 4.46447 14.4645C5.40215 13.5268 6.67392 13 8 13H12C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15H8ZM17 14C17.5523 14 18 14.4477 18 15V17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H18V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19H14C13.4477 19 13 18.5523 13 18C13 17.4477 13.4477 17 14 17H16V15C16 14.4477 16.4477 14 17 14Z"
                />
            </svg>
        </SvgIcon>
    )
}

export default AddUser;


