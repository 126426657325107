import React, { useContext, useEffect, useState } from "react";
// import { styled } from "@mui/system";
import Lottie from "react-lottie";

// import material UI
import { Typography, Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useNavigate, useParams } from "react-router-dom";

// import Component
import DeliveryCard from "../../Components/DeliveryCard/DeliveryCard";
import PromoNews from "../../Components/PromoNews/PromoNews";
import DivFlexStart from "../../Components/DivFlexStart";
import DivFlexCenter from "../../Components/DivFlexCenter";
import DeliveryCardMenu from "../../Components/DeliveryCard/DeliveryCardMenu";

// import Constanst
import { ColorTheme } from "../../Constants/ColorTheme";

// import moment
import moment from "moment";
import 'moment/locale/de';

// import AppContext
import { AppContext } from "../../App";

import DeliveryData from "../../classes/DeliveryData";
import { formatAddress } from "../../connector/Utils/DataUtils";

// import Animations
import {
  sunriseLightAnimation,
  sunriseDarkAnimation,
  sunLightAnimation,
  sunDarkAnimation,
  moonLightAnimation,
  moonDarkAnimation
} from "../../Components/Animations/Animations";

// import empty state illustrations
import EmptyStateBlue from "../../assets/images/empty_state_blue.svg";
import EmptyStateYellow from "../../assets/images/empty_state_yellow.svg";
import EmptyStateLight from "../../assets/images/empty_state_light.svg";
import EmptyStateDark from "../../assets/images/empty_state_dark.svg";
import EmptyStateBlueMobile from "../../assets/images/empty_state_blue_mobile.svg";
import EmptyStateYellowMobile from "../../assets/images/empty_state_yellow_mobile.svg";
import EmptyStateLightMobile from "../../assets/images/empty_state_light_mobile.svg";
import EmptyStateDarkMobile from "../../assets/images/empty_state_dark_mobile.svg";

// import components 
import {
  DeliverStickyTitle,
  PromoStickyTitle,
  AnimationContainer,
  Greeting,
  UpcomingDeliveriesGroup,
  PastDeliveriesGroup,
  EmptyStateDesktop,
  EmptyStateMobile,
  OutletOption
} from "./DeliveryPageComponent";
import LoadingDialog from "../../Components/DialogComponent/LoadingDialog";
import PageNotFound from "../PageNotFound";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";
import Banner from "../../Components/Banner/Banner";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";
import { BannerVariant } from "../../Constants/BannerVariant";

import { deliveryDummyData } from "../../dump-data";

//import Styles
import "../../index.css"
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { ACTIVE_ETA_PWA_URL, ACTIVE_STATIC_URL } from "../../utils/environment";
import { CORE_ACTION_GET_ETA_DATA, CORE_ACTION_POST_MARK_ETA_AS_READ } from "../../Actions/CoreActions";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const DeliveryPage = props => {
  const theme = useTheme();
  const param = useParams();
  const navigate = useNavigate()

  const { t, i18n } = useTranslation();

  moment.locale('de');

  const {
    isMobile,
    isTablet,
    mode,
    logo,
    isLogoImage,
    promoNewsData,
    // deliveryData,
    currentEmail,
    setCurrentEmail,
    scrollDown,
    isDesktop,
    goToDelivery,
    goToPromo,
    isScrollToPromo,
    isLoading,
    setIsLoading,
    shouldShowSettings,
    setShouldShowSettings,
    isLogin,
    setIsLogin,
    handleOpenNavigationDrawer,
    handleOpenDialog,
    alertInfo,
    setCustomerName,
    handleLogin,
    setUserRole,
    userRole,
    outletId,
    setOutletId,
    loggedinEmail,
    noSuperAdmin,
    setLoggedinEmail,
    persistedLinkString,
    setPersistedLinkString,
    setIsSuperAdmin,
    isSuperAdmin,
    setListOfOutlets,
    listOfOutlets,
    hasRegisterSelectedOutlets,
    callbackPWA
  } = useContext(AppContext);

  const [cookie, setCookie] = useCookies(["newPwa"], ["appVersion"]);

  const [isError, setIsError] = useState(false)

  /** ============ State ============*/
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));
  const [deliveryId, setDeliveryId] = useState(null);

  const [deliveryData, setDeliveryData] = useState([])
  const [tempDeliveryData, setTempDeliveryData] = useState([])
  const [upcomingData, setUpcomingData] = useState([])
  const [pastData, setPastData] = useState([])
  const [deliveryCardMenuData, setDeliveryCardMenuData] = useState([])

  /* delivery card control */
  const [detailCardOpen, setDetailCardOpen] = useState('')

  /* order number and article name search bar's value */
  const [searchBarValue, setSearchBarValue] = useState('')

  /* past and upcoming collapsible control */
  const [upcomingOpen, setUpcomingOpen] = useState(true)
  const [pastOpen, setPastOpen] = useState(true)

  /* filter by outlet for super admin account */
  const [selectedOutlet, setSelectedOutlet] = useState('')
  const [outletOptions, setOutletOptions] = useState([])

  const handleMarkETAAsReadOnPageLoad = async () => {
    let deepLink = null

    if (param.link) {
      deepLink = param.link
    }

    let queryString = `${deepLink};${outletId};${loggedinEmail}`
    const encodedQueryString = btoa(queryString)

    CORE_ACTION_GET_ETA_DATA(
      encodedQueryString,
      res => {
        if (res.status === 200) {
          const body = {
            parameter: queryString,
            etaPwaUrl: ACTIVE_ETA_PWA_URL,
            stopIds: []
          }

          body.stopIds.push(res.data.stopId)

          CORE_ACTION_POST_MARK_ETA_AS_READ(
            body,
            res => {
              console.log(res)
            },
            err => {
              console.error(err)
            }
          )
        }
      },
      err => {
        // console.error(err)
      }
    )
  }

  const verifyAccountAndDeliveryData = (loggedinEmail, loggedinOutletId, tourStopToCheck) => {
    // console.group("Verifying Account...")

    let isOutletIdMatch = parseInt(outletId, 10) === parseInt(tourStopToCheck.outlet.id, 10)

    // console.log("Outlet Id Match", isOutletIdMatch)

    let isEmailMatch = tourStopToCheck.outlet.outletEmailsWithUserConfig.filter(data => data.email === loggedinEmail).filter.length > 0

    // console.log("Email Match", isEmailMatch)

    if (isOutletIdMatch && isEmailMatch) {
      // console.log("Result", "OK")
    }
    else {
      // console.log("Result", "ACCOUNT_NOT_MATCHED")
    }

    // console.groupEnd()

    return isOutletIdMatch && isEmailMatch
  }

  useEffect(() => {
    // useEffect hook to execute the empty function after 5 seconds
    const timeoutId = setTimeout(() => {
      handleMarkETAAsReadOnPageLoad()
    }, 5000)

    // Cleanup function to clear the timeout in case the component unmounts
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    setShouldShowSettings(true)
    // setIsLoading(true)

    const processDeliveryData = (deliveryData, isInterval) => {
      return new Promise((resolve, reject) => {
        try {
          const newDeliveryDatas = deliveryData.map(data => new DeliveryData(
            data.id,
            data.tourStart,
            data.tourDate,
            data.stopStatus,
            data.stopStart,
            data.stopEnd,
            formatAddress(data.address),
            data.tourNumber,
            data.tourNumberGL,
            data.tripNumber,
            data.customerName,
            data.orders,
            data.tourStopNotifications,
            data.tourStopNotificationHistory,
            data.address,
            data.outlet
          ));

          newDeliveryDatas.forEach(data => {
            data.orders.sort((a, b) => parseInt(a.orderNumber, 10) - parseInt(b.orderNumber, 10));

            data.orders.forEach(order => {
              if (data.stopStatus !== 'FINISHED') {
                order.orderPositions.sort((a, b) => {
                  if (a.amount == 0) return -1;
                  if (b.amount == 0) return 1;
                  const amountComparison = a.amount - b.amount;
                  return amountComparison !== 0 ? amountComparison : parseInt(a.articleNumber, 10) - parseInt(b.articleNumber, 10);
                });
              } else {
                order.orderPositions.sort((a, b) => parseInt(a.articleNumber, 10) - parseInt(b.articleNumber, 10));
              }

              order.orderPositions.forEach(orderPosition => {
                orderPosition.warning = false;
                orderPosition.stopStatus = data.stopStatus;
                if (data.stopStatus === 'FINISHED') {
                  orderPosition.amount = Math.abs(orderPosition.newAmount);
                }
              });
            });
          });

          Promise.all([
            setDeliveryData(newDeliveryDatas),
            setTempDeliveryData(newDeliveryDatas),
            newDeliveryDatas.length > 0 && deliveryId === null && !isInterval ? setDeliveryId(newDeliveryDatas[0].id) : Promise.resolve(),
            newDeliveryDatas[0].customerText ? setCustomerName(newDeliveryDatas[0].customerText) : Promise.resolve(),
            window.location.pathname === ACTIVE_STATIC_URL ? setIsLoading(false) : Promise.resolve(),
            // handleOpenDialog('welcomeGreeting')
          ]).then(() => {
            resolve(newDeliveryDatas);
          });

        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchData = (isInterval = false) => {
      setIsLoading(true)
      if (window.location.pathname === ACTIVE_STATIC_URL) {
        processDeliveryData(deliveryDummyData, isInterval)
      }
      else {
        let deepLink = null

        if (param.link) {
          deepLink = param.link
        }

        let queryString = `${deepLink};${outletId};${loggedinEmail}`
        const encodedQueryString = btoa(queryString)

        CORE_ACTION_GET_ETA_DATA(
          encodedQueryString,
          res => {
            processDeliveryData(res.data.tourStops)
              .then(processedTourStops => {
                if (res.data.stopId === "" || res.data.stopId === null || typeof res.data.stopId === 'undefined') {
                  if (processedTourStops.length > 0) {
                    setDeliveryId(processedTourStops[processedTourStops.length - 1].id);
                  }
                }

                let foundMatch = false;

                if (processedTourStops.length > 0) {
                  processedTourStops.forEach(tourStop => {
                    if (res.data.stopId === tourStop.id) {
                      foundMatch = true;

                      if (verifyAccountAndDeliveryData(loggedinEmail, outletId, tourStop)) {
                        setIsLogin(true);
                      } else {
                        setIsLogin(false);
                      }
                    }
                  });
                }

                if (foundMatch) {
                  setDeliveryId(res.data.stopId);
                } else {
                  setDeliveryId(processedTourStops[processedTourStops.length - 1].id);
                }

                setIsError(false);
                // setIsLoading(false);
              })
              .catch(error => {
                console.error("Error processing delivery data:", error);
                setIsError(true);
                setIsLoading(false);
              });
          },
          err => {
            console.error(err)
            setIsLoading(false)
            setIsError(true)
          }
        )
      }
    }

    // Fetch data initially
    fetchData();

    // Fetch data every 5 minute
    const intervalId = setInterval(() => fetchData(true), 300000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [noSuperAdmin, hasRegisterSelectedOutlets]);

  const processSetDeliveryCardMenuData = () => {
    return new Promise((resolve, reject) => {
      try {
        let currentDeliveryData = tempDeliveryData.find((delivery) => delivery.id === deliveryId)
        Promise.all([
          setDeliveryCardMenuData(currentDeliveryData)
        ]).then(() => {
          resolve(currentDeliveryData);
        });
      } catch (error) {
        reject(error);
      }
    })
  }

  useEffect(() => {
    if (deliveryId && tempDeliveryData.length > 0) {
      processSetDeliveryCardMenuData().then(data => {
        setIsLoading(false)
      })
    }
  }, [deliveryId, tempDeliveryData])

  useEffect(() => {
    if (listOfOutlets.length > 0) {
      // console.log(listOfOutlets)
      let newOutletOptions = []
      let defaultOutlet = ''
      listOfOutlets.forEach((outlet) => {
        const addressKey = `${outlet.street.trim()}_${outlet.zipcode}_${outlet.city}`
        if (parseInt(outlet.id, 10) === parseInt(outletId, 10)) {
          defaultOutlet = addressKey
          newOutletOptions.push({
            value: addressKey,
            // label: t("2_1-1-11")
            label: `${outlet.street.trim()} ${outlet.zipcode} ${outlet.city}`
          })
        }
        else {
          newOutletOptions.push({
            value: addressKey,
            label: `${outlet.street.trim()} ${outlet.zipcode} ${outlet.city}`
          })
        }
      })

      // Filter outlets by available delivery data's outlet
      // const listOutletInDeliveryData = []

      // if (tempDeliveryData?.length > 0) {
      //   tempDeliveryData.forEach((tempDelivery) => {
      //     const addressKey = `${tempDelivery.unformattedAddress.street.trim()}_${tempDelivery.unformattedAddress.zipcode}_${tempDelivery.unformattedAddress.city}`
      //     if (!listOutletInDeliveryData.includes(addressKey)) {
      //       listOutletInDeliveryData.push(addressKey)
      //     }
      //   })

      //   newOutletOptions = newOutletOptions.filter((outletOption) => listOutletInDeliveryData.includes(outletOption.value))
      // }

      // Move registered outlet to first index
      if (defaultOutlet) {
        const registeredOutletIdx = newOutletOptions.map(e => e.value).indexOf(defaultOutlet)
        newOutletOptions.unshift(newOutletOptions.splice(registeredOutletIdx, 1)[0])
      }

      // Set default value of outlet list dropdown
      const isPrevSelectedAvailable = newOutletOptions.some(e => e.value === selectedOutlet)
      if (!selectedOutlet || !isPrevSelectedAvailable) {
        setSelectedOutlet(newOutletOptions[0]?.value)
      }

      setOutletOptions(newOutletOptions)
    }
  }, [listOfOutlets, tempDeliveryData, i18n.language])

  // function for pass delivery id to open the detail  - desktop view
  const handleClickDeliveryDesktop = (id) => {
    if (isDesktop) {
      setDeliveryId(id);
    }
  };

  const handleSearchByOrderNumberOrPositionName = e => {
    if (e.target.value.length === 0) {
      setTempDeliveryData(cloneDeep(deliveryData))
    }
    else {
      let newTempOrders = null

      const newTempDeliveryData = cloneDeep(deliveryData)

      newTempDeliveryData.forEach((data) => {
        if (deliveryId === data.id) {
          newTempOrders = [...data.orders]
        }
      })

      newTempOrders = newTempOrders.filter(order => {
        let position = order.orderPositions.some(position => position.text.toLowerCase().includes(e.target.value.toLowerCase()))
        const isOrderNumberMatch = order.orderNumber.includes(e.target.value)

        return position || isOrderNumberMatch;
      })

      newTempDeliveryData.forEach((data) => {
        if (deliveryId === data.id) {
          data.orders = [...newTempOrders]
        }
      })

      setTempDeliveryData(newTempDeliveryData)
    }

    setSearchBarValue(e.target.value)
  };
  /* ================== PWA Process To Check New Version ===================== */
  // useEffect(() => {
  //   if (callbackPWA) {
  //     callbackPWA.onUpdate = () => {
  //       console.log('service worker update waiting');
  //       // toast.info("A new version of this app is available. Please restart the app to update.");
  //     };
  //   }
  // }, [callbackPWA])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const updateServiceWorker = (registration) => {
        if (registration.waiting) {
          // Send a message to the waiting service worker, instructing it to activate
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }
      };

      // Function to show the update notification
      const showUpdateBar = (registration) => {
        toast.info("A new version is available. Click here to update.", {
          onClick: () => updateServiceWorker(registration),
          toastId: "updateServiceWorker"
        });
      };

      // Function to check for updates
      const checkForUpdates = async () => {
        try {
          const registration = await navigator.serviceWorker.ready;
          await registration.update();

          if (registration.waiting) {
            // If there's a waiting worker, it means an update is available
            if (!toast.isActive("updateServiceWorker")) {
              showUpdateBar(registration);
            }
          }
        } catch (error) {
          console.error('Error checking for updates:', error);
        }
      };

      // Function to register the service worker
      const registerServiceWorker = async () => {
        try {
          const registration = await navigator.serviceWorker.register('../../service-worker.js');

          registration.onupdatefound = () => {
            const newWorker = registration.installing;

            newWorker.onstatechange = () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                // New update available
                // showUpdateBar();
              }
            };
          };

          // Reload the page when the new service worker takes control
          let refreshing = false;
          navigator.serviceWorker.oncontrollerchange = () => {
            if (!refreshing) {
              // window.location.reload();
              refreshing = true;
            }
          };

        } catch (error) {
          console.error('ServiceWorker registration failed: ', error);
        }
      };

      // Register the service worker
      registerServiceWorker();

      // Perform initial check
      checkForUpdates();

      // Set up periodic checking
      const intervalId = setInterval(checkForUpdates, 60 * 1000); // Check every minute

      // Clean up function
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  /* ================== EOL PWA Process To Check New Version ===================== */

  useEffect(() => {
    if (!persistedLinkString) {
      setPersistedLinkString(param.link)
    }
    // Update current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000);

    // Clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  /* populate past and upcoming stop group */
  useEffect(() => {
    // setIsLoading(true)
    if (tempDeliveryData && tempDeliveryData.length > 0) {
      let clonedTempDeliveryData = cloneDeep(tempDeliveryData)

      // Filter selected delivery data in Super Admin mode
      if (selectedOutlet && selectedOutlet != "" && isSuperAdmin) {
        clonedTempDeliveryData = tempDeliveryData.filter((delivData) => {
          const addressKey = `${delivData.unformattedAddress.street.trim()}_${delivData.unformattedAddress.zipcode}_${delivData.unformattedAddress.city}`
          return addressKey === selectedOutlet
        })
      }

      const tempUpcomingData = clonedTempDeliveryData.filter(delivery => {
        if (delivery.tourDate) {
          return moment(moment(delivery.tourDate).format("YYYY-MM-DD")).isSameOrAfter(moment(moment().format("YYYY-MM-DD")))
        }
        else {
          return moment(moment().format("YYYY-MM-DD")).isSameOrAfter(moment(moment().format("YYYY-MM-DD")))
        }
      }).sort((deliveryA, deliveryB) => {
        // Sort by tourDate in descending order (newest past first)
        if (deliveryA.tourDate && deliveryB.tourDate) {
          const dateA = moment(deliveryA.tourDate);
          const dateB = moment(deliveryB.tourDate);
          return dateB.diff(dateA); // Newer date (B) comes first (positive difference)
        }
      });

      const tempPastData = clonedTempDeliveryData.filter(delivery => {
        if (delivery.tourDate) {
          return moment(moment(delivery.tourDate).format("YYYY-MM-DD")).isBefore(moment(moment().format("YYYY-MM-DD")))
        }
      }).sort((deliveryA, deliveryB) => {
        // Sort by tourDate in descending order (newest past first)
        if (deliveryA.tourDate && deliveryB.tourDate) {
          const dateA = moment(deliveryA.tourDate);
          const dateB = moment(deliveryB.tourDate);
          return dateB.diff(dateA); // Newer date (B) comes first (positive difference)
        }
      });

      // Auto select first data when selected outlet changed and prev selected data is not available
      if (clonedTempDeliveryData.length > 0 && (!deliveryId || !clonedTempDeliveryData.some((el) => el.id === deliveryId))) {
        if (tempUpcomingData?.length > 0) {
          setDeliveryId(tempUpcomingData[0].id)
        } else {
          setDeliveryId(tempPastData[0].id)
        }
      }

      setUpcomingData(cloneDeep(tempUpcomingData))
      setPastData(cloneDeep(tempPastData))
      // setIsLoading(false)
    }
  }, [tempDeliveryData, selectedOutlet])

  // keep delivery group collapsible open when no data
  useEffect(() => {
    if (upcomingData.length === 0 && !upcomingOpen) {
      setUpcomingOpen(true)
    }
    else if (pastData.length === 0 && !pastOpen) {
      setPastOpen(true)
    }
  }, [upcomingData, pastData])

  /** =============== Sunrise Animation =============== */
  let morningAnimation

  if (mode === ColorTheme.DARK) {
    morningAnimation =
      <Lottie
        options={sunriseDarkAnimation}
        style={{
          height: isMobile ? "32px" : "48px",
          width: isMobile ? "32px" : "48px",
          margin: "32px 0px 16px 0px",
          pb: isMobile || isTablet ? 2 : 0,
        }}
      />
  } else {
    morningAnimation =
      <Lottie
        options={sunriseLightAnimation}
        style={{
          height: isMobile ? "32px" : "48px",
          width: isMobile ? "32px" : "48px",
          margin: "32px 0px 16px 0px",
          pb: isMobile || isTablet ? 2 : 0,
        }}
      />
  }
  /** =============== EOL Sunrise Animation =============== */


  /** =============== Midday Animation =============== */
  let middayAnimation

  if (mode === ColorTheme.DARK) {
    middayAnimation =
      <Lottie
        options={sunDarkAnimation}
        style={{
          height: !isMobile && "40px",
          width: isMobile ? "32px" : "48px",
          margin: "32px 0px 16px 0px",
          paddingBottom:
            isMobile || isTablet ? "16px" : "0px",
        }}
      />
  } else {
    middayAnimation =
      <Lottie
        options={sunLightAnimation}
        style={{
          height: !isMobile && "40px",
          width: isMobile ? "32px" : "48px",
          margin: "32px 0px 16px 0px",
          paddingBottom:
            isMobile || isTablet ? "16px" : "0px",
        }}
      />
  }
  /** =============== EOL Midday Animation =============== */


  /** =============== Afternoon Animation =============== */
  let afternoonAnimation

  if (mode === ColorTheme.DARK) {
    afternoonAnimation =
      <Lottie
        options={moonDarkAnimation}
        style={{
          height: isMobile ? "32px" : isDesktop ? "60px" : "48px",
          width: isMobile ? '32px' : "48px",
          margin: "32px 0px 16px 0px",
          pb: isMobile || isTablet ? 2 : 0,
        }}
      />
  } else {
    afternoonAnimation =
      <Lottie
        options={moonLightAnimation}
        style={{
          height: isMobile ? "32px" : isDesktop ? "60px" : "48px",
          width: isMobile ? '32px' : "48px",
          margin: "32px 0px 16px 0px",
          pb: isMobile || isTablet ? 2 : 0,
        }}
      />
  }
  /** =============== EOL Afternoon Animation =============== */



  /** =============== Animation for greeting based on time =============== */
  let timeGreetingAnimation

  if (currentTime <= moment().hour(12).minute(0).format("HH:mm")) {
    timeGreetingAnimation =
      <>
        <AnimationContainer>
          {morningAnimation}
        </AnimationContainer>
        <Greeting>
          {t('goodMorning')}
        </Greeting>
      </>
  } else if (currentTime >= moment().hour(16).minute(0).format("HH:mm")) {
    timeGreetingAnimation =
      <>
        <AnimationContainer>
          {afternoonAnimation}
        </AnimationContainer>
        <Greeting>
          {t('goodEvening')}
        </Greeting>
      </>
  } else {
    timeGreetingAnimation =
      <>
        <AnimationContainer>
          {middayAnimation}
        </AnimationContainer>
        <Greeting>
          {t('goodAfternoon')}
          {/* {param.stopNumber} */}
        </Greeting>
      </>
  }
  /** =============== EOL Animation for greeting based on time =============== */

  // ================ Delivery information text =============
  const getDeliveryInformationText = () => {
    const deliveryDate = moment(tempDeliveryData[0]?.tourStopNotifications.actual.twStart)
    const todayDate = moment()

    if (deliveryDate.isBefore(todayDate, 'day')) {
      return t('yourPastDelivery')
    }
    else if (deliveryDate.isSame(todayDate, 'day')) {
      return t('yourDeliveryToday')
    }
    else {
      return t('yourDeliveryTomorrow')
    }
  }

  /* ================ EOL Delivery information text ============== */

  const checkIsDetailCardOpen = () => {
    // return detailCardOpen?.length > 0
    return false
  }

  const isNotifyAlert = () => {
    return alertInfo?.notifyAlert && alertInfo?.alertMessage
  }

  const scrollToTargetCard = (target) => {
    if (target) {
      const element = document.getElementById(`delivery-card-${target}`)
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY - 112

        window.scrollTo({
          top: elementPosition,
          behavior: "smooth"
        })
      }
    }
  }

  const handleClickDataGroup = (type) => {
    if (type === 'upcoming' && upcomingData.length > 0) {
      setUpcomingOpen(!upcomingOpen)
    }
    else if (type !== 'upcoming' && pastData.length > 0) {
      setPastOpen(!pastOpen)
    }
  }

  const getEmptyStateIllustration = () => {
    if (isDesktop) {
      switch (mode) {
        case ColorTheme.BLUE:
          return EmptyStateBlue
        case ColorTheme.YELLOW:
          return EmptyStateYellow
        case ColorTheme.DARK:
          return EmptyStateDark
        case ColorTheme.LIGHT:
          return EmptyStateLight
        default:
          return EmptyStateLight
      }
    }
    else {
      switch (mode) {
        case ColorTheme.BLUE:
          return EmptyStateBlueMobile
        case ColorTheme.YELLOW:
          return EmptyStateYellowMobile
        case ColorTheme.DARK:
          return EmptyStateDarkMobile
        case ColorTheme.LIGHT:
          return EmptyStateLightMobile
        default:
          return EmptyStateLightMobile
      }
    }
  }

  if (isLoading) {
    return <LoadingDialog mode={mode} />
  }

  return (
    <>
      <Box
        sx={{
          position: (isDesktop || isError) ? 'absolute' : 'sticky',
          top: isDesktop && '40px',
          right: isDesktop && '40px'
        }}
      >
        <TopNavbar
          shouldShowSettings={shouldShowSettings}
          theme={theme}
          isLogin={isLogin}
          handleOpenNavigationDrawer={handleOpenNavigationDrawer}
          handleOpenDialog={handleOpenDialog}
          isError={isError}
        />
      </Box>

      {!isError ?
        (
          <Grid container
            sx={{
              pr: isDesktop ? 5 : "",
              height: isDesktop ? "100vh" : "",
              background: (promoNewsData.length > 0 || !isDesktop) ? theme.palette.background.default : theme.palette.background.defaultPromo
            }}
          >
            <Grid className="deliveryCardGrid" item xs={12} md={12} lg={3} id="deliverSection"
              bgcolor={{ xs: theme.palette.background.defaultMobile, lg: theme.palette.background.default }}
              sx={{
                overflowY: isDesktop ? "scroll" : "hidden",
                height: isDesktop ? "100vh" : "",
                pb: isDesktop ? 2 : "",
                pt: !isDesktop ?
                  checkIsDetailCardOpen() ? 2.5 : 3
                  : 6.6,
                pl: isDesktop ? 5 : "",
              }}
            >
              {!isNotifyAlert() ? (
                <div
                  style={{
                    width: isMobile ? 148 : 262,
                    // height: isMobile ? 45 : 80,
                    height: 'fit-content',
                    padding: !isDesktop && '0px 24px'
                  }}
                >
                  {isLogoImage ? (
                    <img src={logo} width={isMobile ? 148 : '100%'} height={isMobile ? 45 : '100%'} alt="logo" />
                  ) : (
                    <Typography
                      color={theme.palette.text.topNavbarText}
                      sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                        fontSize: '40px',
                        lineHeight: '40px',
                        textTransform: 'uppercase'
                      }}
                    >
                      {logo}
                    </Typography>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: !isDesktop ? '0px 24px' : '0 40px 0 0',
                    gap: '24px'
                  }}
                >
                  {isLogoImage ? (
                    <img src={logo} width={isMobile ? 148 : 262} height={isMobile ? 45 : 80} alt="logo" />
                  ) : (
                    <Typography
                      color={theme.palette.text.topNavbarText}
                      sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                        fontSize: '40px',
                        lineHeight: '40px',
                        textTransform: 'uppercase'
                      }}
                    >
                      {logo}
                    </Typography>
                  )}

                  <Banner width={'100%'} variant={BannerVariant.ALERT}>
                    {alertInfo?.alertMessage || ''}
                  </Banner>
                </div>
              )}
              <>
                {tempDeliveryData.length > 1 ? (
                  <>
                    {scrollDown && !isDesktop && (
                      // <DeliverStickyTitle
                      //   onClick={goToDelivery}
                      // >
                      //   <Typography
                      //     fontSize={14}
                      //     color={theme.palette.text.primary}
                      //     sx={{
                      //       fontFamily: FontFamily.PLUSJAKARTASANS,
                      //       fontStyle: "normal",
                      //       fontWeight: 400,
                      //       lineHeight: "19px",
                      //     }}
                      //   >
                      //     {/* {`Heutige Lieferungen`} */}
                      //     {t("deliveries")}
                      //   </Typography>
                      //   <Typography
                      //     fontSize={14}
                      //     color={theme.palette.text.highlithText}
                      //     sx={{
                      //       fontFamily: FontFamily.PLUSJAKARTASANS,
                      //       textDecoration: "underline",
                      //     }}
                      //   >
                      //     {tempDeliveryData.length}
                      //   </Typography>
                      // </DeliverStickyTitle>
                      <></>
                    )}
                    {(isDesktop || (!checkIsDetailCardOpen() && (isMobile || isTablet))) &&
                      <Box id="titleDeliveryPage" sx={{ paddingRight: isDesktop && '30px', mb: isDesktop ? "48px" : "32px" }}>

                        {timeGreetingAnimation}
                      </Box>
                    }
                  </>
                ) : (
                  <>
                    {scrollDown && !isDesktop && (
                      // <DeliverStickyTitle
                      //   onClick={goToDelivery}
                      // >
                      //   <Typography
                      //     fontSize={14}
                      //     color={theme.palette.text.primary}
                      //     sx={{
                      //       fontFamily: FontFamily.PLUSJAKARTASANS,
                      //       fontStyle: "normal",
                      //       fontWeight: 400,
                      //       lineHeight: "19px",
                      //     }}
                      //   >
                      //     {/* {`Today's Delivery`} */}
                      //     {t("deliveries")}
                      //   </Typography>
                      //   <Typography
                      //     fontSize={14}
                      //     color={theme.palette.text.primary}
                      //     sx={{
                      //       fontFamily: FontFamily.PLUSJAKARTASANS,
                      //       textDecoration: "underline",
                      //     }}
                      //   >
                      //     {tempDeliveryData.length}
                      //   </Typography>
                      // </DeliverStickyTitle>
                      <></>
                    )}
                    <Box id="titleDeliveryPage" sx={{ mb: isDesktop ? "48px" : "32px" }}>

                      {timeGreetingAnimation}
                    </Box>
                  </>
                )}

                {/* {isSuperAdmin &&
                <OutletOption
                  isDesktop={isDesktop}
                  outletOptions={outletOptions}
                  selectedOutlet={selectedOutlet}
                  setSelectedOutlet={setSelectedOutlet}
                  theme={theme}
                />
                } */}

                {(upcomingData.length > 0 || pastData.length > 0 || isDesktop) ?
                  <>
                    <UpcomingDeliveriesGroup
                      handleClickDataGroup={handleClickDataGroup}
                      upcomingData={upcomingData}
                      upcomingOpen={upcomingOpen}
                    >
                      {upcomingData.map((data, index) => (
                        <DivFlexCenter
                          id={`delivery-card-${data.id}`}
                          key={`upcoming-${index}`}
                          sx={{
                            marginTop: (isMobile || isTablet) ? '24px' : '16px',
                            mr: isDesktop ? '30px' : ""
                          }}
                          onClick={() => handleClickDeliveryDesktop(data.id)}
                        >
                          <DeliveryCard
                            data={data}
                            totalDelivery={upcomingData.length}
                            numberOfDeliver={index + 1}
                            deliveryId={deliveryId}
                            setDeliveryId={setDeliveryId}
                            handleSearchByOrderNumberOrPositionName={handleSearchByOrderNumberOrPositionName}
                            isDetailOpen={deliveryId === data.id}
                            setDetailCardOpen={setDetailCardOpen}
                            scrollToTargetCard={scrollToTargetCard}
                            searchBarValue={searchBarValue}
                          />
                        </DivFlexCenter>
                      ))}
                    </UpcomingDeliveriesGroup>

                    <PastDeliveriesGroup
                      handleClickDataGroup={handleClickDataGroup}
                      upcomingOpen={upcomingOpen}
                      upcomingData={upcomingData}
                      pastData={pastData}
                      pastOpen={pastOpen}
                    >
                      {pastData.map((data, index) => (
                        <DivFlexCenter
                          id={`delivery-card-${data.id}`}
                          key={`past-${index}`}
                          // sx={{ marginTop: (checkIsDetailCardOpen() && (isMobile || isTablet)) && index === 0 ? '33px' : '16px', pr: isDesktop ? "30px" : "" }}
                          sx={{
                            marginTop: (isMobile || isTablet) ? '24px' : '16px',
                            mr: isDesktop ? '30px' : ""
                          }}
                          onClick={() => handleClickDeliveryDesktop(data.id)}
                        >
                          <DeliveryCard
                            data={data}
                            totalDelivery={pastData.length}
                            numberOfDeliver={index + 1}
                            deliveryId={deliveryId}
                            setDeliveryId={setDeliveryId}
                            handleSearchByOrderNumberOrPositionName={handleSearchByOrderNumberOrPositionName}
                            isDetailOpen={deliveryId === data.id}
                            setDetailCardOpen={setDetailCardOpen}
                            scrollToTargetCard={scrollToTargetCard}
                            searchBarValue={searchBarValue}
                          />
                        </DivFlexCenter>
                      ))}
                    </PastDeliveriesGroup>
                  </>
                  :
                  <EmptyStateMobile
                    getEmptyStateIllustration={getEmptyStateIllustration}
                  />
                }
              </>
            </Grid>

            <Grid item xs={0} md={0} lg={6}
              display={{ xs: "none", md: "none", lg: "block" }}
              bgcolor={{ md: theme.palette.background.deliveryCardMenu }}
              // padding={{ lg: "86px 40px 0px 40px" }}
              sx={{
                // overflowY: isDesktop ? "scroll" : "",
                scrollBehavior: "smooth",
                height: isDesktop ? "100vh" : "",
                paddingTop: "40px",
                paddingBottom: "40px",
                paddingLeft: "48px",
                paddingRight: "48px"

                // overflow: "hidden",
                // position: "sticky"
              }}
            >
              {(upcomingData.length > 0 || pastData.length > 0) ?
                <>
                  <Typography
                    fontSize={32}
                    color={theme.palette.text.primary}
                    sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}
                  >
                    {t('deliveryStatus')}
                  </Typography>

                  <DivFlexCenter>
                    <DeliveryCardMenu
                      data={deliveryCardMenuData}
                      // data={deliveryId ? tempDeliveryData.find((delivery) => delivery.id === deliveryId) : tempDeliveryData[0]}
                      isOpenItemList={true}
                      handleSearchByOrderNumberOrPositionName={handleSearchByOrderNumberOrPositionName}
                      searchBarValue={searchBarValue}
                    />
                  </DivFlexCenter>
                </>
                :
                <EmptyStateDesktop
                  getEmptyStateIllustration={getEmptyStateIllustration}
                />
              }
            </Grid>

            <Grid item xs={12} md={12} lg={3}
              sx={{ paddingTop: (isDesktop || pastOpen) ? "40px" : "16px", height: 'calc(100vh - 25%)' }}
              bgcolor={{ xs: theme.palette.background.defaultMobile, lg: promoNewsData.length > 0 ? theme.palette.background.default : theme.palette.background.defaultPromo }}
            >
              <>
                {/* 
                <TopNavbar
                  shouldShowSettings={shouldShowSettings}
                  theme={theme}
                  isLogin={isLogin}
                  handleOpenNavigationDrawer={handleOpenNavigationDrawer}
                  handleOpenDialog={handleOpenDialog}
                /> */}


                {promoNewsData?.length > 0 &&
                  <Box
                    sx={{
                      overflowY: isDesktop ? "scroll" : "",
                      scrollbarWidth: "none", // Hide the scrollbar for firefox
                      '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                      },
                      '&-ms-overflow-style:': {
                        display: 'none', // Hide the scrollbar for IE
                      },
                      height: "calc(100vh - 25%)",
                      paddingRight: "0px",
                      pl: isDesktop ? "24px" : "0px",
                    }}
                  >
                    <DivFlexStart
                      id="promo"
                      sx={{
                        padding: isDesktop ? "" : "0px 24px",
                        // mt: 3,
                        mb: 2,
                        display: isDesktop ? "none" : "",
                        scrollMarginTop: 142,
                      }}
                    >
                      <Typography
                        fontSize={32}
                        color={theme.palette.text.primary}
                        sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontWeight: 400, lineHeight: '40px' }}
                      >
                        {`Promo und News`}
                      </Typography>
                    </DivFlexStart>
                    {/* {isScrollToPromo && (
                      <PromoStickyTitle onClick={goToPromo}>
                        <Typography
                          fontSize={14}
                          color={theme.palette.text.primary}
                          sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANS,
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {`Promo und News`}
                        </Typography>
                      </PromoStickyTitle>
                    )} */}

                    <PromoNews promoData={promoNewsData} />
                  </Box>
                }
              </>
            </Grid>
          </Grid>
        ) : (
          isLoading ? (
            <></>
          ) : (
            <Box
              sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <PageNotFound />
            </Box>
          )
        )
      }
    </>
  );
};

export default DeliveryPage;
