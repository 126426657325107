import React, { useState, useContext } from "react";
import { styled } from "@mui/material/styles";

// import material Component
import { Box, Chip, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";

import { AppContext } from "../../App";

// import Icons
import ChevronDown from "../../assets/icons/ChevronDown";

// import  component
import DivFlexStart from "../DivFlexStart";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

// import theme color
import { useTheme } from "@mui/material/styles";

// import i18n
import { useTranslation } from "react-i18next";

const ItemSubList = styled(ListItemButton)((props) => ({
  ":hover": {
    cursor: "default",
  },
}));

const getBadgeTextColor = (ordered, actual) => {
  if (ordered > actual) {
    return '#F7F5F5'
  }
  else {
    return '#DA1E28'
  }
}

const getBadgeBackgroundColor = (ordered, actual) => {
  if (ordered > actual) {
    return '#DA1E28'
  }
  else {
    return '#FFB3B8'
  }
}


const ItemList = (props) => {
  const { isMobile, isTablet, isDesktop, isMarkEmptyItemAsNotDeliverable } = useContext(AppContext);

  const { item, index, itemLength, isOpenItemList } = props;

  const { t } = useTranslation()

  // color theme
  const theme = useTheme();

  // local state
  const [open, setOpen] = useState(true);
  const [seeMore, setSeeMore] = useState(true);

  // reusable var
  let maxItemNameChar = isDesktop ? 35 : Math.floor(window.innerWidth / 320 * 17)
  const isItemNameLong = item.text.length > maxItemNameChar

  const handleClickExpandList = () => {
    setOpen(!open);
  };

  const handleSeeMore = (e) => {
    e.stopPropagation()
    setSeeMore(prevState => !prevState)
  }

  const ItemAmount = (props) => {
    const { isSubList } = props

    let isDeliverable = true
    if (item.amount <= 0 && item.stopStatus !== 'FINISHED' && item.stopStatus !== 'CANCELED' && isMarkEmptyItemAsNotDeliverable) {
      isDeliverable = false
    }

    return (
      isDeliverable ?
        <Typography
          sx={{ fontSize: 12, fontFamily: FontFamily.PLUSJAKARTASANS, marginBottom: '-3px', whiteSpace: 'nowrap' }}
          color={isSubList ? theme.palette.text.itemSubListText : theme.palette.text.primary}
        >
          {`${item.amount ?? '0'} ${item.unit}`}
        </Typography>
        :
        <Chip
          label={item.amount}
          sx={{
            color: 'white',
            backgroundColor: '#DA1E28',
            height: '22px',
            paddingLeft: '9.5px',
            paddingRight: '9.5px',
            "& .MuiChip-label": {
              fontSize: 12,
              fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
              fontWeight: 700,
              padding: 0,
              paddingTop: '2px'
            }
          }}
        />
    )
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          background: isDesktop
            ? isOpenItemList
              ? theme.palette.background.deliveryCardMenu
              : theme.palette.background.default
            : theme.palette.background.deliveryCardMenu,
          // paddingLeft: isDesktop ? "0px" : 3,
          // paddingRight: isDesktop ? "0px" : 3,
          mb: index === itemLength - 1 ? 5 : '',
          "&.MuiList-root": {
            paddingTop: '0px',
            paddingBottom: '0px'
          }
        }}
      >
        <ListItemButton
          onClick={item.warning ? handleClickExpandList : undefined}
          sx={{
            minHeight: 40,
            paddingLeft: isMobile ? 0 : '8px',
            paddingRight: isMobile ? 0 : '8px',
            marginLeft: !isDesktop ? '24px' : '',
            marginRight: !isDesktop ? '24px' : '',
            // "&:hover": {
            //   backgroundColor: "transparent",
            //   cursor: !item.warning ? "default" : "",
            // },
            cursor: item.warning ? 'pointer' : 'default',
            borderBottom: !item.warning ? `1px solid ${theme.palette.background.deliveryOrderBorderBottom}` : `2px solid ${theme.palette.background.iconColor}`,
          }}
          disableRipple
        >
          <DivFlexSpaceBetween sx={{ width: "100%", padding: !isDesktop ? '0 8px' : 0 }}>
            <DivFlexStart>
              <Typography
                sx={{
                  fontSize: 12,
                  fontFamily: FontFamily.PLUSJAKARTASANS,
                  fontWeight: item.warning ? 700 : 400,
                  marginBottom: '-3px',
                  marginRight: '16px',
                  width: '100%'
                }}
                color={theme.palette.text.primary}
              >
                {item.text}
              </Typography>

              {/* {isItemNameLong &&
                <Box sx={{ alignSelf: seeMore ? 'center' : 'end' }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontFamily: FontFamily.PLUSJAKARTASANS,
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textDecorationLine: 'underline'
                    }}
                    color={theme.palette.text.primary}
                    onClick={(e) => handleSeeMore(e)}
                  >
                    {seeMore ? t("seeMore") : t("seeLess")}
                  </Typography>
                </Box>
              } */}

              {item.warning && (
                <Chip
                  label={item.newAmount - item.amount}
                  sx={{
                    color: getBadgeTextColor(item.amount, item.newAmount),
                    backgroundColor: getBadgeBackgroundColor(item.amount, item.newAmount),
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    padding: '5.5px 8px',
                    marginRight: '16px',
                    height: '20px',
                    "& .MuiChip-label": {
                      fontSize: 12,
                      padding: 0
                    },
                  }}
                />
              )}
            </DivFlexStart>
            {item.warning ? (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                }}
              >
                <ChevronDown
                  style={{
                    fontSize: isDesktop ? 24 : 16,
                    color: theme.palette.background.iconColor,
                    rotate: open && '180deg',
                    transition: '0.3s all ease-in-out'
                  }}
                />
              </Box>

            ) :
              <ItemAmount />
            }

          </DivFlexSpaceBetween>
        </ListItemButton>
        {item.warning && (
          <>
            <Collapse in={open} timeout="auto" unmountOnExit>

              <ItemSubList
                sx={{
                  height: 40,
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  marginLeft: (isMobile || isTablet) ? '53px' : '36px',
                  marginRight: (isMobile || isTablet) ? '24px' : 0,
                  borderBottom: `1px solid ${theme.palette.background.deliveryOrderBorderBottom}`,
                  // "&:hover": { backgroundColor: "transparent" }
                }}
                disableRipple
              >
                <DivFlexSpaceBetween sx={{ width: "100%" }}>
                  <Typography
                    sx={{ fontSize: 12, fontFamily: FontFamily.PLUSJAKARTASANS, marginBottom: '-3px' }}
                    color={theme.palette.text.itemSubListText}
                  >
                    {`Geplant`}
                  </Typography>
                  <ItemAmount isSubList />
                </DivFlexSpaceBetween>
              </ItemSubList>

              <ItemSubList
                sx={{
                  height: 40,
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  marginLeft: (isMobile || isTablet) ? '53px' : '36px',
                  marginRight: (isMobile || isTablet) ? '24px' : 0,
                  borderBottom: `1px solid ${theme.palette.background.deliveryOrderBorderBottom}`,
                  // "&:hover": {
                  //   backgroundColor: theme.palette.background.oddItemList,
                  // },
                }}
                disableRipple
              >
                <DivFlexSpaceBetween sx={{ width: "100%", }}>
                  <Typography
                    sx={{ fontSize: 12, fontFamily: FontFamily.PLUSJAKARTASANS, marginBottom: '-3px' }}
                    color={theme.palette.text.itemSubListText}
                  >
                    {`Aktuell`}
                  </Typography>
                  <ItemAmount isSubList />
                </DivFlexSpaceBetween>
              </ItemSubList>

            </Collapse>
          </>
        )}
      </List >
    </>
  );
};

export default ItemList;
