import React, { useContext } from "react";
import { Typography, Grid, Box, TableContainer, FormGroup, Chip } from "@mui/material";
import { FontFamily } from "../../Constants/FontFamily";

// import table from material ui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// import styled and theme
import { styled, useTheme } from "@mui/material/styles";

// import Icon
import ArrowLeft from "../../assets/icons/ArrowLeft";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import EditIcon from "../../assets/icons/EditIcon";
import HintIcon from "../../assets/icons/HintIcon";
import Checkbox from '../../Components/Checkbox'
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

// import component
import { EmptyStateMobile } from "../DeliveryPage/DeliveryPageComponent";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";
import DivFlexCenter from "../../Components/DivFlexCenter";
import DivFlexSpaceBetween from "../../Components/DivFlexSpacebetween";
import DivFlexStart from "../../Components/DivFlexStart";
import Button from "../../Components/Button";
import { Warning } from "@mui/icons-material";

/**========== Styled Component ========== */
// table head component
const TableHeadCustom = styled(TableCell)((props) => ({
    fontFamily: FontFamily.PLUSJAKARTASANS,
    fontSize: 18,
    // paddingLeft: 0,
    top: -1,
    backgroundColor: props.theme.palette.background.deliveryOrderTitle,
    color: props.theme.palette.text.primary,
    borderBottom: 'none',
    // padding: 0,
    // paddingBottom: 16
}))

const TableCellCustom = styled(TableCell)((props) => ({
    fontFamily: FontFamily.PLUSJAKARTASANS,
    color: props.theme.palette.text.primary,
    // paddingLeft: 0,
    fontSize: 18,
    borderBottom: props.isLastItem ? 'none' : `1px solid ${props.theme.palette.background.tableRowSeparator}`
}))

// table data container component
const TableDataContainer = styled('div')((props) => ({
    maxHeight: 300,
    overflowY: 'auto'
}))

const RolesTableHead = (props) => {
    const { handleOpenDialog } = useContext(AppContext)
    const { theme } = props

    const { t } = useTranslation()

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    fontFamily: FontFamily.PLUSJAKARTASANS,
                    fontSize: 18
                }}
            >
                {t("roles")}
            </Typography>
            <HintIcon
                htmlColor={theme.palette.background.iconColor}
                onClick={() => handleOpenDialog('rolesAccess')}
                sx={{
                    cursor: 'pointer',
                    mt: '-4px'
                }}
            />
        </Box>
    )
}

const NoEmailAdded = (props) => {
    const { isDesktop } = useContext(AppContext)
    const { theme, marginTop = 5, marginBottom = 10 } = props

    return (
        <DivFlexCenter sx={{ flexDirection: 'column', gap: isDesktop ? 2 : 1, mt: marginTop, mb: marginBottom, textAlign: 'center' }}>
            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isDesktop ? 21 : 12, color: theme.palette.text.primary }}>
                {`No Email Added`}
            </Typography>

            <Typography sx={{ fontFamily: isDesktop ? FontFamily.PLUSJAKARTASANS : FontFamily.PLUSJAKARTASANSLIGHT, fontSize: isDesktop ? 21 : 12, color: theme.palette.text.primary }}>
                {`Please click the button below to add new email.`}
            </Typography>
        </DivFlexCenter>
    )
}
/**========== EOL Styled Component ========== */

const TableData = (props) => {
    const { loggedinEmail } = useContext(AppContext)
    const { handleOpenMenuOverflow, currentEmail, emailList, getTextRoles, handleSetCurrentEmailForEdit, handleSetCurrentEmailForDelete, handleButtonAddNewEmail, isMobile } = props;

    const theme = useTheme();
    const { t } = useTranslation()

    return (
        <div>
            {emailList?.length > 0 ? (
                <TableContainer sx={{ maxHeight: 450 }}>
                    <Table stickyHeader >
                        <TableHead sx={{ zIndex: 0, position: "relative" }}>
                            <TableRow>
                                <TableHeadCustom theme={theme}>{`Email`}</ TableHeadCustom>
                                {/* <TableHeadCustom theme={theme}>
                                    <RolesTableHead theme={theme} />
                                </TableHeadCustom> */}
                                <TableHeadCustom theme={theme}></TableHeadCustom>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emailList.map((list, index) => {
                                if (!list?.loggedInEmail) {
                                    return (
                                        (
                                            <TableRow key={`${list.id} ${index} ${list.email}`}>
                                                <TableCellCustom isLastItem={index === emailList.length - 1} component="th" scope="row" theme={theme}>
                                                    <DivFlexStart>
                                                        <Box>
                                                            {list.email}
                                                        </Box>
                                                        {!list.isValid && (
                                                            <Chip
                                                                label={t('emailNotValid')}
                                                                sx={{
                                                                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                                                    fontSize: 16,
                                                                    background: theme.palette.variables.errorMuted,
                                                                    color: '#FFF',
                                                                    ml: 4
                                                                }}
                                                            />
                                                        )}
                                                    </DivFlexStart>
                                                </TableCellCustom>
                                                {/* <TableCellCustom isLastItem={index === emailList.length - 1}> {getTextRoles(list.role, t)}</TableCellCustom> */}
                                                <TableCellCustom isLastItem={index === emailList.length - 1} sx={{ width: 90 }}>
                                                    <DivFlexSpaceBetween sx={{ mr: -2, ml: 1 }}>
                                                        <MoreVertIcon sx={{
                                                            cursor: 'pointer', fontSize: 24,
                                                            display: { xs: 'block', sm: 'none' }
                                                        }} onClick={(e) => handleOpenMenuOverflow(e, null, list)} />
                                                        {/* <EditIcon sx={{
                                                                cursor: 'pointer', fontSize: 24,
                                                                display: { xs: 'none', sm: 'block' }
                                                            }} color={theme.palette.background.iconAction} onClick={() => handleSetCurrentEmailForEdit(null, list)} /> */}
                                                        <DeleteIcon sx={{
                                                            cursor: 'pointer', fontSize: 24, ml: 3,
                                                            display: { xs: 'none', sm: 'block' }
                                                        }} color={theme.palette.background.dangerIcon} onClick={() => handleSetCurrentEmailForDelete(null, list)} />
                                                    </DivFlexSpaceBetween>
                                                </TableCellCustom>
                                            </TableRow>
                                        )
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoEmailAdded marginTop={12} marginBottom={13.375} theme={theme} />
            )}
            <Typography sx={{ pt: 4, textDecoration: 'underline', fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', fontSize: isMobile ? 16 : 18, color: theme.palette.background.buttonPrimary }} onClick={handleButtonAddNewEmail} >
                + {t("addNewEmail")}
            </Typography>
        </div>
    )
}

export default TableData;