import React from "react"

import { SvgIcon } from "@mui/material"

export const ClockIcon = props => {
    return (
        <SvgIcon {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M6.17317 0.761205C7.38642 0.258658 8.68678 0 10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761205 13.8268C0.258658 12.6136 0 11.3132 0 10C0 8.68678 0.258658 7.38642 0.761205 6.17317C1.26375 4.95991 2.00035 3.85752 2.92893 2.92893C3.85752 2.00035 4.95991 1.26375 6.17317 0.761205ZM10 2C8.94942 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C3.60028 5.08601 3.011 5.96793 2.60896 6.93853C2.20693 7.90914 2 8.94942 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94942 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 8.94942 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08602 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM11 9.46473V5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5V9.99874C9 9.99896 9 9.99917 9 9.99939V10C9 10.1912 9.05367 10.3699 9.14676 10.5218C9.20036 10.6096 9.26819 10.6901 9.3495 10.7595C9.38489 10.7899 9.42242 10.8178 9.46184 10.843L12.4454 12.832C12.9049 13.1384 13.5258 13.0142 13.8322 12.5547C14.1385 12.0952 14.0143 11.4743 13.5548 11.1679L11 9.46473Z" />
        </SvgIcon>
    )

}





export default ClockIcon