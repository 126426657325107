import React, { useContext, useEffect, useState } from "react";

// import material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// import  component
import Button from "../Button";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

// import App context
import { AppContext } from "../../App";

// import dummy image for promo
import logo from "../../../src/assets/images/dummy-promo-lemonade.jpg";

// color theme
import { useTheme } from "@mui/material/styles";

// import icons
import ImageCarousel from "../ImageCarousel/ImageCarousel";

// import i18n
import { useTranslation } from "react-i18next";

const PromoCard = (props) => {
  const { promo, promoId, openDetailPromo, isDialog, isMobile, isTablet, promoDetail, handleOpenPromoDialog, scrollToTargetPromo } = props;

  const { isDesktop, email, phone } = useContext(AppContext);

  const { t } = useTranslation()

  // color theme
  const theme = useTheme();

  // local state
  const [openDetail, setOpenDetail] = useState(false);

  const handleMailTo = e => {
    e.stopPropagation()
    window.location.href = `mailto:${email}`;
    e.preventDefault();
  }

  const handleCallSalesRep = e => {
    e.stopPropagation()
  }

  // const handleOpenDetail = e => {
  //   e.stopPropagation()
  //   setOpenDetail(prevState => !prevState)
  // }

  // useEffect(() => {
  //   if (isDesktop && !isDialog && openDetail) {
  //     setOpenDetail(false)
  //   }
  // }, [isDesktop])

  useEffect(() => {
    setOpenDetail(openDetailPromo)
  }, [openDetailPromo])

  // useEffect(() => {
  //   if (promoDetail === promoId) {
  //     setOpenDetail(true)
  //   }
  //   else {
  //     setOpenDetail(false)
  //   }
  // }, [promoDetail])

  useEffect(() => {
    if (promoId === promoDetail && !isDesktop) {
      scrollToTargetPromo(promoId)
    }
  }, [openDetail])


  return (
    <Card
      sx={{
        width: openDetail || isDesktop ? "100%" : "calc(100% - 48px)",
        backgroundColor: isMobile || isTablet
          ? openDetail
            ? theme.palette.background.default
            : theme.palette.background.promoCardMobile
          : isDialog
            ? theme.palette.background.dialog
            : theme.palette.background.promoCard,
        // "&:hover": {
        //   backgroundColor: isMobile || isTablet
        //     ? ""
        //     : isDialog
        //       ? ""
        //       : theme.palette.background.hoverPromoCard,
        // },
        borderRadius: 0,
        cursor: "pointer",
        display: openDetail && (!isDesktop || isDialog) ? 'flex' : 'block',
        flexDirection: isMobile || isTablet ? 'column' : 'row'
      }}
      elevation={0}
      onClick={isDesktop ? undefined : handleOpenPromoDialog}
    >

      {Array.isArray(promo.images) && promo.images?.length > 0 ?
        <Box>
          <ImageCarousel
            images={promo.images}
            isDialog={isDialog}
          />
        </Box>
        :
        <CardMedia
          component="img"
          image={promo.images}
          alt="promo"
          sx={{ objectFit: "cover", width: isDialog ? '553px' : '100%', height: isDialog ? '779px' : '100%' }}
        />
      }

      {openDetail && (!isDesktop || isDialog) ? (
        <Box>
          <CardContent
            style={{
              width: isDialog ? '553px' : '100%',
              height: '100%',
              padding: isDialog
                ? "80px 64px 80px 64px"
                : openDetail
                  ? "32px 24px 8px 24px"
                  : "8px 16px 16px 16px",
            }}
          >
            <Box
              sx={{ width: '100%', height: '100%', position: 'relative' }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  fontSize={isMobile ? 28 : 36}
                  sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    paddingLeft: isDesktop ? "" : openDetail ? "" : "16px",
                    lineHeight: isMobile ? '36px' : '40px'
                  }}
                >
                  {promo.headline}
                </Typography>

                <Box
                  sx={{
                    maxHeight: isDialog && '300px',
                    overflow: isDialog && 'auto',
                    marginTop: (!isDesktop && openDetail) ? '24px' : '32px'
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                      fontFamily: FontFamily.PLUSJAKARTASANS,
                    }}
                  >
                    {promo.description}
                  </Typography>
                </Box>
              </Box>

              <Box
                style={{
                  position: isMobile ? 'relative' : 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  textAlign: 'center',
                  marginTop: isMobile ? '40px' : 0
                }}
              >
                <>
                  {isDesktop ?
                    <>
                      <Typography
                        fontSize={14}
                        sx={{
                          fontFamily: FontFamily.PLUSJAKARTASANS,
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                        }}
                      >
                        {t("callSalesRepresentative")}
                      </Typography>
                      <Typography
                        fontSize={14}
                        sx={{
                          fontFamily: FontFamily.PLUSJAKARTASANS,
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                          mt: 1,
                          mb: '20px'
                        }}
                      >
                        {phone}
                      </Typography>
                    </>
                    :
                    <></>
                    // <Button
                    //   isPromo={true}
                    //   onClick={handleCallSalesRep}
                    //   theme={theme}
                    //   style={{
                    //     backgroundColor: theme.palette.background.buttonDefault,
                    //     marginBottom: '16px'
                    //   }}
                    // >
                    //   <Typography
                    //     sx={{
                    //       fontSize: 14,
                    //       fontFamily: FontFamily.PLUSJAKARTASANS,
                    //       fontWeight: 600,
                    //       color: theme.palette.text.primaryButtonText
                    //     }}
                    //   >
                    //     {t("callSalesRepresentative")}
                    //   </Typography>
                    // </Button>
                  }

                  <Button
                    isPromo={true}
                    onClick={handleMailTo}
                    theme={theme}
                    isSecondary={true}
                    style={{
                      backgroundColor: isDesktop ? theme.palette.background.buttonDefault : 'transparent',
                      border: isMobile && `2px solid ${theme.palette.background.buttonDefault}`
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: isDesktop ? 16 : 14,
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontWeight: 600,
                        color: isDesktop ? theme.palette.text.primaryButtonText : theme.palette.text.primary
                      }}
                    >
                      {t("mailSalesRepresentative")}
                    </Typography>
                  </Button>
                </>
              </Box>
            </Box>
          </CardContent>

          {/* <Collapse in={openDetail} timeout="auto" unmountOnExit>
          <Box
            sx={{
              border: '1px solid red',
              padding:
                isDialog
                  ? "32px 64px 80px 64px"
                  : openDetail
                    ? "0px 24px 24px 24px"
                    : "0px 16px 8px 16px",
              overflowY: "scroll",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: 12,
                fontFamily: FontFamily.PLUSJAKARTASANS,
              }}
            >
              {promo.description}
            </Typography>
          </Box>

          <Box
            sx={{
              padding: isMobile || isTablet
                ? openDetail
                  ? "8px 24px 16px 24px"
                  : "8px 16px 16px 16px"
                : "30px 75px 30px 75px",
            }}
          >
            {isDialog ? (
              <>
                <DivFlexCenter sx={{ marginBottom: "15px" }}>
                  <Typography
                    fontSize={12}
                    sx={{
                      fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                      color: theme.palette.text.primary,
                    }}
                  >
                    <span>
                      {" "}
                      <PhoneIcon
                        color={theme.palette.text.primary}
                        sx={{ fontSize: 16, mr: 0.5, mt: -0.2 }}
                      />{" "}
                    </span>{" "}
                    {phone}
                  </Typography>
                </DivFlexCenter>

                <Button
                  isPromo={true}
                  icon={
                    <ChatIcon color={"#F4F4F4"} sx={{ mr: 1, fontSize: 20 }} />
                  }
                  onClick={handleMailTo}
                  theme={theme}
                >
                  {`Contact Our Sales Rep.`}
                </Button>
              </>
            ) : (
              <>
                <DivFlexCenter sx={{ marginBottom: 2 }}>
                  <Typography
                    fontSize={12}
                    sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}
                    color={theme.palette.text.primary}
                  >
                    {`Contact Our Sales Rep.`}
                  </Typography>
                </DivFlexCenter>
                <DivFlexSpaceBetween>
                  <Button height={46} isPromo={true} style={{ width: "48%" }}>
                    <ChatIcon color={"#F4F4F4"} sx={{ fontSize: 20 }} />
                  </Button>
                  <Button height={46} isPromo={true} style={{ width: "48%" }}>
                    <PhoneIcon color={"#F4F4F4"} sx={{ fontSize: 22 }} />
                  </Button>
                </DivFlexSpaceBetween>
              </>
            )}
          </Box>
        </Collapse> */}
        </Box>
      ) : (
        <CardContent
          style={{
            width: '100%',
            height: '100%',
            padding: isDialog
              ? "80px 64px 80px 64px"
              : openDetail && (!isDesktop || isDialog)
                ? "16px 24px 8px 24px"
                : "8px 16px 16px 16px",
          }}
        >
          <Typography
            color={theme.palette.text.primary}
            fontSize={18}
            sx={{
              fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
            }}
          >
            {promo.headline}
          </Typography>
        </CardContent>
      )}

    </Card>
  );
};

PromoCard.defaultProps = {
  promo: {
    image: logo,
    headline: "-",
    description: "---",
  },
};

export default PromoCard;
