import React, { useContext } from "react";

import { styled } from "@mui/system";

import Typography from "@mui/material/Typography";

import { AppContext } from "../App";

import { useTheme } from "@emotion/react";

// import contstant
import { FontFamily } from "../Constants/FontFamily";
import { CircularProgress, circularProgressClasses } from "@mui/material";

const getPaddingBySize = (size) => {
  switch (size) {
    case "large":
      return "16px 40px"
    case "medium":
      return "16px 40px"
    default:
      return "12px 40px"
  }
}

const Buttons = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // height: props.height ? props.height : props.isMobile ? 40 : 52,
  backgroundColor: props.isLoading ? props.theme.palette.background.buttonActive : props.theme.palette.background.buttonDefault,
  cursor: props.isLoading ? "default" : "pointer",
  padding: getPaddingBySize(props.size),
  userSelect: props.isLoading ? 'none' : 'auto',
  pointerEvents: props.isLoading ? 'none' : 'auto',
  ":hover": {
    backgroundColor:
      props.isMobile || props.isTablet ? ''
        : props.isLoading ? props.theme.palette.background.buttonActive : props.theme.palette.background.buttonHover,
  },
  ":active": {
    backgroundColor:
      props.isLoading ? props.theme.palette.background.buttonDefault
        : props.theme.palette.background.buttonActive,
  },
}));

const Button = (props) => {
  const { isMobile, isDekstop, isTablet } = useContext(AppContext);

  const theme = useTheme()

  const { children, icon, style, onClick, isPromo, height, isLoading, isSecondary, size } = props

  const getFontSize = () => {
    switch (size) {
      case "large":
        return 20
      case "medium":
        return 16
      default:
        return 14
    }
  }

  return (
    <>
      <Buttons
        onClick={onClick}
        theme={theme}
        sx={{ ...style }}
        isMobile={isMobile}
        isTablet={isTablet}
        isDekstop={isDekstop}
        height={height}
        isLoading={isLoading}
      >
        {icon}
        {isLoading ? (
          <CircularProgress
            sx={{
              color: theme.palette.background.circularLoading,
              // animationDuration: "0ms",
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            thickness={4.6}
            disableShrink
          />
        ) : (
          <Typography
            sx={{
              fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
              color: isSecondary ? theme.palette.text.primary : theme.palette.background.buttonColor,
              fontSize: getFontSize(),
              userSelect: 'none',
              textAlign: 'center'
            }}
          >
            {children}
          </Typography>
        )}
      </Buttons>
    </>
  );
};

export default Button;
