import React, { useContext, useEffect, useState } from "react";
import CustomDialog from "../../Components/DialogComponent/DialogContainer/CustomDialog";
import CustomDialogContent from "../../Components/DialogComponent/DialogContainer/CustomDialogContent";
import { AppContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import DivFlexCenter from "../../Components/DivFlexCenter";
import { Box, Typography } from "@mui/material";
import { FontFamily } from "../../Constants/FontFamily";
import DivFlexStart from "../../Components/DivFlexStart";
import Checkbox from "../../Components/Checkbox";
import { formatAddress } from "../../connector/Utils/DataUtils";
import DivFlexSpaceBetween from "../../Components/DivFlexSpacebetween";
import NewButton from "../../Components/NewButton/NewButton";
import { ButtonSizeEnum } from "../../Components/NewButton/ButtonSizeEnum";
import { ButtonVariantEnum } from "../../Components/NewButton/ButtonVariantEnum";

const MultipleEmailAutomationDialog = (props) => {

    const {
        isMobile,
        isDesktop
    } = useContext(AppContext)

    const {
        isOpen,
        listOfOutletsApplyOn,
        handleClose,
        setListOfOutletsApplyOn,
        applyOnFromOutletId,
        handleSaveApplyOn
    } = props

    const theme = useTheme()

    const { t } = useTranslation()

    // useEffect(() => {
    //     const tempListOfOutlets = listOfOutletsApplyOn
    //     const sourceOutletIndex = listOfOutlets.findIndex(outlet => outlet.id === sourceOutletId)
    //     if (sourceOutletId > -1) {
    //         tempListOfOutlets.unshift(tempListOfOutlets.splice(sourceOutletIndex, 1)[0])
    //     }

    //     setListOfOutlets(tempListOfOutlets)
    // }, [])

    const getBorderBottom = (outlet) => {
        if (outlet.isConfigSource) {
            return 'none'
        }
        if (outlet.isChecked) {
            return `2px solid ${theme.palette.variables.borderHover}`
        }
        return `1px solid ${theme.palette.variables.borderDefault}`
    }

    const handleIsChecked = (outletId) => {
        if (outletId) {
            let tempListOfOutlets = listOfOutletsApplyOn.map((outlet) => {
                if (parseInt(outlet.id, 10) === parseInt(outletId, 10)) {
                    return {
                        ...outlet,
                        isChecked: !outlet.isChecked
                    }
                } else {
                    return outlet
                }
            })

            setListOfOutletsApplyOn(tempListOfOutlets)
        }
    }

    return (
        <CustomDialog
            width={900}
            open={isOpen}
            theme={theme}
            withoutClose={false}
            onClose={handleClose}
        >
            <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                <CustomDialogContent isMobile={isMobile}>
                    <DivFlexCenter sx={{ mt: isMobile ? '18px' : '', textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontFamily: isMobile ? FontFamily.PLUSJAKARTASANSSEMIBOLD : FontFamily.PLUSJAKARTASANSBOLD,
                                // fontWeight: 700,
                                fontSize: isMobile ? '24px' : '44px',
                                lineHeight: isMobile ? '29px' : '57px',
                                textAlign: 'center'
                            }}
                        >
                            {t("1_1-5-10_modal_title")}
                        </Typography>
                    </DivFlexCenter>
                    {!isMobile &&
                        <DivFlexStart sx={{ mt: 8, textAlign: 'left' }}>
                            <Typography
                                sx={{
                                    fontFamily: FontFamily.PLUSJAKARTASANS,
                                    fontWeight: 400,
                                    fontSize: '20px',
                                    lineHeight: '28px'
                                }}
                            >
                                {t("2_1-5-10_modal_content")}
                            </Typography>
                        </DivFlexStart>
                    }
                    <DivFlexStart sx={{ mt: isMobile ? 4 : 3, flexDirection: 'column' }}>
                        {listOfOutletsApplyOn.map((outlet, index) =>
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    pl: '18px',
                                    pr: 2,
                                    pt: '7px',
                                    pb: '7px',
                                    backgroundColor: outlet.isConfigSource ? theme.palette.variables.general : 'none',
                                    borderBottom: getBorderBottom(outlet)
                                }}
                            >
                                <Checkbox
                                    disabled={outlet.isConfigSource}
                                    checked={outlet.isChecked}
                                    onChange={() => handleIsChecked(outlet.id)}
                                    label={formatAddress(outlet)}
                                />
                            </Box>
                        )}
                    </DivFlexStart>
                    {isMobile ?
                        <DivFlexSpaceBetween sx={{ mt: '67px', flexDirection: 'column', gap: '12px' }}>
                            <NewButton
                                size={ButtonSizeEnum.SMALL}
                                variant={ButtonVariantEnum.PRIMARY}
                                onClick={() => handleSaveApplyOn(applyOnFromOutletId)}
                                isFullWidth
                            >
                                {t('3_1-5-10_modal_button')}
                            </NewButton>
                            <NewButton
                                size={ButtonSizeEnum.SMALL}
                                variant={ButtonVariantEnum.TERTIARY}
                                onClick={handleClose}
                            >
                                {t('cancel')}
                            </NewButton>
                        </DivFlexSpaceBetween>
                        :
                        <DivFlexSpaceBetween sx={{ mt: '117px' }}>
                            <NewButton
                                size={ButtonSizeEnum.LARGE}
                                variant={ButtonVariantEnum.TERTIARY}
                                onClick={handleClose}
                            >
                                {t('cancel')}
                            </NewButton>
                            <NewButton
                                size={isDesktop ? ButtonSizeEnum.LARGE : ButtonSizeEnum.SMALL}
                                variant={ButtonVariantEnum.PRIMARY}
                                onClick={() => handleSaveApplyOn(applyOnFromOutletId)}
                                sx={{ pl: '44.5px', pr: '44.5px' }}
                            >
                                {t('3_1-5-10_modal_button')}
                            </NewButton>
                        </DivFlexSpaceBetween>
                    }
                </CustomDialogContent>
            </Box>
        </CustomDialog>
    )
}

export default MultipleEmailAutomationDialog