import { TextField } from "@mui/material";
import { styled } from '@mui/system';

// import Constants
import { FontFamily } from "../../Constants/FontFamily";


const TextFieldStyled = styled(TextField)((props) => ({
    input: {
        fontSize: props.fontSize ? props.fontSize : props.isMobile ? 16 : 16,
        fontFamily: FontFamily.PLUSJAKARTASANS,
        color: props.theme.palette.text.inputTextActive,
        height: !props.padding ? (props.height ? props.height : props.isMobile ? 5 : 20) : 'unset',
        "&:-webkit-autofill": {
            // WebkitBoxShadow: `0 0 0 1000px ${props.theme.palette.background.dialog} inset`,
            WebkitBoxShadow: 'none',
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: props.theme.palette.text.inputTextActive,
            WebkitBackgroundClip: 'text'
        },
        paddingLeft: !props.padding && 24,
        padding: props.padding && props.padding,
        background: 'transparent'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: props.warning ? '2px solid #da1e28' : `2px solid ${props.theme.palette.background.borderForm}`,
            borderRadius: 0,
            background: 'transparent'
        },
        '&:hover fieldset': {
            border: props.warning ? '2px solid #da1e28' : `2px solid ${props.theme.palette.background.borderFormHover}`,
            borderRadius: 0,
            background: 'transparent'
        },
        '&.Mui-focused fieldset': {
            border: props.warning ? '2px solid #da1e28' : `2px solid ${props.theme.palette.background.borderFormActive}`,
            borderRadius: 0,
            background: 'transparent'
        },

    },

}))

export default TextFieldStyled;
