import React from "react"

import { SvgIcon } from "@mui/material"

export const EditIcon = props => {
    return (
        <SvgIcon {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M16.7087 2.9071C17.29 2.32621 18.0782 1.99988 18.9 1.99988C19.7222 1.99988 20.5107 2.3265 21.0921 2.90788C21.6735 3.48926 22.0001 4.27779 22.0001 5.09998C22.0001 5.9218 21.6738 6.70997 21.0929 7.29128L21.0921 7.29209L19.8287 8.56C19.7936 8.61187 19.753 8.66117 19.7071 8.70711C19.662 8.75217 19.6138 8.79203 19.563 8.82669L12.7084 15.7058C12.5207 15.8941 12.2658 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7341 8.10585 11.4793 8.29416 11.2916L15.1734 4.43693C15.208 4.38619 15.2479 4.33793 15.2929 4.29289C15.3388 4.24698 15.3881 4.20647 15.4399 4.17135L16.7079 2.90788L16.7087 2.9071ZM16.0112 6.42544L10 12.4152V14H11.5847L17.5745 7.98876L16.0112 6.42544ZM18.9862 6.57202L17.428 5.01376L18.1221 4.32209C18.3284 4.11578 18.6082 3.99988 18.9 3.99988C19.1918 3.99988 19.4716 4.11578 19.6779 4.32209C19.8842 4.5284 20.0001 4.80822 20.0001 5.09998C20.0001 5.39175 19.8842 5.67157 19.6779 5.87788L18.9862 6.57202ZM3.87868 6.87868C4.44129 6.31607 5.20435 6 6 6H7C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8H6C5.73478 8 5.48043 8.10536 5.29289 8.29289C5.10536 8.48043 5 8.73478 5 9V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H15C15.2652 19 15.5196 18.8946 15.7071 18.7071C15.8946 18.5196 16 18.2652 16 18V17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17V18C18 18.7957 17.6839 19.5587 17.1213 20.1213C16.5587 20.6839 15.7957 21 15 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7957 3 18V9C3 8.20435 3.31607 7.44129 3.87868 6.87868Z" />
        </SvgIcon>
    )
}




export default EditIcon
