import {
    createTheme,
} from "@mui/material/styles";

export const lightTheme = createTheme({
    palette: {
        variables: {
            dangerIcon: '#FA4D56',
            icon: '#6F6F6F',
            searchBox: '#E0E0E0',

            general: '#FFFFFF',
            centerArea: '#F3F3F3',
            dialogBox: '#FFFFFF',
            cardDefault: '#F3F3F3',
            cardHover: '#EBEBEB',
            cardPressed: '#C6C6C6',
            cardActive: '#A8A8A8',

            prominent: '#1A1919',
            coloredText: '#1A1919',
            borderActive: '#6F6F6F',
            borderHover: '#A8A8A8',
            borderDefault: '#D1D2D1',
            disabled: '#A8A8A8',
            mutedDefault: '#8D8D8D',
            mutedHover: '#6F6F6F',
            mutedActive: '#1A1919',

            buttonBgDefault: '#0F62FE',
            buttonBgHover: '#4589FF',
            buttonBgClicked: '#002D9C',
            buttonBgDisabled: '#6F6F6F',
            buttonFg: '#F4F4F4',
            buttonFgDisabled: '#A8A8A8',
            buttonSecFg: '#1A1919',
            errorSubtle: '#FFD7D9',
            errorMuted: '#FF8389',
            errorProminent: '#DA1E28',
            topNavFg: '#1A1919',

            primaryMain: '#2196F3',
            primaryP40: '#78A9FF',
            primaryP50: '#4589FF',
            primaryP60: '#0F62FE',
            typographyDisabled: '#8D8D8D'
        },
        background: {
            // body
            default: "#ffffff",
            defaultPromo: "#F3F3F3",

            settingsIcon: '#6F6F6F',
            settingsIconHover: '#A8A8A8',
            settingsIconDisabled: '#A8A8A8',
            settingsIconPressed: '##C6C6C6',

            // delivery card
            deliveryCard: "#F3F3F3",
            hoverDeliveryCard: "#F7F5F5",
            clickedDeliveryCard: "#EBEBEB",
            deliveryCardMenu: "#F3F3F3",

            // promo card
            promoCard: "#F3F3F3",
            promoCardMobile: "#F3F3F3",
            hoverItemList: "#f7f5f5",
            hoverPromoCard: "#f7f5f5",

            // appbar and icon
            appBar: "#EBEBEB",
            iconColor: "#6F6F6F",
            iconColorDisabled: "#A8A8A8",
            title: '#1A1919',
            hoverIconButton: "#F7F5F5",
            dangerIcon: "#FA4D56",


            // dialog
            dialogBlanket: "rgba(0, 0, 0, 0.3)",
            dialog: "#ffffff",
            headDialog: "#ffffff",
            iconAction: "#000000",
            signUpDialog: '#FFFFFF',

            // loading
            circularLoading: "#EBEBEB",
            // checkbox
            checkboxDisabled: "#8D8D8D",
            checkboxBorder: "#262626",
            checkboxBackground: "#F4F4F4",

            // radio
            radioDisabled: "#8D8D8D",
            radioBorder: "#262626",
            radioHover: "#E0E0E0",

            // form
            otpBorder: "#D1D2D1",
            borderForm: "#D1D2D1",
            borderFormHover: "#6F6F6F",
            borderFormActive: "#6F6F6F",

            // button
            buttonSecondary: "#262626",
            buttonDefault: "#0F62FE",
            buttonActive: "#002D9C",
            buttonHover: "#4589FF",
            buttonColor: "#F4F4F4",
            buttonPrimary: "#2196F3",

            // tracking numbercontainer
            borderTrackingNumber: "#979797",

            // link expired
            iconStatus: '#A8A8A8',

            // scroll to top button
            scrollToTop: "#262626",

            // itemlist
            oddItemList: "#E7E7E7",

            // separatorStickyTitle
            separatorTitle: "#8D8D8D",

            //DeliveryCardMenuSearchBar
            deliveryCardMenuSearchBar: "#E0E0E0",
            deliverycardSearchIcon: "#6F6F6F",

            //DeliveryCardMenuItemTitle
            deliveryCardMenuItemTitle: "#F3F3F3",

            //DeliveryCardCloseIcon
            deliveryCardCloseIcon: "#6F6F6F",

            //delivery orders
            deliveryOrderTitle: "#F3F3F3",
            deliveryOrderTitleActive: "#C6C6C6",
            collapseIcon: "#262626",
            deliveryOrderBorderBottom: "rgba(111, 111, 111, 0.3)",
            //DeliveryCardItemTitle
            deliveryCardItemTitle: "#cbcbcb",
            deliveryCardBorder: "#D1D2D1",

            //NavigationDrawer
            menuItemHover: "#E7E7E7",
            menuItemClicked: "#D1D2D1",
            menuItemActive: "#D1D2D1",
            menuItemActiveIcon: "#6F6F6F",

            drawerColor: "#EBEBEB",
            drawerPaper: "#EBEBEB",
            drawerSeparator: "#D1D2D1",

            titleSeparator: "#A8A8A8",

            //Image carousel navigation
            carouselNavBackground: "#FFFFFF",
            carouselNavButton: "#6F6F6F",
            carouselBulletBackground: "#FFFFFF",
            carouselBulletSelected: "#6F6F6F",
            carouselBullet: "#C6C6C6",

            //Table row separator
            tableRowSeparator: "#D1D2D1"
        },
        text: {
            primary: "#1A1919",
            heading1: "#1A1919",
            highlithText: "#000000",
            disabled: "#A8A8A8",

            // text for chip delivery card
            doneText: "#6F6F6F",
            doneIcon: "#A8A8A8",

            // button
            buttonPrimary: "#F4F4F4",
            buttonSecondary: "#ffffff",

            // text in dialog
            dialogHeadingText: "#1A1919",
            emailListText: "#000000",
            inputText: "#8D8D8D",
            inputTextActive: "#1A1919",
            emailListRole: "#909090",
            primaryButtonText: "#F4F4F4",

            // loading text
            loadingText: '#262626',
            formDialog: "#1A1919",
            setToDefault: "#1A1919",
            setToDefaultDisabled: "#8d8d8d",

            // top navbar text
            topNavbarText: '#1A1919',
            verticalSeparator: '#1A1919',

            // item sub list text
            itemSubListText: '#6F6F6F'
        },
    }
});

export const darkTheme = createTheme({
    palette: {
        variables: {
            dangerIcon: '#FA4D56',
            icon: '#A8A8A8',
            searchBox: '#525252',

            general: '#262626',
            centerArea: '#393939',
            dialogBox: '#393939',
            cardDefault: '#393939',
            cardHover: '#525252',
            cardPressed: '#6F6F6F',
            cardActive: '#161616',

            prominent: '#F7F5F5',
            coloredText: '#F7F5F5',
            borderActive: '#F7F5F5',
            borderHover: '#C6C6C6',
            borderDefault: '#5C5B5B',
            disabled: '#8D8D8D',
            mutedDefault: '#C6C6C6',
            mutedHover: '#A8A8A8',
            mutedActive: '#FFFFFF',

            buttonBgDefault: '#0F62FE',
            buttonBgHover: '#4589FF',
            buttonBgClicked: '#002D9C',
            buttonBgDisabled: '#C6C6C6',
            buttonFg: '#F4F4F4',
            buttonFgDisabled: '#A8A8A8',
            buttonSecFg: '#F4F4F4',
            errorSubtle: '#FFD7D9',
            errorMuted: '#FF8389',
            errorProminent: '#DA1E28',
            topNavFg: '#F7F5F5',

            primaryMain: '#2196F3',
            primaryP40: '#78A9FF',
            primaryP50: '#4589FF',
            primaryP60: '#0F62FE',
            typographyDisabled: '#8D8D8D'
        },
        background: {
            // body
            default: "#262626",
            defaultPromo: "#393939", // background for the right part default View, should same with delivery card,promo card

            settingsIcon: '#A8A8A8',
            settingsIconHover: '#161616',
            settingsIconDisabled: '#8D8D8D',
            settingsIconPressed: '#6F6F6F',

            // delivery card
            deliveryCard: "#393939",
            hoverDeliveryCard: "#4C4C4C",
            clickedDeliveryCard: "#1F2020",
            deliveryCardMenu: "#393939",

            // promo card
            promoCard: "#393939",
            promoCardMobile: "#393939",
            hoverItemList: "#404040",
            hoverPromoCard: "#4c4c4c",

            // appbar and icon
            appBar: "#353535",
            iconColor: "#A8A8A8",
            iconColorDisabled: "#8D8D8D",
            title: '#F7F5F5',
            hoverIconButton: "#4C4C4C",
            dangerIcon: "#FA4D56",

            // dialog
            dialogBlanket: "rgba(0, 0, 0, 0.3)",
            dialog: "#393939",
            headDialog: "#393939",
            iconAction: "#e0e0e0",
            signUpDialog: '#262626',

            // loading
            circularLoading: "#EBEBEB",
            // checkbox
            checkboxDisabled: "#8D8D8D",
            checkboxBorder: "#E0E0E0",
            checkboxBackground: "#525252",

            // radio
            radioDisabled: "#A8A8A8",
            radioBorder: "#E0E0E0",
            radioHover: "#525252",

            // form
            otpBorder: "#656565",
            borderForm: "#5C5B5B",
            borderFormHover: "#F7F5F5",
            borderFormActive: "#F7F5F5",

            // button
            buttonSecondary: "#ffffff",
            buttonDefault: "#0F62FE",
            buttonActive: "#002D9C",
            buttonHover: "#4589FF",
            buttonColor: "#F4F4F4",
            buttonPrimary: "#2196F3",

            // tracking numbercontainer
            borderTrackingNumber: "transparent",

            // link expired
            iconStatus: '#8D8D8D',

            // scroll to top button
            scrollToTop: "#ffffff",

            // itemlist
            oddItemList: "#525252",

            // separatorStickyTitle
            separatorTitle: "#C6C6C6",

            //DeliveryCardMenuSearchBar
            deliveryCardMenuSearchBar: "#525252",
            deliverycardSearchIcon: "#C6C6C6",



            //DeliveryCardMenuItemTitle
            deliveryCardMenuItemTitle: "#393939",

            //DeliveryCardCloseIcon
            deliveryCardCloseIcon: "#A8A8A8",

            //delivery orders
            deliveryOrderTitle: "#393939",
            deliveryOrderTitleActive: "#6F6F6F",
            collapseIcon: "#F4F4F4",
            deliveryOrderBorderBottom: "rgba(250, 250, 250, 0.3)",
            //DeliveryCardItemTitle
            deliveryCardItemTitle: "#525252",
            deliveryCardBorder: "#5C5B5B",

            //NavigationDrawer
            menuItemHover: "#4C4C4C",
            menuItemClicked: "#5C5B5B",
            menuItemActive: "#5C5B5B",
            menuItemActiveIcon: "#E0E0E0",

            drawerColor: "#525252",
            drawerPaper: "#525252",
            drawerSeparator: "#656565",

            titleSeparator: "#6F6F6F",

            //Image carousel navigation
            carouselNavBackground: "#262626",
            carouselNavButton: "#A8A8A8",
            carouselBulletBackground: "#262626",
            carouselBulletSelected: "#A8A8A8",
            carouselBullet: "#C6C6C6",

            //Table row separator
            tableRowSeparator: "#5C5B5B"
        },
        text: {
            primary: "#F7F5F5",
            heading1: "#f4f4f4",
            highlithText: "#ffffff",
            disabled: "#8D8D8D",

            // text for chip delivery card
            doneText: "#FFFFFF",
            doneIcon: "#A8A8A8",

            // button
            buttonPrimary: "#F4F4F4",
            buttonSecondary: "#262626",

            // text dialog
            dialogHeadingText: "#e0e0e0",
            emailListText: "#f4f4f4",
            inputText: "#e0e0e0",
            inputTextActive: "#F7F5F5",
            emailListRole: "#ffffff",
            primaryButtonText: "#F4F4F4",

            // loading text
            loadingText: '#F7F5F5',
            setToDefault: "#F4F4F4",
            setToDefaultDisabled: "#8d8d8d",

            // canceled datetime text
            canceledText: '#C6C6C6',

            // top navbar text
            topNavbarText: '#F7F5F5',
            verticalSeparator: '#A8A8A8',

            // item sub list text
            itemSubListText: '#A8A8A8'
        },
    }
});

export const yellowTheme = createTheme({
    palette: {
        variables: {
            dangerIcon: '#FA4D56',
            icon: '#8E6A00',
            searchBox: '#E0E0E0',

            general: '#FFFDF4',
            centerArea: '#FCF4D6',
            dialogBox: '#FFFFFF',
            cardDefault: '#FCF4D6',
            cardHover: '#F5ECC8',
            cardPressed: '#F4E991',
            cardActive: '#EDDA48',

            prominent: '#1A1919',
            coloredText: '#8E6A00',
            borderActive: '#8E6A00',
            borderHover: '#B28600',
            borderDefault: '#F1C21B',
            disabled: '#A8A8A8',
            mutedDefault: '#8D8D8D',
            mutedHover: '#6F6F6F',
            mutedActive: '#1A1919',

            buttonBgDefault: '#2F6DD2',
            buttonBgHover: '#5285D9',
            buttonBgClicked: '#183769',
            buttonBgDisabled: '#6F6F6F',
            buttonFg: '#F4F4F4',
            buttonFgDisabled: '#A8A8A8',
            buttonSecFg: '#1A1919',
            errorSubtle: '#FFD7D9',
            errorMuted: '#FF8389',
            errorProminent: '#DA1E28',
            topNavFg: '#F7F5F5',

            primaryMain: '#2196F3',
            primaryP40: '#78A9FF',
            primaryP50: '#4589FF',
            primaryP60: '#0F62FE',
            typographyDisabled: '#8D8D8D'
        },
        background: {
            // body
            default: "#FFFDF4",
            defaultPromo: "#FCF4D6",

            settingsIcon: '#8E6A00',
            settingsIconHover: '#EDDA48',
            settingsIconDisabled: '#C6C6C6',
            settingsIconPressed: '#F4E991',

            // delivery card
            deliveryCard: "#F3F3F3",
            hoverDeliveryCard: "#F7F5F5",
            clickedDeliveryCard: "#EBEBEB",
            deliveryCardMenu: "#FCF4D6",

            // promo card
            promoCard: "#FCF4D6",
            promoCardMobile: "#FCF4D6",
            hoverItemList: "#f3f3f3",
            hoverPromoCard: "#FCF4D6",

            // appbar and icon
            appBar: "#EDDA48",
            iconColor: "#8E6A00",
            iconColorDisabled: "#C6C6C6",
            title: '#1A1919',
            hoverIconButton: "#F7F5F5",
            iconDisabled: '#C6C6C6',
            dangerIcon: "#FA4D56",

            // dialog
            dialogBlanket: "rgba(233, 228, 211, 0.3)",
            dialog: "#ffffff",
            headDialog: "#ffffff",
            iconAction: "#000000",
            signUpDialog: "#FFFDF4",

            // loading
            circularLoading: "#EBEBEB",
            // checkbox
            checkboxDisabled: "#8D8D8D",
            checkboxBorder: "#262626",
            checkboxBackground: "#F4F4F4",

            // radio
            radioDisabled: "#8D8D8D",
            radioBorder: "#262626",
            radioHover: "#E0E0E0",

            // form
            otpBorder: "#F1C21B",
            borderForm: "#F1C21B",
            borderFormHover: "#8E6A00",
            borderFormActive: "#8E6A00",

            // button
            buttonSecondary: "#262626",
            buttonDefault: "#0F62FE",
            buttonActive: "#B28600",
            buttonHover: "#D2A106",
            buttonColor: "#F4F4F4",
            buttonPrimary: "#2196F3",

            // tracking numbercontainer
            borderTrackingNumber: "#F1C21B",

            // link expired
            iconStatus: '#C6C6C6',

            // scroll to top button
            scrollToTop: "#262626",

            // itemlist
            oddItemList: "#E7E7E7",

            // separatorStickyTitle
            separatorTitle: "#8D8D8D",

            //DeliveryCardMenuSearchBar
            deliveryCardMenuSearchBar: "#E0E0E0",
            deliverycardSearchIcon: "#8E6A00",

            //DeliveryCardCloseIcon
            deliveryCardCloseIcon: "#8E6A00",

            //delivery orders
            deliveryOrderTitle: "#F3F3F3",
            deliveryOrderTitleActive: "#F4E991",
            collapseIcon: "#262626",
            deliveryOrderBorderBottom: "#F1C21B",
            deliveryCardBorder: "#F1C21B",

            //NavigationDrawer
            menuItemHover: "#F4E991",
            menuItemClicked: "#F1C21B",
            menuItemActive: "#F1C21B",
            menuItemActiveIcon: "#FDDC69",

            drawerColor: "#F5ECC8",
            drawerPaper: "#F5ECC8",
            drawerSeparator: "#F1C21B",

            titleSeparator: "#A8A8A8",

            //Image carousel navigation
            carouselNavBackground: "#FFFDF4",
            carouselNavButton: "#8E6A00",
            carouselBulletBackground: "#FFFDF4",
            carouselBulletSelected: "#8E6A00",
            carouselBullet: "#C6C6C6",

            //Table row separator
            tableRowSeparator: "#F1C21B"
        },
        text: {
            primary: "#1A1919",
            heading1: "#1A1919",
            highlithText: "#000000",
            disabled: '#C6C6C6',

            // text for chip delivery card
            doneText: "#6F6F6F",
            doneIcon: "#A8A8A8",

            // button
            buttonPrimary: "#302400",
            buttonSecondary: "#ffffff",

            // text in dialog
            dialogHeadingText: "#1A1919",
            emailListText: "#000000",
            inputText: "#8D8D8D",
            inputTextActive: "#1A1919",
            emailListRole: "#909090",
            primaryButtonText: "#302400",

            // loading text
            loadingText: '#262626',
            setToDefault: "#1A1919",
            setToDefaultDisabled: "#8d8d8d",

            // top navbar text
            topNavbarText: '#8E6A00',
            verticalSeparator: '#8E6A00',

            // canceled datetime text
            canceledText: '#C6C6C6',

            // item sub list text
            itemSubListText: '#6F6F6F'
        },
    }
});

export const blueTheme = createTheme({
    palette: {
        variables: {
            dangerIcon: '#FA4D56',
            icon: '#183769',
            searchBox: '#F4F4F4',

            general: '#EBF2FD',
            centerArea: '#D5E2F6',
            dialogBox: '#FFFFFF',
            cardDefault: '#D5E2F6',
            cardHover: '#BACEF0',
            cardPressed: '#97B6E8',
            cardActive: '#5285D9',

            prominent: '#1A1919',
            coloredText: '#183769',
            borderActive: '#275BAF',
            borderHover: '#2F6DD2',
            borderDefault: '#97B6E8',
            disabled: '#A8A8A8',
            mutedDefault: '#8D8D8D',
            mutedHover: '#6F6F6F',
            mutedActive: '#1A1919',

            buttonBgDefault: '#2F6DD2',
            buttonBgHover: '#5285D9',
            buttonBgClicked: '#183769',
            buttonBgDisabled: '#6F6F6F',
            buttonFg: '#F4F4F4',
            buttonFgDisabled: '#A8A8A8',
            buttonSecFg: '#1A1919',
            errorSubtle: '#FFD7D9',
            errorMuted: '#FF8389',
            errorProminent: '#DA1E28',
            topNavFg: '#F7F5F5',

            primaryMain: '#2196F3',
            primaryP50: '#4589FF',
            primaryP40: '#78A9FF',
            primaryP60: '#0F62FE',
            typographyDisabled: '#8D8D8D'
        },
        background: {
            // body
            default: "#EBF2FD",
            defaultPromo: "#D5E2F6",

            settingsIcon: '#275BAF',
            settingsIconHover: '#5285D9',
            settingsIconDisabled: '#C6C6C6',
            settingsIconPressed: '#97B6E8',

            // delivery card
            deliveryCard: "#F3F3F3",
            hoverDeliveryCard: "#F7F5F5",
            clickedDeliveryCard: "#EBEBEB",
            deliveryCardMenu: "#D5E2F6",

            // promo card
            promoCard: "#D5E2F6",
            promoCardMobile: "#D5E2F6",
            hoverItemList: "#f3f3f3",
            hoverPromoCard: "#EBF2FD",

            // appbar and icon
            appBar: "#2F6DD2",
            iconColor: "#275BAF",
            iconColorDisabled: "#C6C6C6",
            title: '#1A1919',
            hoverIconButton: "#EBF2FD",
            dangerIcon: "#FA4D56",

            // dialog
            dialogBlanket: "rgba(208, 226, 255, 0.3)",
            dialog: "#ffffff",
            headDialog: "#ffffff",
            iconAction: "#161616",
            signUpDialog: "#EBF2FD",

            // loading
            circularLoading: "#EBEBEB",
            // checkbox
            checkboxDisabled: "#8D8D8D",
            checkboxBorder: "#262626",
            checkboxBackground: "#F4F4F4",

            // radio
            radioDisabled: "#8D8D8D",
            radioBorder: "#262626",
            radioHover: "#E0E0E0",

            // form
            otpBorder: "#97B6E8",
            borderForm: "#97B6E8",
            borderFormHover: "#275BAF",
            borderFormActive: "#275BAF",

            // button
            buttonSecondary: "#262626",
            buttonDefault: "#0F62FE",
            buttonActive: "#002D9C",
            buttonHover: "#5285D9",
            buttonColor: "#F4F4F4",
            buttonPrimary: "#2196F3",

            // tracking numbercontainer
            borderTrackingNumber: "#979797",

            // link expired
            iconStatus: '#C6C6C6',

            // scroll to top button
            scrollToTop: "#262626",

            // itemlist
            oddItemList: "#D5E2F6",

            // separatorStickyTitle
            separatorTitle: "#8D8D8D",

            //DeliveryCardMenuSearchBar
            deliveryCardMenuSearchBar: "#F4F4F4",
            deliverycardSearchIcon: "#275BAF",

            //DeliveryCardCloseIcon
            deliveryCardCloseIcon: "#275BAF",

            //delivery orders
            deliveryOrderTitle: "#F3F3F3",
            deliveryOrderTitleActive: "#97B6E8",
            collapseIcon: "#262626",
            deliveryOrderBorderBottom: "#97B6E8",
            deliveryCardBorder: "#97B6E8",

            //NavigationDrawer
            menuItemHover: "#A6C8FF",
            menuItemClicked: "#97B6E8",
            menuItemActive: "#97B6E8",
            menuItemActiveIcon: "#525252",

            drawerColor: "#BACEF0",
            drawerPaper: "#BACEF0",
            drawerSeparator: "#97B6E8",

            titleSeparator: "#8D8D8D",

            //Image carousel navigation
            carouselNavBackground: "#EBF2FD",
            carouselNavButton: "#275BAF",
            carouselBulletBackground: "#EBF2FD",
            carouselBulletSelected: "#275BAF",
            carouselBullet: "#C6C6C6",

            //Table row separator
            tableRowSeparator: "#97B6E8"
        },
        text: {
            primary: "#1A1919",
            heading1: "#1A1919",
            highlithText: "#000000",
            disabled: "#C6C6C6",

            // text for chip delivery card
            doneText: "#6F6F6F",
            doneIcon: "#A8A8A8",

            // button
            buttonPrimary: "#F4F4F4",
            buttonSecondary: "#ffffff",

            // text in dialog
            dialogHeadingText: "#1A1919",
            emailListText: "#000000",
            inputText: "#8D8D8D",
            inputTextActive: "#1A1919",
            emailListRole: "#909090",
            primaryButtonText: "#F4F4F4",

            // loading text
            loadingText: '#262626',
            setToDefault: "#1A1919",
            setToDefaultDisabled: "#8d8d8d",

            // top navbar text
            topNavbarText: '#275BAF',
            verticalSeparator: '#275BAF',

            // item sub list text
            itemSubListText: '#6F6F6F'
        },
    }
});

export const orangeTheme = createTheme({
    palette: {
        variables: {
            dangerIcon: '#FA4D56',
            icon: '#8E6A00',
            searchBox: '#E0E0E0',

            general: '#FFFDF4',
            centerArea: '#FCF4D6',
            dialogBox: '#FFFFFF',
            cardDefault: '#FCF4D6',
            cardHover: '#F5ECC8',
            cardPressed: '#F4E991',
            cardActive: '#EDDA48',

            prominent: '#1A1919',
            coloredText: '#8E6A00',
            borderActive: '#8E6A00',
            borderHover: '#B28600',
            borderDefault: '#FF980C',
            disabled: '#A8A8A8',
            mutedDefault: '#8D8D8D',
            mutedHover: '#6F6F6F',
            mutedActive: '#1A1919',

            buttonBgDefault: '#2F6DD2',
            buttonBgHover: '#5285D9',
            buttonBgClicked: '#183769',
            buttonBgDisabled: '#6F6F6F',
            buttonFg: '#F4F4F4',
            buttonFgDisabled: '#A8A8A8',
            buttonSecFg: '#1A1919',
            errorSubtle: '#FFD7D9',
            errorMuted: '#FF8389',
            errorProminent: '#DA1E28',
            topNavFg: '#F7F5F5',

            primaryMain: '#2196F3',
            primaryP40: '#78A9FF',
            primaryP50: '#4589FF',
            primaryP60: '#0F62FE',
            typographyDisabled: '#8D8D8D'
        },
        background: {
            // body
            default: "#FFEACE",
            defaultPromo: "#FFDDAE",

            settingsIcon: '#8E6A00',
            settingsIconHover: '#EDDA48',
            settingsIconDisabled: '#C6C6C6',
            settingsIconPressed: '#F4E991',

            // delivery card
            deliveryCard: "#F3F3F3",
            hoverDeliveryCard: "#F7F5F5",
            clickedDeliveryCard: "#EBEBEB",
            deliveryCardMenu: "#FFDDAE",

            // promo card
            promoCard: "#FCF4D6",
            promoCardMobile: "#FCF4D6",
            hoverItemList: "#f3f3f3",
            hoverPromoCard: "#FCF4D6",

            // appbar and icon
            appBar: "#EDDA48",
            iconColor: "#8E6A00",
            iconColorDisabled: "#C6C6C6",
            title: '#1A1919',
            hoverIconButton: "#F7F5F5",
            iconDisabled: '#C6C6C6',
            dangerIcon: "#FA4D56",

            // dialog
            dialogBlanket: "rgba(233, 228, 211, 0.3)",
            dialog: "#ffffff",
            headDialog: "#ffffff",
            iconAction: "#000000",
            signUpDialog: "#FFFDF4",

            // loading
            circularLoading: "#EBEBEB",
            // checkbox
            checkboxDisabled: "#8D8D8D",
            checkboxBorder: "#262626",
            checkboxBackground: "#F4F4F4",

            // radio
            radioDisabled: "#8D8D8D",
            radioBorder: "#262626",
            radioHover: "#E0E0E0",

            // form
            otpBorder: "#FF980C",
            borderForm: "#FF980C",
            borderFormHover: "#8E6A00",
            borderFormActive: "#8E6A00",

            // button
            buttonSecondary: "#262626",
            buttonDefault: "#0F62FE",
            buttonActive: "#B28600",
            buttonHover: "#D2A106",
            buttonColor: "#F4F4F4",
            buttonPrimary: "#2196F3",

            // tracking numbercontainer
            borderTrackingNumber: "#FF980C",

            // link expired
            iconStatus: '#C6C6C6',

            // scroll to top button
            scrollToTop: "#262626",

            // itemlist
            oddItemList: "#E7E7E7",

            // separatorStickyTitle
            separatorTitle: "#8D8D8D",

            //DeliveryCardMenuSearchBar
            deliveryCardMenuSearchBar: "#E0E0E0",
            deliverycardSearchIcon: "#8E6A00",

            //DeliveryCardCloseIcon
            deliveryCardCloseIcon: "#8E6A00",

            //delivery orders
            deliveryOrderTitle: "#FFDDAE",
            deliveryOrderTitleActive: "#F4E991",
            collapseIcon: "#262626",
            deliveryOrderBorderBottom: "#FF980C",
            deliveryCardBorder: "#FF980C",

            //NavigationDrawer
            menuItemHover: "#F4E991",
            menuItemClicked: "#F1C21B",
            menuItemActive: "#F1C21B",
            menuItemActiveIcon: "#FDDC69",

            drawerColor: "#FFBA5D",
            drawerPaper: "#F5ECC8",
            drawerSeparator: "#F1C21B",

            titleSeparator: "#A8A8A8",

            //Image carousel navigation
            carouselNavBackground: "#FFFDF4",
            carouselNavButton: "#8E6A00",
            carouselBulletBackground: "#FFFDF4",
            carouselBulletSelected: "#8E6A00",
            carouselBullet: "#C6C6C6",

            //Table row separator
            tableRowSeparator: "#F1C21B"
        },
        text: {
            primary: "#1A1919",
            heading1: "#1A1919",
            highlithText: "#000000",
            disabled: '#C6C6C6',

            // text for chip delivery card
            doneText: "#6F6F6F",
            doneIcon: "#A8A8A8",

            // button
            buttonPrimary: "#302400",
            buttonSecondary: "#ffffff",

            // text in dialog
            dialogHeadingText: "#1A1919",
            emailListText: "#000000",
            inputText: "#8D8D8D",
            inputTextActive: "#1A1919",
            emailListRole: "#909090",
            primaryButtonText: "#302400",

            // loading text
            loadingText: '#262626',
            setToDefault: "#1A1919",
            setToDefaultDisabled: "#8d8d8d",

            // top navbar text
            topNavbarText: '#AA6508',
            verticalSeparator: '#8E6A00',

            // canceled datetime text
            canceledText: '#C6C6C6',

            // item sub list text
            itemSubListText: '#6F6F6F'
        },
    }
});

export const greenTheme = createTheme({
    palette: {
        variables: {
            dangerIcon: '#FA4D56',
            icon: '#8E6A00',
            searchBox: '#E0E0E0',

            general: '#FFFDF4',
            centerArea: '#C5F8D3',
            dialogBox: '#FFFFFF',
            cardDefault: '#C5F8D3',
            cardHover: '#F5ECC8',
            cardPressed: '#F4E991',
            cardActive: '#EDDA48',

            prominent: '#1A1919',
            coloredText: '#8E6A00',
            borderActive: '#8E6A00',
            borderHover: '#B28600',
            borderDefault: '#F1C21B',
            disabled: '#A8A8A8',
            mutedDefault: '#8D8D8D',
            mutedHover: '#6F6F6F',
            mutedActive: '#1A1919',

            buttonBgDefault: '#2F6DD2',
            buttonBgHover: '#5285D9',
            buttonBgClicked: '#183769',
            buttonBgDisabled: '#6F6F6F',
            buttonFg: '#F4F4F4',
            buttonFgDisabled: '#A8A8A8',
            buttonSecFg: '#1A1919',
            errorSubtle: '#FFD7D9',
            errorMuted: '#FF8389',
            errorProminent: '#DA1E28',
            topNavFg: '#F7F5F5',

            primaryMain: '#2196F3',
            primaryP40: '#78A9FF',
            primaryP50: '#4589FF',
            primaryP60: '#0F62FE',
            typographyDisabled: '#8D8D8D'
        },
        background: {
            // body
            default: "#EFFDF3",
            defaultPromo: "#DEFBE6",

            settingsIcon: '#8E6A00',
            settingsIconHover: '#EDDA48',
            settingsIconDisabled: '#C6C6C6',
            settingsIconPressed: '#F4E991',

            // delivery card
            deliveryCard: "#F3F3F3",
            hoverDeliveryCard: "#F7F5F5",
            clickedDeliveryCard: "#EBEBEB",
            deliveryCardMenu: "#DEFBE6",

            // promo card
            promoCard: "#C5F8D3",
            promoCardMobile: "#C5F8D3",
            hoverItemList: "#f3f3f3",
            hoverPromoCard: "#C5F8D3",

            // appbar and icon
            appBar: "#EDDA48",
            iconColor: "#8E6A00",
            iconColorDisabled: "#C6C6C6",
            title: '#1A1919',
            hoverIconButton: "#F7F5F5",
            iconDisabled: '#C6C6C6',
            dangerIcon: "#FA4D56",

            // dialog
            dialogBlanket: "rgba(233, 228, 211, 0.3)",
            dialog: "#ffffff",
            headDialog: "#ffffff",
            iconAction: "#000000",
            signUpDialog: "#FFFDF4",

            // loading
            circularLoading: "#EBEBEB",
            // checkbox
            checkboxDisabled: "#8D8D8D",
            checkboxBorder: "#262626",
            checkboxBackground: "#F4F4F4",

            // radio
            radioDisabled: "#8D8D8D",
            radioBorder: "#262626",
            radioHover: "#E0E0E0",

            // form
            otpBorder: "#F1C21B",
            borderForm: "#F1C21B",
            borderFormHover: "#8E6A00",
            borderFormActive: "#8E6A00",

            // button
            buttonSecondary: "#262626",
            buttonDefault: "#0F62FE",
            buttonActive: "#B28600",
            buttonHover: "#D2A106",
            buttonColor: "#F4F4F4",
            buttonPrimary: "#2196F3",

            // tracking numbercontainer
            borderTrackingNumber: "#F1C21B",

            // link expired
            iconStatus: '#C6C6C6',

            // scroll to top button
            scrollToTop: "#262626",

            // itemlist
            oddItemList: "#E7E7E7",

            // separatorStickyTitle
            separatorTitle: "#8D8D8D",

            //DeliveryCardMenuSearchBar
            deliveryCardMenuSearchBar: "#E0E0E0",
            deliverycardSearchIcon: "#8E6A00",

            //DeliveryCardCloseIcon
            deliveryCardCloseIcon: "#8E6A00",

            //delivery orders
            deliveryOrderTitle: "#DEFBE6",
            deliveryOrderTitleActive: "#F4E991",
            collapseIcon: "#262626",
            deliveryOrderBorderBottom: "#F1C21B",
            deliveryCardBorder: "#F1C21B",

            //NavigationDrawer
            menuItemHover: "#F4E991",
            menuItemClicked: "#F1C21B",
            menuItemActive: "#F1C21B",
            menuItemActiveIcon: "#FDDC69",

            drawerColor: "#A7F0BA",
            drawerPaper: "#F5ECC8",
            drawerSeparator: "#F1C21B",

            titleSeparator: "#A8A8A8",

            //Image carousel navigation
            carouselNavBackground: "#FFFDF4",
            carouselNavButton: "#8E6A00",
            carouselBulletBackground: "#FFFDF4",
            carouselBulletSelected: "#8E6A00",
            carouselBullet: "#C6C6C6",

            //Table row separator
            tableRowSeparator: "#F1C21B"
        },
        text: {
            primary: "#1A1919",
            heading1: "#1A1919",
            highlithText: "#000000",
            disabled: '#C6C6C6',

            // text for chip delivery card
            doneText: "#6F6F6F",
            doneIcon: "#A8A8A8",

            // button
            buttonPrimary: "#302400",
            buttonSecondary: "#ffffff",

            // text in dialog
            dialogHeadingText: "#1A1919",
            emailListText: "#000000",
            inputText: "#8D8D8D",
            inputTextActive: "#1A1919",
            emailListRole: "#909090",
            primaryButtonText: "#302400",

            // loading text
            loadingText: '#262626',
            setToDefault: "#1A1919",
            setToDefaultDisabled: "#8d8d8d",

            // top navbar text
            topNavbarText: '#0E6027',
            verticalSeparator: '#8E6A00',

            // canceled datetime text
            canceledText: '#C6C6C6',

            // item sub list text
            itemSubListText: '#6F6F6F'
        },
    }
});