import React from "react";
import DivFlexStart from "./DivFlexStart";
import { Box, Typography } from "@mui/material";
import { FontFamily } from "../Constants/FontFamily";
import CloseIcon from "../assets/icons/CloseIcon";
import { useTranslation } from "react-i18next";

const CanceledIconText = () => {

    const { t } = useTranslation()

    return (
        <DivFlexStart style={{ background: "#DA1E28", padding: '0 8px', borderRadius: 5 }} sx={{ ml: 1, height: 18, pt: 0 }}>
            <Box sx={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
                <CloseIcon
                    sx={{ fontSize: 16, marginRight: "4px" }}
                    color={"#FFD7D9"}
                />
            </Box>
            <Box sx={{ display: 'flex', height: 'auto', alignItems: 'center' }}>
                <Typography
                    fontSize={12}
                    color={"#F7F5F5"}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontWeight: 600
                    }}
                >
                    {t("canceled")}
                </Typography>
            </Box>
        </DivFlexStart>
    )
}

export default CanceledIconText;