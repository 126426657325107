import React, { useContext, useEffect, useState } from "react";

// theme
import { useTheme } from "@mui/material/styles";

// constants
import { FontFamily } from "../../../Constants/FontFamily";
import { BannerVariant } from "../../../Constants/BannerVariant";

// import icons
import AlertCircle from "../../../assets/icons/AlertCircle";

// context
import { AppContext } from "../../../App";

// components
import { Box, Typography } from "@mui/material";
import CustomDialog from "../DialogContainer/CustomDialog";
import CustomDialogContent from "../DialogContainer/CustomDialogContent";
import DivFlexCenter from "../../DivFlexCenter";
import Banner from "../../Banner/Banner";
import { useTranslation } from "react-i18next";
import { CORE_ACTION_POST_SIGN_IN } from "../../../Actions/CoreActions";

const EmailActivation = (props) => {
    const {
        isMobile,
        handleCloseDialog,
        handleOpenDialog,
        isLoadingLogin,
        setIsLoadingLogin,
        handleLogin,
        loggedinEmail,
        setLoggedinEmail
    } = useContext(AppContext)

    const { t } = useTranslation()

    const { isOpen } = props

    const theme = useTheme()

    const [timer, setTimer] = useState(60)
    const [isLinkExpired, setIsLinkExpired] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setTimer(60)
        setIsLinkExpired(false)
    }, [isOpen])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (timer > 0) {
                setTimer((prevTimer) => prevTimer - 1)
            }
            else {
                clearInterval(intervalId)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [timer])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLinkExpired(true)
        }, 5 * 60 * 1000) // 5 minutes

        return () => clearTimeout(timeoutId)
    }, [isLinkExpired])

    /* temporary artificial loading state */
    useEffect(() => {
        let timeoutId

        if (isLoadingLogin) {
            timeoutId = setTimeout(() => {
                setIsLoadingLogin(false)
            }, 2 * 1000)
        }

        return () => clearTimeout(timeoutId)
    }, [isLoadingLogin, setIsLoadingLogin])
    /* ================================ */

    const resendActivation = () => {
        if (timer <= 0 && !isLoading) {
            setIsLoading(true)
            CORE_ACTION_POST_SIGN_IN(
                loggedinEmail,
                res => {
                    setIsLoading(false)
                    if (res.status === 200 && res.data) {
                        if (res.data.message !== null && res.data.message === 'OK' && res.data.email === loggedinEmail) {
                            setTimer(60)
                            setIsLinkExpired(false)
                        }
                        else if (res.data.message !== null && res.data.message === 'BAD_REQUEST') {
                            setIsLinkExpired(true);
                        }
                        else if (res.data.message != null && res.data.message === 'EMAIL_NOT_FOUND') {
                            setIsLinkExpired(true);
                        }
                    }
                },
                err => {
                    setIsLoading(false)
                    console.log(err)
                }
            )
        }
    }

    const handleChangeEmail = () => {
        handleCloseDialog()
        handleOpenDialog('login')
    }

    const handleCloseActivationDialog = () => {
        handleCloseDialog()
    }

    return (
        <CustomDialog
            open={isOpen}
            theme={theme}
            onClose={handleCloseActivationDialog}
            maxWidth={"sm"}
        >
            <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                <CustomDialogContent
                    isMobile={isMobile}
                    theme={theme}
                    sx={{
                        p: isMobile ? '4px 16px 40px 16px' : '0 40px 64px 40px'
                    }}
                >
                    <DivFlexCenter sx={{ height: isMobile ? 20 : 40, mb: isMobile ? 3 : 6, textAlign: 'center' }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isMobile ? 22 : 48,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                lineHeight: isMobile ? 'normal' : '40px'
                            }}
                        >
                            {`Email Activation`}
                        </Typography>
                    </DivFlexCenter>

                    {isLinkExpired &&
                        <Banner
                            variant={BannerVariant.DANGER}
                            marginBottom={24}
                            fontSize={isMobile ? 12 : 18}
                            width={'100%'}
                            icon={<AlertCircle />}
                        >
                            {t("theActivationLinkIsExpired")}
                        </Banner>
                    }

                    <DivFlexCenter sx={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isMobile ? 14 : 18,
                                fontFamily: FontFamily.PLUSJAKARTASANS
                            }}
                        >
                            {t("activationEmailInformation")}
                            <br /><br />
                            {t("wereExcitedToHaveYouOnboard")}
                        </Typography>
                    </DivFlexCenter>

                    <DivFlexCenter sx={{ textAlign: 'center', mt: isMobile ? 5 : 8 }}>
                        <Typography
                            sx={{
                                color: (timer > 0 || isLoading) ? theme.palette.text.disabled : theme.palette.text.primary,
                                fontSize: isMobile ? 14 : 21,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                textDecorationLine: 'underline',
                                cursor: timer <= 0 && 'pointer'
                            }}
                            onClick={() => resendActivation()}
                        >
                            {`${t("resendActivationEmail")} ${timer > 0 ? `(${timer})` : ``}`}
                        </Typography>
                    </DivFlexCenter>

                    <DivFlexCenter sx={{ textAlign: 'center', mt: isMobile ? 10 : '74px' }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isMobile ? 14 : 21,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                textDecorationLine: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleChangeEmail()}
                        >
                            {t("useAnotherEmailAddress")}
                        </Typography>
                    </DivFlexCenter>
                </CustomDialogContent>
            </Box>
        </CustomDialog>
    )
}

export default EmailActivation