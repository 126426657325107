import React, { useContext } from "react";
import CustomDialog from "./DialogContainer/CustomDialog";
import { AppContext } from "../../App";

// import theme
import { styled, useTheme } from "@mui/material/styles";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import DivFlexCenter from "../DivFlexCenter";
import { Box, List, ListItem, ListItemIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FontFamily } from "../../Constants/FontFamily";
import CheckedIcon from "../../assets/icons/CheckedIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useTranslation } from "react-i18next";

/* =============== Styled Components ================ */
const TableHeadCustom = styled(TableCell)((props) => ({
    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
    fontSize: 21,
    top: -1,
    backgroundColor: props.theme.palette.background.headDialog,
    color: props.theme.palette.text.primary,
    borderBottom: 'none',
    padding: 0,
    textAlign: 'center',
    paddingBottom: 32
}))

const TableCellCustom = styled(TableCell)((props) => ({
    fontFamily: FontFamily.PLUSJAKARTASANS,
    color: props.theme.palette.text.primary,
    fontSize: 21,
    borderBottom: `1px solid ${props.theme.palette.background.tableRowSeparator}`,
    textAlign: props.textCenter ? 'center' : 'left',
    paddingLeft: props.firstColumn && 0,
    maxWidth: props.firstColumn && '400px'
}))

/* =============== EOL Styled Components ============= */

const RolesAccessDialog = (props) => {
    const {
        isMobile,
        isDesktop,
        handleOpenDialog,
        setOpenDialog
    } = useContext(AppContext)

    const { isOpen } = props

    const { t } = useTranslation()

    const theme = useTheme()

    const roleFeaturesList = [
        {
            feature: t("receivedTheETAAnnouncement"),
            superAdmin: true,
            admin: true,
            standard: true
        },
        {
            feature: t("accessTheCustomerPortal"),
            superAdmin: true,
            admin: true,
            standard: true
        },
        {
            feature: t("addEditAndDeleteEmails"),
            superAdmin: t("allEmails"),
            admin: t("adminAndStandardEmails"),
            standard: false
        },
        {
            feature: t("editCustomerPortalSetting"),
            superAdmin: true,
            admin: false,
            standard: false
        },
    ]

    const roleFeaturesListMobile = [
        {
            role: `Super Admin`,
            features: [
                `Received Payload & ETA announcement via email`,
                `Access Customer Portal`,
                `Can add, edit, or delete all email`,
                `Can edit Customer Portal's setting`
            ]
        },
        {
            role: `Admin`,
            features: [
                `Received Payload & ETA announcement via email`,
                `Access Customer Portal`,
                `Can add, edit, or delete Admin and Standard emails`,
                `Can NOT edit Customer Portal's setting`
            ]
        },
        {
            role: `Standard`,
            features: [
                `Received Payload & ETA announcement via email`,
                `Access Customer Portal`,
                `Can NOT add, edit, or delete any email`,
                `Can NOT edit Customer Portal setting`
            ]
        }
    ]

    const handleCloseRolesDialog = () => {
        // handleOpenDialog('emailList')
        setOpenDialog("")
    }

    const getAccessType = (access) => {
        const checkedIcon = <CheckedIcon sx={{ fontSize: '40px' }} htmlColor={"#42BE65"} />
        const closeIcon = <CloseIcon sx={{ fontSize: '40px' }} htmlColor={"#FA4D56"} />

        if (access === true) {
            return checkedIcon
        }
        if (access === false) {
            return closeIcon
        }
        return access
    }

    const ContentDesktop = () => {
        const { t } = useTranslation()

        return (
            <TableContainer sx={{ pb: 8, px: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableHeadCustom theme={theme}></TableHeadCustom>
                            <TableHeadCustom theme={theme}>{`${t("superAdmin")}`}</TableHeadCustom>
                            <TableHeadCustom theme={theme}>{`${t("admin")}`}</TableHeadCustom>
                            <TableHeadCustom theme={theme}>{`${t("standard")}`}</TableHeadCustom>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {roleFeaturesList.map((list, index) => (
                            <TableRow key={index} sx={{ pr: 24 }}>
                                <TableCellCustom firstColumn theme={theme}>{list.feature}</TableCellCustom>
                                <TableCellCustom textCenter>{getAccessType(list.superAdmin)}</TableCellCustom>
                                <TableCellCustom textCenter>{getAccessType(list.admin)}</TableCellCustom>
                                <TableCellCustom textCenter>{getAccessType(list.standard)}</TableCellCustom>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const ContentMobile = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {roleFeaturesListMobile.map((list, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            sx={{
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                fontSize: 12,
                                color: theme.palette.text.primary
                            }}
                        >
                            {list.role}
                        </Typography>

                        <List sx={{ py: 0 }}>
                            {list.features.map((feature, featureIndex) => (
                                <ListItem key={featureIndex} disablePadding sx={{ pt: 1, alignItems: 'start' }}>
                                    <ListItemIcon sx={{ minWidth: 0, pl: 0.75, pr: 0.75 }}>
                                        <Typography
                                            sx={{
                                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                                fontSize: 12,
                                                color: theme.palette.text.primary,
                                                top: '1px'
                                            }}
                                        >
                                            {`•`}
                                        </Typography>
                                    </ListItemIcon>
                                    <Typography
                                        sx={{
                                            fontFamily: FontFamily.PLUSJAKARTASANS,
                                            fontSize: 12,
                                            color: theme.palette.text.primary
                                        }}
                                    >
                                        {feature}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                ))}
            </Box>
        )
    }

    return (
        <CustomDialog
            open={isOpen}
            onClose={handleCloseRolesDialog}
            theme={theme}
            maxWidth={'lg'}
        >
            <CustomDialogContent
                isMobile={isMobile}
                theme={theme}
                sx={{
                    padding: isMobile ? '12px 16px 40px 16px' : '0 40px 64px 40px',
                    backgroundColor: theme.palette.background.dialog
                }}
            >
                {/* heading */}
                <DivFlexCenter sx={{ textAlign: 'center', mb: !isDesktop ? 4 : 10.875 }}>
                    <Typography
                        sx={{
                            color: theme.palette.text.primary,
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: !isDesktop ? 22 : 48,
                            lineHeight: isDesktop && '40px'
                        }}
                    >
                        {t("rolesAccess")}
                    </Typography>
                </DivFlexCenter>

                {/* body */}
                {!isDesktop ?
                    <ContentMobile />
                    :
                    <ContentDesktop />
                }

            </CustomDialogContent>
        </CustomDialog>
    )
}

export default RolesAccessDialog