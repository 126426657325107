
import { useState, useContext, useEffect } from "react"


import { AppContext } from "../../App"

import { Box } from "@mui/material"

import DivFlexCenter from "../DivFlexCenter"
import PromoCard from "../PromoCard/PromoCard"
import PromoDialog from "../DialogComponent/PromoDialog"


const PromoNews = (props) => {

    const { isDesktop, goToPromo } = useContext(AppContext)

    const { promoData, isLinkExpiredPage } = props

    const [openPromoDialog, setOpenPromoDialog] = useState(false)
    const [promoDetail, setPromoDetail] = useState(null);
    const [isLastPromo, setIsLastPromo] = useState(false);
    const [isFirstPromo, setIsFirstPromo] = useState(false);


    const handleClosePromoDialog = () => {
        setOpenPromoDialog(false);
    };

    const handleOpenPromoDialog = (promoId) => {
        if (promoId === promoDetail) {
            setOpenPromoDialog(false)
            setPromoDetail(null)
            goToPromo()
        }
        else {
            setOpenPromoDialog(true);
            // setIdPromoDialog(promoId)
            setPromoDetail(promoId);
        }
    };

    const handleNextPromoDetail = (promoLength) => {
        if (promoDetail !== promoLength - 1) {
            setPromoDetail(promoDetail + 1);
        }
    };

    const handlePrevPromoDetail = (promoLength) => {
        if (promoDetail > 0) {
            setPromoDetail(promoDetail - 1);
        }
    };

    const scrollToTargetPromo = (target) => {
        if (target !== null) {
            const element = document.getElementById(`promo-card-${target}`)
            if (element) {
                const topAdjusment = isLinkExpiredPage ? 62 : 168
                const elementPosition = element.getBoundingClientRect().top + window.scrollY - topAdjusment

                window.scrollTo({
                    top: elementPosition,
                    behavior: "smooth"
                })
            }
        }
    }

    useEffect(() => {
        if (promoDetail === promoData.length - 1) {
            setIsLastPromo(true);
        } else {
            setIsLastPromo(false);
        }

        if (promoDetail === 0) {
            setIsFirstPromo(true);
        } else {
            setIsFirstPromo(false);
        }
    }, [promoDetail]);

    // useEffect(() => {
    //     if (!isDesktop && openPromoDialog) {
    //         setOpenPromoDialog(false)
    //     }
    // }, [isDesktop])


    return (
        <>
            <Box>
                {promoData.map((promo, index) => (
                    <DivFlexCenter
                        id={`promo-card-${index}`}
                        key={index}
                        sx={{ mb: 3, mt: isDesktop ? 10 : 0 }}
                        onClick={isDesktop ? () => handleOpenPromoDialog(index) : undefined}
                    >
                        <PromoCard
                            promo={promo}
                            promoId={index}
                            openDetailPromo={index === promoDetail}
                            isMobile={isDesktop ? false : true}
                            removePadding={isDesktop ? !openPromoDialog : false}
                            handleOpenPromoDialog={() => handleOpenPromoDialog(index)}
                            promoDetail={promoDetail}
                            scrollToTargetPromo={scrollToTargetPromo}
                        />
                    </DivFlexCenter>
                ))}
            </Box>

            {isDesktop &&
                <PromoDialog
                    open={openPromoDialog}
                    onClose={handleClosePromoDialog}
                    promo={promoData[promoDetail]}
                    isFirstPromo={isFirstPromo}
                    isLastPromo={isLastPromo}
                    handlePrevPromoDetail={() =>
                        handlePrevPromoDetail(promoData.length)
                    }
                    handleNextPromoDetail={() =>
                        handleNextPromoDetail(promoData.length)
                    }
                />
            }

        </>
    )
}

export default PromoNews