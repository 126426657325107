import React, { useContext, useRef, useState } from "react";
import { Typography, Box, TableContainer } from "@mui/material";
import { FontFamily } from "../../Constants/FontFamily";
import { formatAddress } from "../../connector/Utils/DataUtils";

// import table from material ui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import TablePagination from '@mui/material/TablePagination';

// import styled and theme
import { styled, useTheme } from "@mui/material/styles";

// import Icon
import { useTranslation } from "react-i18next";
import { RoleEnum } from "../../Constants/RoleEnum";
import { AppContext } from "../../App";
import ChevronDown from "../../assets/icons/ChevronDown";
import ChevronUp from "../../assets/icons/ChevronUp";

/**========== Styled Component ========== */
// table head component
const TableHeadCustom = styled(TableCell)((props) => ({
  fontFamily: FontFamily.PLUSJAKARTASANS,
  fontSize: 18,
  // paddingLeft: 0,
  top: -1,
  backgroundColor: props.theme.palette.background.deliveryOrderTitle,
  color: props.theme.palette.variables.prominent,
  borderBottom: 'none',
  // padding: 0,
  // paddingBottom: 16
}))

const TableCellCustom = styled(TableCell)((props) => ({
  fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
  color: props.theme.palette.text.primary,
  // paddingLeft: 0,
  fontSize: 20,
  borderBottom: props.isLastItem ? 'none' : `1px solid ${props.theme.palette.background.tableRowSeparator}`
}))

// table data container component
const TableDataContainer = styled('div')((props) => ({
  maxHeight: 300,
  overflowY: 'auto'
}))
/**========== EOL Styled Component ========== */

// table row component
const TableRowCustom = styled('div')((props) => ({
  fontFamily: FontFamily.PLUSJAKARTASANS,
  fontSize: 18,
  lineHeight: '25px',
  fontWeight: '400px'
}))

function Row(props) {
  const { childrenComponent, row, extraParam = false, handleSetCurrentEmailForEdit, handleSetCurrentEmailForDelete, handleButtonAddNewEmail, handleOpenMenuOverflow, isEmailAutomation,
    handleShowEtaSettingList, handleIsChecked, handleSetToDefault, handleClickApplyOn, handleSelectAllConfig } = props;

  const { outletId, isMobile } = useContext(AppContext);

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: `2px solid ${theme.palette.variables.borderHover}` } }}>
        <TableCellCustom component="th" scope="row">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TableRowCustom>
              {formatAddress(row)}
            </TableRowCustom>
            {parseInt(row.id, 10) === parseInt(outletId, 10) && (
              <Chip label="Registered Outlet"
                sx={{
                  fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                  fontSize: 16,
                  backgroundColor: theme.palette.background.buttonDefault,
                  color: '#F7F5F5'
                }}
                style={{ marginLeft: 24 }} />
            )}
          </Box>
        </TableCellCustom>
        <TableCellCustom>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            style={{ float: "inline-end", color: theme.palette.background.iconColor }}
          >
            {open ? <ChevronUp sx={{ color: theme.palette.variables.icon }} /> : <ChevronDown sx={{ color: theme.palette.variables.icon }} />}
          </IconButton>
        </TableCellCustom>
      </TableRow>


      <TableCellCustom style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            {extraParam === true ? (
              isEmailAutomation ?
                React.cloneElement(childrenComponent, {
                  emailNotification: { ...row.currentUserConfig },
                  showEtaSettingList: handleShowEtaSettingList({ ...row.currentUserConfig }),
                  handleIsChecked: (data) => handleIsChecked(data, row.id),
                  handleSetToDefault: () => handleSetToDefault(row.id),
                  handleClickApplyOn: () => handleClickApplyOn(row.id),
                  handleSelectAllConfig: () => handleSelectAllConfig(row.id),
                  isMobile: isMobile,
                  isSetToDefaultDisabled: !row?.isUserConfigChanged,
                  isUserConfigChanged: row?.isUserConfigChanged,
                  selectAllConfig: row?.selectAllConfig
                },)
                :
                React.cloneElement(childrenComponent, {
                  emailList: row.outlet_emails,
                  handleButtonAddNewEmail: () => handleButtonAddNewEmail(row),
                  handleSetCurrentEmailForEdit: (_, list) => handleSetCurrentEmailForEdit(row, list),
                  handleSetCurrentEmailForDelete: (_, list) => handleSetCurrentEmailForDelete(row, list),
                  handleOpenMenuOverflow: (e, _, list) => handleOpenMenuOverflow(e, row, list),
                },
                )
            ) : (
              childrenComponent
            )}
          </Box>
        </Collapse>
      </TableCellCustom>
    </React.Fragment>
  );
}

const CollapsibleTable = (props) => {
  const { tableContainerRef, handleDetectOnScrollEnd, children, rows, setPage, page, setPageSize, pageSize, totalPage, totalData, extraParam = false, handleSetCurrentEmailForEdit, handleSetCurrentEmailForDelete, handleButtonAddNewEmail, handleOpenMenuOverflow, isEmailAutomation,
    handleShowEtaSettingList, handleIsChecked, handleSetToDefault, handleClickApplyOn, handleSelectAllConfig } = props;

  const { t } = useTranslation()

  return (
    <div>
      <TableContainer sx={{}} onScroll={handleDetectOnScrollEnd} ref={tableContainerRef}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableHeadCustom>{t('outlets')}</TableHeadCustom>
              <TableHeadCustom />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <Row
                extraParam={extraParam}
                childrenComponent={children}
                key={row.name} row={row}
                handleSetCurrentEmailForEdit={handleSetCurrentEmailForEdit}
                handleSetCurrentEmailForDelete={handleSetCurrentEmailForDelete}
                handleButtonAddNewEmail={handleButtonAddNewEmail}
                handleOpenMenuOverflow={handleOpenMenuOverflow}
                isEmailAutomation={isEmailAutomation}
                handleShowEtaSettingList={handleShowEtaSettingList}
                handleIsChecked={handleIsChecked}
                handleSetToDefault={handleSetToDefault}
                handleClickApplyOn={handleClickApplyOn}
                handleSelectAllConfig={handleSelectAllConfig}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalData}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </div>
  )
}

export default CollapsibleTable;