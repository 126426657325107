import React, { useContext } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import material UI
import { Typography } from "@mui/material";

// import react router dom
import { Outlet } from "react-router-dom";

// import context
import { AppContext } from "../../App";

// import component
import DivFlexCenter from "../DivFlexCenter";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import Button from "../Button";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

// import style and theme
import { useTheme } from "@mui/material/styles";

const LogoutConfirmationDialog = (props) => {

    const { isMobile, handleCloseDialog, handleLogout } = useContext(AppContext)
    const { isOpen } = props;

    const { t } = useTranslation()

    const theme = useTheme()


    return (
        <>
            <CustomDialog
                open={isOpen}
                onClose={handleCloseDialog}
                theme={theme}
                maxWidth={'md'}
            >
                <CustomDialogContent isMobile={isMobile} sx={{ padding: isMobile ? '8px 16px 24px 16px' : '2px 40px 64px 40px' }}>
                    <DivFlexCenter sx={{ height: isMobile ? 20 : 40, mb: isMobile ? 4.75 : 7 }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: isMobile ? 22 : 48,
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                lineHeight: isMobile ? 'normal' : '40px'
                            }}
                        >
                            {t("signOut")}
                        </Typography>
                    </DivFlexCenter>
                    <Typography
                        sx={{
                            fontSize: isMobile ? 12 : 21,
                            fontFamily: FontFamily.PLUSJAKARTASANS,
                            color: theme.palette.text.primary,
                            mb: isMobile ? 11.875 : 10,
                            textAlign: isMobile ? 'center' : 'left'
                        }}
                    >
                        {t("signOutConfirmation")}
                    </Typography>

                    <DivFlexSpaceBetween sx={{ flexDirection: isMobile ? 'column-reverse' : 'row', gap: isMobile ? 1.5 : '' }}>
                        <Typography
                            sx={{
                                textDecoration: 'underline',
                                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                cursor: 'pointer',
                                fontSize: isMobile ? 14 : 21,
                                color: theme.palette.text.primary
                            }}
                            onClick={handleCloseDialog}
                        >
                            {t("cancel")}
                        </Typography>

                        <Button style={{ padding: isMobile ? '12px 40px' : '16px 40px', width: isMobile ? '100%' : 'auto' }} onClick={handleLogout}>
                            <Typography
                                sx={{
                                    color: theme.palette.text.buttonPrimary,
                                    fontSize: isMobile ? 14 : 21,
                                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                    height: isMobile && '18px'
                                }}
                            >
                                {t("signOut")}
                            </Typography>
                        </Button>
                    </DivFlexSpaceBetween>
                </CustomDialogContent>
            </CustomDialog>
            <Outlet />
        </>

    )
}


export default LogoutConfirmationDialog