import { Typography } from "@mui/material";
import DivFlexStart from "../Components/DivFlexStart";
import DoneIcon from "../assets/icons/DoneIcon";
import CanceledIconText from "../Components/CanceledIconText";
import { FontFamily } from "../Constants/FontFamily";

export const getStatusChip = (data, theme, t) => {
    const { innerWidth: width } = window;
    return (
        <DivFlexStart>
            {/* {data.orderPositions.some((v) => {
          return v.warning === true;
        }) ? (
          width >= 1200 && width <= 1580 ? (
            <DiscrepancyIconChip />
          ) : (
            <DiscrepancyChip />
          )
        ) : (
          <></>
        )} */}

            {data.stopStatus === "FINISHED" ? (
                <DivFlexStart sx={{ ml: 1, height: 18, pt: 0 }}>
                    <DoneIcon
                        color={theme.palette.background.iconColor}
                        sx={{ fontSize: 16, mt: -0.3, marginRight: "4px" }}
                    />
                    <Typography
                        fontSize={12}
                        color={theme.palette.text.doneText}
                        sx={{ fontFamily: FontFamily.PLUSJAKARTASANS }}
                    >
                        {t('finished')}
                    </Typography>
                </DivFlexStart>
            ) : (
                <DivFlexStart sx={{ ml: 0, height: 0 }} />
            )}

            {data.stopStatus === "CANCELED" ? (
                <CanceledIconText />
            ) : (
                <DivFlexStart sx={{ ml: 0, height: 0 }} />
            )}
        </DivFlexStart>
    );
};