import React, { useCallback, useEffect, useState } from "react";

// MUI
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";

// Icons
import ChevronRight from "../../assets/icons/ChevronRight";
import ChevronLeft from "../../assets/icons/ChevronLeft";

export const usePrevNextButtons = (emblaApi) => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback((e) => {
        e.stopPropagation()
        if (!emblaApi) return
        emblaApi.scrollPrev()
    }, [emblaApi])

    const onNextButtonClick = useCallback((e) => {
        e.stopPropagation()
        if (!emblaApi) return
        emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback((emblaApi) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onSelect(emblaApi)
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
    }, [emblaApi, onSelect])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    }
}

const ButtonWrapper = (props) => {
    const { children, ...restProps } = props

    const theme = useTheme()

    return (
        <IconButton
            {...restProps}
            disableRipple
            sx={{
                display: 'flex',
                width: '28px',
                height: '28px',
                backgroundColor: theme.palette.background.carouselNavBackground,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {children}
        </IconButton>
    )
}

export const PrevButton = (props) => {
    const { children, ...restProps } = props

    const theme = useTheme()

    return (
        <ButtonWrapper {...restProps}>
            <ChevronLeft htmlColor={theme.palette.background.carouselNavButton} />
        </ButtonWrapper>
    )
}

export const NextButton = (props) => {
    const { children, ...restProps } = props

    const theme = useTheme()

    return (
        <ButtonWrapper {...restProps}>
            <ChevronRight htmlColor={theme.palette.background.carouselNavButton} />
        </ButtonWrapper>
    )
}