import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import PageNotFound from "../PageNotFound";
import { FontFamily } from "../../Constants/FontFamily";
// import { Snackbar } from '@commsult/ontego-ui'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Icon
import ArrowLeft from "../../assets/icons/ArrowLeft";

// import material UI
import { Typography, Grid, Box, CircularProgress, circularProgressClasses } from "@mui/material";

// import component
import TopNavbar from "../../Components/TopNavbar/TopNavbar";
import DivFlexCenter from "../../Components/DivFlexCenter";

// import table from material ui
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

// import styled and theme
import { styled, useTheme, alpha } from "@mui/material/styles";
import { CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID, CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES, CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID, CORE_ACTION_POST_CREATE_MULTIPLE_OUTLET_EMAIL_ADDRESSES } from "../../Actions/CoreActions";
import { getTextRoles } from "../../utils/util";
import { RoleEnum } from "../../Constants/RoleEnum";
import ConfirmationSnackbar from "../../Components/ConfirmationSnackbar/ConfirmationSnackbar";
import Snackbar from "../../Components/Snackbar/Snackbar";
import TableData from "./TableData";
import CollapsibleTable from "./CollapsibleTable";
import { cloneDeep, debounce } from "lodash";
import EmailAutomationData from "./EmailAutomationData";
import MultipleEmailAutomationDialog from "./MultipleEmailAutomationDialog";
import AddNewEmailDialog from "../../Components/DialogComponent/AddNewEmailDialog";
import DeleteEmailDialog from "../../Components/DialogComponent/DeleteEmailDialog";
import EditEmailDialog from "../../Components/DialogComponent/EditEmailDialog";
import { ETABehaviourConfigSettingStatus } from "../../Constants/ETABehaviourConfigSettingStatus";

/**========== Styled Component ========== */
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    border: "1px #D1D2D1 solid"
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
/**========== EOL Styled Component ========== */

const NoEmailAdded = (props) => {
    const { isDesktop } = useContext(AppContext)
    const { theme, marginTop = 5, marginBottom = 10 } = props

    return (
        <DivFlexCenter sx={{ flexDirection: 'column', gap: isDesktop ? 2 : 1, mt: marginTop, mb: marginBottom, textAlign: 'center' }}>
            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isDesktop ? 21 : 12, color: theme.palette.text.primary }}>
                {`No Email Added`}
            </Typography>

            <Typography sx={{ fontFamily: isDesktop ? FontFamily.PLUSJAKARTASANS : FontFamily.PLUSJAKARTASANSLIGHT, fontSize: isDesktop ? 21 : 12, color: theme.palette.text.primary }}>
                {`Please click the button below to add new email.`}
            </Typography>
        </DivFlexCenter>
    )
}

const ConfigurationPage = () => {
    const theme = useTheme();
    const param = useParams();
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    moment.locale('de');

    const {
        isMobile,
        currentEmail,
        setCurrentEmail,
        isDesktop,
        isLoading,
        setIsLoading,
        shouldShowSettings,
        isLogin,
        handleOpenNavigationDrawer,
        handleOpenDialog,
        persistedLinkString,
        handleLogin,
        setUserRole,
        userRole,
        outletId,
        loggedinEmail,
        handleDetectOnScrollEnd,
        emailList,
        setEmailList,
        setOpenDialog,
        initStateEmailList,
        setInitStateEmailList,
        isShowConfirmationSnackbar,
        setShowConfirmationSnackbar,
        showConfirmationSnackbar,
        showSuccessSnackbar,
        pageSize,
        setPageSize,
        page,
        setPage,
        totalPage,
        totalData,
        setSelectedOutlet,
        selectedOutlet,
        setSelectedEmail,
        tableContainerRef,
        etaBehaviorConfig,
        setDataToSaveConfigurationPage,
        dataToSaveConfigurationPage,
        isError,
        hasRegisterSelectedOutlets,
        openDialog,
        handleLogout,
        setOutletList,
        outletList,
        handleSearchOutlet,
        setInitOutletList,
        initOutletList
    } = useContext(AppContext);

    const dummyData = {
        isDeliveryConfirmed: true,
        isDeliveryCanceled: true,
        isDeliveryStarted: true,
        isEtaAnnounced: true,
        isEtaUpdated: true,
        isEtaChanged: true
    }

    const showEtaSettingList = {
        isDeliveryConfirmed: true,
        isDeliveryCanceled: true,
        isDeliveryStarted: true,
        isEtaAnnounced: true,
        isEtaUpdated: true,
        isEtaChanged: true
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [emailNotification, setEmailNotification] = useState(dummyData)
    const [initialData, setInitialData] = useState([]);
    const [isSetToDefaultDisabled, setIsSetToDefaultDisabled] = useState(true);
    const [listOfOutletsApplyOn, setListOfOutletsApplyOn] = useState([])
    const [applyOnFromOutletId, setApplyOnFromOutletId] = useState(null)
    const [isEmailAutomationDialogOpen, setIsEmailAutomationDialogOpen] = useState(false)
    const [querySearch, setQuerySearch] = useState("")
    const [noMoreDataExist, setNoMoreDataExist] = useState(false)
    const [automationConfigPayload, setAutomationConfigPayload] = useState([])
    const [debouncedQuery, setDebouncedQuery] = useState('');

    const open = Boolean(anchorEl);

    const handleClickOverflowMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseOverfowMenu = () => {
        setAnchorEl(null);
    };

    /** ========================== Search Outlet and Fetch outletList Data for the first time ========================== */

    useEffect(() => {
        setDebouncedQuery(querySearch);
    }, [querySearch]);

    useEffect(() => {
        handleSearchOutlet(debouncedQuery);

        return () => {
            handleSearchOutlet.cancel();
        };
    }, [debouncedQuery, handleSearchOutlet]);

    const handleChangeSearch = (event) => {
        setQuerySearch(event.target.value);
        setPage(0)
        setNoMoreDataExist(false)
    };

    /** ========================== EOL Search Outlet ========================== */

    /** ==========================Add,edit,delete new Email ========================== */

    const handleAddNewEmailTempState = (data) => {
        setShowConfirmationSnackbar(true)

        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            let tempData = cloneDeep(data)
            tempData.outletId = selectedOutlet.id;
            setDataToSaveConfigurationPage([...dataToSaveConfigurationPage, tempData])

            let clonedOutletList = cloneDeep(outletList)
            let tempOutletData = clonedOutletList.find((dataOutlet) => dataOutlet.id === selectedOutlet.id)
            tempOutletData.outlet_emails.push(data)
            setOutletList(clonedOutletList)
            setSelectedOutlet(null)
        } else {
            let tempData = cloneDeep(data)
            tempData.outletId = outletId;
            setDataToSaveConfigurationPage([...dataToSaveConfigurationPage, tempData])

            const newEmailList = cloneDeep(emailList);
            newEmailList.push({
                // might remove this id
                id: data.outlet_id,
                email: data.email,
                role: data.role
            })
            setEmailList(newEmailList)

        }
    }

    // select email for updated and open dialog
    const handleSetCurrentEmailForEdit = (outlet, email) => {
        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            setSelectedOutlet(outlet)
        }

        setSelectedEmail(email)
        handleOpenDialog('editEmail');
    };

    const handleEditNewEmailTempState = (data) => {

        let tempData = cloneDeep(data)

        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            let clonedOutletList = cloneDeep(outletList)
            let tempOutletData = clonedOutletList.find((dataOutlet) => dataOutlet.id === selectedOutlet.id)
            tempOutletData.outlet_emails = tempOutletData.outlet_emails.map((email) =>
                email.id === data.id ? data : email
            );

            const updatedOutletList = clonedOutletList.map((outlet) =>
                outlet.id === tempOutletData.id ? tempOutletData : outlet
            );
            setOutletList(updatedOutletList)
            setSelectedOutlet(null)

        } else {
            if (!data.id) {
                tempData.outletId = outletId;
            }

            const newEmailList = cloneDeep(emailList);
            let tempNewEmailList = newEmailList.map((email) => email.id === data.id ? data : email)
            setEmailList(tempNewEmailList)
        }

        setShowConfirmationSnackbar(true)
        setDataToSaveConfigurationPage([...dataToSaveConfigurationPage, tempData])

        setOpenDialog("")
    }

    // select email for deleted and open dialog
    const handleSetCurrentEmailForDelete = (outlet, email) => {
        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            setSelectedOutlet(outlet)
        }
        setSelectedEmail(email)
        handleOpenDialog('deleteEmail');
    };

    const handleDeleteEmailTempState = (data) => {
        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            let clonedOutletList = cloneDeep(outletList)
            let clonedDataToSaveConfigurationPage = cloneDeep(dataToSaveConfigurationPage)

            let currentlySelectedOutlet = clonedOutletList.find((dataOutlet) => dataOutlet.id === selectedOutlet.id)
            let filteredCurrentlySelectedOutletEmailList = currentlySelectedOutlet.outlet_emails.filter((eachEmail) => eachEmail.email !== data.email)
            const updatedOutletList = clonedOutletList.map((data) => {
                if (data.id === selectedOutlet.id) {
                    data.outlet_emails = filteredCurrentlySelectedOutletEmailList
                }
                return data;
            })

            setOutletList(updatedOutletList);

            if (data.id === null) {
                let filteredClonedDataToSaveConfigurationPage = clonedDataToSaveConfigurationPage.filter((eachData) => eachData.email !== data.email)
                setDataToSaveConfigurationPage(filteredClonedDataToSaveConfigurationPage)
            } else {
                let tempData = data
                tempData.loggedInEmail = loggedinEmail
                tempData.outletId = currentlySelectedOutlet.id
                setDataToSaveConfigurationPage([...dataToSaveConfigurationPage, tempData])
            }

            setSelectedOutlet(null)
        } else {

            // let tempData = data
            // tempData.loggedInEmail = loggedinEmail
            // setDataToSaveConfigurationPage([...dataToSaveConfigurationPage, tempData])

            let clonedMail = cloneDeep(emailList)

            const index = clonedMail.findIndex(item => item.id === data.id);

            clonedMail[index].loggedInEmail = loggedinEmail;

            // let newEmailList = clonedMail.filter((email) => email.id !== data.id)
            setEmailList(clonedMail)
        }
        setShowConfirmationSnackbar(true)

        setOpenDialog("")
    }

    const handleDeleteSelfEmailAndLogout = (data) => {
        setIsLoading(true)

        let clonedOutletList = cloneDeep(outletList)
        let currentlySelectedOutlet = clonedOutletList.find((dataOutlet) => dataOutlet.id === selectedOutlet.id)
        let tempData =
            [{
                email: data.email,
                outlet_id: currentlySelectedOutlet.id,
                role: data.role,
                loggedInEmail: loggedinEmail
            }]

        CORE_ACTION_POST_CREATE_MULTIPLE_OUTLET_EMAIL_ADDRESSES(userRole, false, tempData, res => {
            if (res.data) {
                handleLogout()
            }
        },
            err => {
                setShowConfirmationSnackbar(false)
                console.error(err)
            })

    }

    // NOTE: Use this to process all outletEmail data in batch
    const addNewEmail = () => {
        setIsLoading(true)
        let dataToModify = [];

        let dataToSendTemp = [];
        dataToSaveConfigurationPage.forEach((eachData) => {
            let tempDataToSend = {
                email: eachData.email,
                outlet_id: eachData.outletId,
                role: eachData.role,
                loggedInEmail: eachData.loggedInEmail ?? "",
            }
            if (eachData.id != null) {
                tempDataToSend.id = eachData.id;
            }
            dataToSendTemp.push(tempDataToSend)
        })

        // to save email automation in config page
        let tempAutomationConfigPayload = []
        outletList.forEach((eachData) => {
            const currentUserConfig = eachData.currentUserConfig
            let tempDataTosend = {
                email: loggedinEmail,
                outlet_id: eachData.id,
                userConfig: {
                    deliveryConfirmed: currentUserConfig?.isDeliveryConfirmed,
                    deliveryStarted: currentUserConfig?.isDeliveryStarted,
                    deliveryCanceled: currentUserConfig?.isDeliveryCanceled,
                    etaAnnounced: currentUserConfig?.isEtaAnnounced,
                    etaUpdated: currentUserConfig?.isEtaUpdated,
                    etaChanged: currentUserConfig?.isEtaChanged
                }
            }
            dataToSendTemp.push(tempDataTosend)
            tempAutomationConfigPayload.push({ ...eachData })
        })

        dataToModify = dataToSendTemp


        CORE_ACTION_POST_CREATE_MULTIPLE_OUTLET_EMAIL_ADDRESSES(userRole, true, dataToModify, res => {
            if (res.data) {
                const newEmailList = [];
                res.data.forEach((eachResData) => {
                    newEmailList.push({
                        id: eachResData.outlet_id,
                        email: eachResData.email,
                        role: eachResData.role
                    })
                })
                // setEmailList(newEmailList)
                setInitStateEmailList(newEmailList)
                setInitOutletList(outletList);
                setAutomationConfigPayload(tempAutomationConfigPayload)
                setDataToSaveConfigurationPage([])
                // handleOpenDialog('emailList');
                setOpenDialog("");
                setShowConfirmationSnackbar(false)

                setIsLoading(false)
            }
        },
            err => {
                setShowConfirmationSnackbar(false)
                setIsLoading(false)
                console.error(err)
            })
    };
    /** ==========================Eol Add,edit,delete new Email ========================== */

    const handleGeOutletDatasInfiniteScrolling = () => {
        if (!noMoreDataExist) {
            CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID(
                outletId, (page), pageSize, { loggedInEmail: loggedinEmail, searchString: querySearch },
                res => {
                    if (res.data.length > 0) {
                        setOutletList([...outletList, ...res.data]);
                    } else {
                        // setOutletList(res.data)
                    }

                    if (res.data.length <= 0) {
                        setNoMoreDataExist(true)
                    }
                    setIsLoading(false)
                },
                err => {
                    // console.log(err)
                    // setIsError(true)
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        if (page >= 1) {
            handleGeOutletDatasInfiniteScrolling()
        }
    }, [page])

    useEffect(() => {
        if (JSON.stringify(initialData) === JSON.stringify(emailNotification)) {
            setIsSetToDefaultDisabled(true)
        } else {
            setIsSetToDefaultDisabled(false)
        }
    }, [emailNotification])

    const handleCheckForMandatoryEtaAutomationSetting = (userConfig, type, showEtaSettingList) => {
        let disabledKey = type + "Disabled"
        switch (type) {
            case "isDeliveryConfirmed":
                if (showEtaSettingList[disabledKey]) {
                    return true
                } else {
                    return userConfig.deliveryConfirmed
                }
            case "isDeliveryStarted":
                if (showEtaSettingList[disabledKey]) {
                    return true
                } else {
                    return userConfig.deliveryStarted
                }
            case "isDeliveryCanceled":
                if (showEtaSettingList[disabledKey]) {
                    return true
                } else {
                    return userConfig.deliveryCanceled
                }
            case "isEtaAnnounced":
                if (showEtaSettingList[disabledKey]) {
                    return true
                } else {
                    return userConfig.etaAnnounced
                }
            case "isEtaUpdated":
                if (showEtaSettingList[disabledKey]) {
                    return true
                } else {
                    return userConfig.etaUpdated
                }
            case "isEtaChanged":
                if (showEtaSettingList[disabledKey]) {
                    return true
                } else {
                    return userConfig.etaChanged
                }
            default:
                break;
        }
    }

    useEffect(() => {
        if (outletList?.length > 0 && !outletList[0]?.currentUserConfig) {
            let tempListOfOutletsConfig = []

            outletList.map((eachOutlet) => {
                let outletEmailWithConfig = {
                    ...eachOutlet,
                    isUserConfigChanged: false,
                    currentUserConfig: { ...dummyData },
                    showEtaSettingList: handleShowEtaSettingList(),
                    selectAllConfig: false
                }
                const outletEmails = eachOutlet?.outlet_emails
                if (outletEmails?.length > 0) {
                    const currentOutletEmail = outletEmails.find((outletEmail) => outletEmail?.email === loggedinEmail)
                    const userConfig = currentOutletEmail?.userConfig
                    if (userConfig) {
                        outletEmailWithConfig.currentUserConfig = {
                            isDeliveryConfirmed: handleCheckForMandatoryEtaAutomationSetting(userConfig, "isDeliveryConfirmed", outletEmailWithConfig.showEtaSettingList),
                            isDeliveryStarted: handleCheckForMandatoryEtaAutomationSetting(userConfig, "isDeliveryStarted", outletEmailWithConfig.showEtaSettingList),
                            isDeliveryCanceled: handleCheckForMandatoryEtaAutomationSetting(userConfig, "isDeliveryCanceled", outletEmailWithConfig.showEtaSettingList),
                            isEtaAnnounced: handleCheckForMandatoryEtaAutomationSetting(userConfig, "isEtaAnnounced", outletEmailWithConfig.showEtaSettingList),
                            isEtaUpdated: handleCheckForMandatoryEtaAutomationSetting(userConfig, "isEtaUpdated", outletEmailWithConfig.showEtaSettingList),
                            isEtaChanged: handleCheckForMandatoryEtaAutomationSetting(userConfig, "isEtaChanged", outletEmailWithConfig.showEtaSettingList)
                        }
                        outletEmailWithConfig.showEtaSettingList = handleShowEtaSettingList(outletEmailWithConfig.currentUserConfig
                        )
                    }
                    outletEmailWithConfig.selectAllConfig = checkShouldChangeSelectAll(outletEmailWithConfig)
                }
                tempListOfOutletsConfig.push(outletEmailWithConfig)
            })

            if (tempListOfOutletsConfig?.length > 0) {
                setOutletList(tempListOfOutletsConfig)
                setInitialData(tempListOfOutletsConfig)
            }
            // else {
            //     setInitialData([])
            // }
        }
        // else {
        //     setInitialData([])
        // }
    }, [outletList])

    useEffect(() => {
        handleShowEtaSettingList({})
    }, [etaBehaviorConfig])

    useEffect(() => {
        if (applyOnFromOutletId) {
            // handleOpenDialog("multipleEmailAutomation")
            setIsEmailAutomationDialogOpen(true)
        }
    }, [applyOnFromOutletId])

    useEffect(() => {
        if (automationConfigPayload?.length > 0 && outletList?.length > 0) {
            const newOutletList = outletList?.map((eachOutlet) => {
                return {
                    ...eachOutlet,
                    isUserConfigChanged: false
                }
            })
            setInitialData(newOutletList)
            setOutletList(newOutletList)
        }
    }, [automationConfigPayload])

    const handleShowEtaSettingList = (etaUserConfig) => {
        let clonedShowEtaSettingList = showEtaSettingList

        // Delivery confirmed
        clonedShowEtaSettingList.isDeliveryConfirmed = (etaBehaviorConfig?.deliveryConfirmed || etaUserConfig?.deliveryConfirmed) ?? false;

        if ((etaBehaviorConfig?.deliveryConfirmedSetting === ETABehaviourConfigSettingStatus.MANDATORY)) {
            clonedShowEtaSettingList.isDeliveryConfirmedDisabled = true
        }

        // Delivery started
        if ((etaBehaviorConfig?.deliveryStarted) || (etaUserConfig?.deliveryStarted && Object.keys(etaUserConfig.deliveryStarted).length > 0)) {
            clonedShowEtaSettingList.isDeliveryStarted = true
        } else {
            clonedShowEtaSettingList.isDeliveryStarted = false
        }

        if ((etaBehaviorConfig?.deliveryStartedSetting === ETABehaviourConfigSettingStatus.MANDATORY)) {
            clonedShowEtaSettingList.isDeliveryStartedDisabled = true
        }

        // Delivery canceled
        clonedShowEtaSettingList.isDeliveryCanceled = (etaBehaviorConfig?.deliveryCanceled || etaUserConfig?.deliveryCanceled) ?? false;

        if ((etaBehaviorConfig?.deliveryCanceledSetting === ETABehaviourConfigSettingStatus.MANDATORY)) {
            clonedShowEtaSettingList.isDeliveryCanceledDisabled = true
        }

        // ETA announced
        if (etaBehaviorConfig?.firstRangeIsOneOrTwo || etaBehaviorConfig?.firstRangeIsComingSoon || (etaUserConfig?.isEtaAnnounced && Object.keys(etaUserConfig.isEtaAnnounced).length > 0)) {
            clonedShowEtaSettingList.isEtaAnnounced = true
        } else {
            clonedShowEtaSettingList.isEtaAnnounced = false
        }

        if (etaBehaviorConfig?.firstRangeIsOneOrTwoSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.firstRangeIsComingSoonSetting === ETABehaviourConfigSettingStatus.MANDATORY) {
            clonedShowEtaSettingList.isEtaAnnouncedDisabled = true;
        }

        // ETA updated
        if (etaBehaviorConfig?.newRangeIsOne || etaBehaviorConfig?.newRangeIsComingSoon || (etaUserConfig?.isEtaUpdated && Object.keys(etaUserConfig.isEtaUpdated).length > 0)) {
            clonedShowEtaSettingList.isEtaUpdated = true
        } else {
            clonedShowEtaSettingList.isEtaUpdated = false
        }

        if (etaBehaviorConfig?.newRangeIsOneSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.newRangeIsComingSoonSetting === ETABehaviourConfigSettingStatus.MANDATORY) {
            clonedShowEtaSettingList.isEtaUpdatedDisabled = true
        }

        // ETA changed
        if (etaBehaviorConfig?.earlyThanPrevRangeOneOrTwo || etaBehaviorConfig?.earlyThanPrevRangeComingSoon || etaBehaviorConfig?.laterThanPrevRange || (etaUserConfig?.isEtaChanged && Object.keys(etaUserConfig.isEtaChanged).length > 0)) {
            clonedShowEtaSettingList.isEtaChanged = true
        } else {
            clonedShowEtaSettingList.isEtaChanged = false
        }

        if (etaBehaviorConfig?.earlyThanPrevRangeOneOrTwoSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.earlyThanPrevRangeComingSoonSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.laterThanPrevRangeSetting === ETABehaviourConfigSettingStatus.MANDATORY) {
            clonedShowEtaSettingList.isEtaChangedDisabled = true
        }

        return clonedShowEtaSettingList
    }

    const handleIsChecked = (data, outletId) => () => {
        if (data && outletId) {
            setShowConfirmationSnackbar(true)
            const value = outletList.find((eachOutletConfig) => parseInt(eachOutletConfig.id, 10) === parseInt(outletId, 10))?.currentUserConfig[data]

            const tempListOfOutletsConfig = outletList.map((eachOutletConfig) => {
                if (parseInt(eachOutletConfig.id, 10) === parseInt(outletId, 10) && eachOutletConfig?.currentUserConfig) {

                    let tempData = {
                        ...eachOutletConfig,
                        isUserConfigChanged: true,
                        currentUserConfig: {
                            ...eachOutletConfig.currentUserConfig,
                            [data]: !value,
                        },
                    }
                    tempData.selectAllConfig = checkShouldChangeSelectAll(tempData)

                    return (tempData)
                }
                else {
                    return ({ ...eachOutletConfig })
                }
            })
            setOutletList(tempListOfOutletsConfig)
        }
    }

    const handleClickApplyOn = (outletId) => {
        if (outletId) {
            let sourceOutletIndex = null
            let listOfOutlets = outletList.map((eachOutletConfig, index) => {
                if (parseInt(eachOutletConfig.id, 10) === parseInt(outletId, 10)) {
                    sourceOutletIndex = index
                    return ({
                        ...eachOutletConfig,
                        isChecked: true,
                        isConfigSource: true
                    })
                } else {
                    return ({
                        ...eachOutletConfig,
                        isChecked: false,
                        isConfigSource: false
                    })
                }
            })
            listOfOutlets.unshift(listOfOutlets.splice(sourceOutletIndex, 1)[0])
            setApplyOnFromOutletId(outletId)
            setListOfOutletsApplyOn(listOfOutlets)
        }
        else {
            setApplyOnFromOutletId(null)
            setListOfOutletsApplyOn([])
        }
    }

    const handleSaveApplyOn = (outletId) => {
        const sourceConfig = outletList.find((outlet) => parseInt(outlet.id, 10) === parseInt(outletId, 10))

        const listOutletsToBeApplied = listOfOutletsApplyOn.map((outlet) => {
            if (outlet.isChecked) {
                return outlet.id
            }
        })

        if (sourceConfig) {
            const tempListOfOutletsConfig = outletList.map((outlet) => {
                if (listOutletsToBeApplied.includes(outlet.id)) {
                    let tempData = {
                        ...outlet,
                        currentUserConfig: sourceConfig.currentUserConfig
                    }
                    tempData.selectAllConfig = checkShouldChangeSelectAll(tempData)
                    return tempData
                } else {
                    return { ...outlet }
                }
            })
            setOutletList(tempListOfOutletsConfig)
        }
        handleCloseDialogMultipleEmailAutomation()
    }

    const handleSelectAllConfig = (outletId) => {
        setShowConfirmationSnackbar(true)
        const currentConfig = outletList.find((outlet) => parseInt(outlet.id, 10) === parseInt(outletId, 10))

        if (currentConfig) {
            const tempListOfOutletsConfig = outletList.map((eachOutlet) => {
                const currentShowEtaSetting = { ...eachOutlet.showEtaSettingList }
                const currentUserConfig = { ...eachOutlet.currentUserConfig }
                const selectAllConfig = eachOutlet.selectAllConfig
                const newUserConfig = {
                    ...eachOutlet.currentUserConfig,
                    isDeliveryStarted: currentShowEtaSetting?.isDeliveryStarted ? !selectAllConfig : currentUserConfig.isDeliveryStarted,
                    isEtaAnnounced: currentShowEtaSetting?.isEtaAnnounced ? !selectAllConfig : currentUserConfig.isEtaAnnounced,
                    isEtaUpdated: currentShowEtaSetting?.isEtaUpdated ? !selectAllConfig : currentUserConfig.isEtaUpdated,
                    isEtaChanged: currentShowEtaSetting?.isEtaChanged ? !selectAllConfig : currentUserConfig.isEtaChanged
                }

                if (parseInt(eachOutlet.id, 10) === parseInt(outletId, 10)) {
                    return {
                        ...eachOutlet,
                        selectAllConfig: !eachOutlet.selectAllConfig,
                        currentUserConfig: { ...newUserConfig }
                    }
                }
            })
            setOutletList(tempListOfOutletsConfig)
        }
    }

    const checkWithShowEta = (outletData) => {
        if (outletData) {
            let result = []
            const activeOptions = []
            const currentSelectAllState = outletData?.selectAllConfig

            const currentShowEtaSetting = outletData?.showEtaSettingList
            const currentUserConfig = outletData?.currentUserConfig

            if (currentShowEtaSetting?.isDeliveryStarted) {
                activeOptions.push("isDeliveryStarted")
            }
            if (currentShowEtaSetting?.isEtaAnnounced) {
                activeOptions.push("isEtaAnnounced")
            }
            if (currentShowEtaSetting?.isEtaUpdated) {
                activeOptions.push("isEtaUpdated")
            }
            if (currentShowEtaSetting?.isEtaChanged) {
                activeOptions.push("isEtaChanged")
            }

            if (currentSelectAllState) {
                if (activeOptions?.length > 0) {
                    activeOptions.map((eachOption) => {
                        if (!currentUserConfig[eachOption]) {
                            result.push(eachOption)
                        }
                    })
                }

                if (result?.length > 0) {
                    return true
                }
            } else {
                if (activeOptions?.length > 0) {
                    activeOptions.forEach((eachOption) => {
                        if (currentUserConfig[eachOption]) {
                            result.push(eachOption)
                        }
                    })
                }

                if (JSON.stringify(result) === JSON.stringify(activeOptions)) {
                    return true
                }
            }
        }
        return false
    }

    const checkShouldChangeSelectAll = (outletData) => {
        const currentSelectAllState = outletData.selectAllConfig
        if ((currentSelectAllState && checkWithShowEta(outletData)) ||
            (!currentSelectAllState && checkWithShowEta(outletData))) {
            return !currentSelectAllState
        }
        return currentSelectAllState
    }

    const handleCloseDialogMultipleEmailAutomation = () => {
        setApplyOnFromOutletId(null)
        setIsEmailAutomationDialogOpen(false)
    }

    useEffect(() => {
        if (!initStateEmailList) {
            setInitStateEmailList(emailList)
        }
    }, [emailList])

    useEffect(() => {
        let dataToSend = {
            userRole
        }
        const newEmail = { ...currentEmail }
        newEmail.email = loggedinEmail

        if (outletId) {
            CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID(
                dataToSend,
                outletId,
                res => {
                    if (res.data) {
                        let newEmailList = []
                        res.data.forEach((userEmail, index) => {
                            if (loggedinEmail === userEmail.email) {
                                setUserRole(userEmail.role)
                                newEmail.role = userEmail.role
                                setCurrentEmail(newEmail)
                                handleLogin()
                            }
                            if (userEmail.isDeletedAt === null || userEmail.isDeletedAt === undefined) {
                                newEmailList.push({
                                    id: userEmail.id,
                                    email: userEmail.email,
                                    role: userEmail.role
                                })
                            }
                        })
                        if (newEmail.role !== RoleEnum.SUPER_ADMIN) {
                            newEmailList = newEmailList.filter(data => data.role !== RoleEnum.SUPER_ADMIN);
                        }
                        setEmailList(newEmailList)
                        setInitStateEmailList(newEmailList)
                    }
                },
                err => {
                    console.error(err)
                }
            )
        } else {
            CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES(
                dataToSend,
                persistedLinkString,
                res => {
                    if (res.data) {
                        let newEmailList = []
                        res.data.forEach((userEmail, index) => {
                            if (loggedinEmail === userEmail.email) {
                                setUserRole(userEmail.role)
                                newEmail.role = userEmail.role
                                setCurrentEmail(newEmail)
                                handleLogin()
                            }
                            if (userEmail.isDeletedAt === null || userEmail.isDeletedAt === undefined) {
                                newEmailList.push({
                                    id: userEmail.id,
                                    email: userEmail.email,
                                    role: userEmail.role
                                })
                            }
                        })
                        if (newEmail.role !== RoleEnum.SUPER_ADMIN) {
                            newEmailList = newEmailList.filter(data => data.role !== RoleEnum.SUPER_ADMIN);
                        }
                        setEmailList(newEmailList)
                        setInitStateEmailList(newEmailList)
                    }
                },
                err => {
                    console.error(err)
                }
            )
        }
    }, [])

    const handleButtonAddNewEmail = (outlet) => {
        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            setSelectedOutlet(outlet)
        }
        setOpenDialog("addNewEmail")
    }

    const handleSetToDefault = (outlet_id) => {
        if (outlet_id) {
            const thisInitialData = initialData.find((initData) => parseInt(initData.id, 10) === parseInt(outlet_id, 10))?.currentUserConfig

            const tempListOfOutletsConfig = outletList.map((eachOutletConfig) => {
                if (eachOutletConfig.id === outlet_id) {
                    let tempData = {
                        ...eachOutletConfig,
                        isUserConfigChanged: false,
                        currentUserConfig: thisInitialData,
                    }
                    tempData.selectAllConfig = checkShouldChangeSelectAll(tempData)
                    return tempData
                }
                else {
                    return eachOutletConfig
                }
            })

            setOutletList(tempListOfOutletsConfig)
        }
    }

    const handleDiscardChanges = () => {
        setShowConfirmationSnackbar(false)
        setEmailList(initStateEmailList)
        let clonedOutletList = cloneDeep(initOutletList)
        setOutletList(clonedOutletList)
    }

    const handleSaveChanges = () => {
        addNewEmail()
    }

    const handleOpenMenuOverflow = (e, outlet, email) => {
        if (currentEmail.role === RoleEnum.SUPER_ADMIN) {
            setSelectedOutlet(outlet)
        }
        setSelectedEmail(email)
        handleClickOverflowMenu(e)
    }

    const handleEditOverFlowMenuClicked = () => {
        handleOpenDialog('editEmail');
        handleCloseOverfowMenu()
    }

    const handleDeleteOverFlowMenuClicked = () => {
        handleOpenDialog('deleteEmail');
        handleCloseOverfowMenu()
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <>

            <Grid item xs={12} md={12} lg={3}
                sx={{ pr: 5, paddingTop: (isDesktop) ? "40px" : "16px", height: 'calc(100vh - 25%)' }}
                bgcolor={{ xs: theme.palette.background.defaultMobile, lg: theme.palette.background.defaultPromo }}
            >
                <TopNavbar
                    shouldShowSettings={shouldShowSettings}
                    theme={theme}
                    isLogin={isLogin}
                    handleOpenNavigationDrawer={handleOpenNavigationDrawer}
                    handleOpenDialog={handleOpenDialog}
                />
            </Grid>

            {!isError ?
                (
                    <>
                        <Grid
                            container
                            sx={{
                                pr: isDesktop ? 5 : "",
                                pl: isDesktop ? 5 : 3,
                                pt: 4,
                                background: theme.palette.background.default,
                                justifyContent: "space-between"
                            }}
                            style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ArrowLeft style={{ fontSize: 40, cursor: "pointer" }} onClick={handleGoBack} />
                                <Typography
                                    sx={{
                                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                                        color: theme.palette.text.primary,
                                        fontSize: "1.5rem",
                                        pl: 3
                                    }}
                                >

                                    {t('1_1-1-11_drawer_menu1')}
                                </Typography>
                            </div>
                            <Search sx={{ mr: isDesktop ? 0 : 2 }}>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder={t('search')}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={handleChangeSearch}
                                />
                            </Search>
                        </Grid>

                        <Grid container
                            sx={{
                                pr: isDesktop ? 5 : "",
                                pl: isDesktop ? 5 : 3,
                                pt: 8,
                                // height: isDesktop ? "100vh" : "",
                                background: theme.palette.background.default
                            }}
                        >

                            {/* Email List */}
                            <Grid item xs={12} md={4} sx={{ pr: isDesktop ? 8 : 0 }}>
                                <div>
                                    <Typography
                                        sx={{
                                            fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                            color: theme.palette.variables.prominent,
                                            fontSize: 20
                                        }}
                                    >
                                        {t("1_1-5-2_section_header")}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: FontFamily.PLUSJAKARTASANS,
                                            color: theme.palette.variables.prominent,
                                            fontSize: 18
                                        }}
                                    >
                                        {t("2_1-5-2_section_content")}
                                    </Typography>
                                </div>
                            </Grid>

                            {isLoading ? (
                                <Grid item xs={12} md={8} sx={{ mt: isDesktop ? 0 : 12, textAlign: "center" }}>
                                    <CircularProgress
                                        size={isMobile ? "56px" : "72px"}
                                        sx={{
                                            color: theme.palette.background.circularLoading,
                                            // animationDuration: "0ms",
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: "round",
                                            },
                                        }}
                                        thickness={4.6}
                                        disableShrink
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={8} sx={{ mt: isDesktop ? 0 : 4 }}>
                                    {currentEmail.role === RoleEnum.SUPER_ADMIN ? (
                                        <CollapsibleTable
                                            rows={outletList}
                                            pageSize={pageSize}
                                            page={page}
                                            setPage={setPage}
                                            setPageSize={setPageSize}
                                            totalPage={totalPage}
                                            totalData={totalData}
                                            extraParam={true}
                                            handleSetCurrentEmailForEdit={handleSetCurrentEmailForEdit}
                                            handleSetCurrentEmailForDelete={handleSetCurrentEmailForDelete}
                                            handleButtonAddNewEmail={handleButtonAddNewEmail}
                                            handleDetectOnScrollEnd={handleDetectOnScrollEnd}
                                            tableContainerRef={tableContainerRef}
                                            handleOpenMenuOverflow={handleOpenMenuOverflow}
                                        >
                                            <TableData
                                                currentEmail={currentEmail}
                                                // emailList={outletList.outlet_emails}
                                                getTextRoles={getTextRoles}
                                                // handleSetCurrentEmailForEdit={handleSetCurrentEmailForEdit}
                                                // handleSetCurrentEmailForDelete={handleSetCurrentEmailForDelete}
                                                // handleButtonAddNewEmail={handleButtonAddNewEmail}
                                                isMobile={isMobile}
                                                handleOpenMenuOverflow={handleOpenMenuOverflow}
                                            />
                                        </CollapsibleTable>
                                    ) : (
                                        <TableData
                                            currentEmail={currentEmail}
                                            emailList={emailList}
                                            getTextRoles={getTextRoles}
                                            handleSetCurrentEmailForEdit={handleSetCurrentEmailForEdit}
                                            handleSetCurrentEmailForDelete={handleSetCurrentEmailForDelete}
                                            handleButtonAddNewEmail={handleButtonAddNewEmail}
                                            isMobile={isMobile}
                                            handleOpenMenuOverflow={handleOpenMenuOverflow}
                                        />
                                    )}

                                </Grid>
                            )}



                            {/* EOL Email List */}

                            <Grid item xs={12} sx={{ pt: 8, pb: 8 }}>
                                <div style={{ borderTop: `3px solid ${theme.palette.background.tableRowSeparator}` }} />
                            </Grid>

                            {/* Email Automation */}
                            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2, pr: isDesktop ? 8 : 0 }}>
                                <Typography
                                    sx={{
                                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                                        color: theme.palette.variables.prominent,
                                        fontSize: 20
                                    }}
                                >
                                    {t("3_1-5-2_section_header")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: FontFamily.PLUSJAKARTASANS,
                                        color: theme.palette.variables.prominent,
                                        fontSize: 18
                                    }}
                                >
                                    {t("4_1-5-2_section_content")}
                                </Typography>
                            </Grid>

                            {isLoading ? (
                                <Grid item xs={12} md={8} sx={{ mt: isDesktop ? 0 : 12, textAlign: "center" }}>
                                    <CircularProgress
                                        size={isMobile ? "56px" : "72px"}
                                        sx={{
                                            color: theme.palette.background.circularLoading,
                                            // animationDuration: "0ms",
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: "round",
                                            },
                                        }}
                                        thickness={4.6}
                                        disableShrink
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={8} sx={{ mt: isDesktop ? 0 : 4, mb: isDesktop && isShowConfirmationSnackbar ? '85px' : '170px' }}>
                                    {currentEmail.role === RoleEnum.SUPER_ADMIN ? (
                                        <CollapsibleTable
                                            rows={outletList}
                                            extraParam={true}
                                            isEmailAutomation={true}
                                            handleShowEtaSettingList={handleShowEtaSettingList}
                                            handleIsChecked={(e, outlet_id) => handleIsChecked(e, outlet_id)}
                                            handleSetToDefault={(outlet_id) => handleSetToDefault(outlet_id)}
                                            handleClickApplyOn={(outlet_id) => handleClickApplyOn(outlet_id)}
                                            handleSelectAllConfig={(outlet_id) => handleSelectAllConfig(outlet_id)}
                                        >
                                            <EmailAutomationData />
                                        </CollapsibleTable>
                                    ) : (
                                        <>
                                            <Box sx={{ display: 'flex', textAlign: isMobile ? 'center' : 'left' }}>
                                                <Typography sx={{ color: theme.palette.text.heading1, fontSize: isMobile ? 12 : 21, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                                    {t("latestEmailEtaNotification")}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                            )}

                            {/* EOL Email Automation */}

                        </Grid >
                    </>
                ) : (
                    isLoading ? (
                        <></>
                    ) : (
                        <PageNotFound />
                    )
                )
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseOverfowMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '& .MuiMenuItem-root': {
                        color: theme.palette.text.primary, // This will apply to all MenuItems
                        backgroundColor: theme.palette.background.default, // Background color
                    },
                    '& .MuiList-root': {
                        color: theme.palette.text.primary, // This will apply to all MenuItems
                        backgroundColor: theme.palette.background.default, // Background color
                    },
                }}
            >
                {/* <MenuItem onClick={handleEditOverFlowMenuClicked}>Edit</MenuItem> */}
                <MenuItem onClick={handleDeleteOverFlowMenuClicked}>
                    Delete
                </MenuItem>
            </Menu>
            <ConfirmationSnackbar
                id="confirmationSnackbar-adminPanelGeneralTab"
                show={showConfirmationSnackbar}
                backgroundColor={theme.palette.background.defaultPromo}
                textColor="white"
                onCancelClick={handleDiscardChanges}
                onSaveClick={handleSaveChanges}
            >
                Success
            </ConfirmationSnackbar>

            <Snackbar backgroundColor={theme.palette.background.defaultPromo} show={showSuccessSnackbar} animationDuration={3000}>
                Success
            </Snackbar>

            <MultipleEmailAutomationDialog
                isOpen={isEmailAutomationDialogOpen}
                listOfOutletsApplyOn={listOfOutletsApplyOn}
                setListOfOutletsApplyOn={setListOfOutletsApplyOn}
                applyOnFromOutletId={applyOnFromOutletId}
                handleClose={handleCloseDialogMultipleEmailAutomation}
                handleSaveApplyOn={handleSaveApplyOn}
                outletId={outletId}
            />

            <AddNewEmailDialog
                handleAddNewEmailTempState={handleAddNewEmailTempState}
                outletList={outletList}
                isOpen={openDialog === 'addNewEmail'}
            />
            <DeleteEmailDialog
                handleDeleteEmailTempState={handleDeleteEmailTempState}
                handleDeleteSelfEmailAndLogout={handleDeleteSelfEmailAndLogout}
                isOpen={openDialog === 'deleteEmail'}
            />
            <EditEmailDialog
                handleEditNewEmailTempState={handleEditNewEmailTempState}
                isOpen={openDialog === 'editEmail'}
            />
        </>
    )
}

export default ConfigurationPage;