import moment from "moment"

export const getFormatDate = (i18n, date, isShort) => {
    moment.locale(i18n.language)
    if (date && typeof date !== 'undefined') {
        let dayFormat = 'dd'

        if(i18n.language === 'en'){
            dayFormat = 'ddd'
        }

        if (isShort) {
            return moment(date, "YYYYMMDDTHH:mm").format(`${dayFormat}, L`)
        }
        else {
            return moment(date, "YYYYMMDDTHH:mm").format(`${dayFormat}, D. MMMM YYYY`)
        }
    } else {
        return "-"
    }

}


export const getTimeFormat = (datetime) => {
    // return moment(date, "YYYYMMDDTHH:mm").format("HH:mm")
    // return "08:30 - 09:30"
    if (datetime && typeof datetime !== 'undefined') {
        return moment(datetime, "YYYYMMDDTHH:mm").format("HH:mm")
    }
    else {
        return ""
    }
}