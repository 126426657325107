import React, { useContext, useEffect, useState } from "react";

import { Typography, Grid, Box, FormGroup } from "@mui/material";
import { FontFamily } from "../Constants/FontFamily";

// import Icon
import Checkbox from '../Components/Checkbox'
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { AppContext } from "../App";

const getCheckboxLabel = (key, t) => {
    if (key) {
        switch (key) {
            case 'isDeliveryConfirmed':
                return t('deliveryConfirmed')
            case 'isDeliveryStarted':
                return t('deliveryStarted')
            case 'isDeliveryCanceled':
                return t('deliveryCanceled')
            case 'isEtaAnnounced':
                return t('etaAnnounced')
            case 'isEtaUpdated':
                return t('etaUpdated')
            case 'isEtaChanged':
                return t('etaChanged')
        }
    }
}

const isCheckboxDisabled = (key, showEtaSettingList) => {
    // const disabledSettingList = ['isDeliveryConfirmed', 'isDeliveryCanceled']

    let settingIsMandatory = false;
    Object.keys(showEtaSettingList).forEach((eachData) => {
        let disabledKey = key + "Disabled"
        if (disabledKey === eachData) {
            settingIsMandatory = true
        }
    })
    if (settingIsMandatory) {
        return true
    }

    // if (key && disabledSettingList.includes(key)) {
    //     return true
    // }
    return false
}

const EmailAutomationForm = (props) => {
    const { isMobile } = useContext(AppContext)
    const { isAllSettingsDisabled, paddingBottom = 20, handleIsChecked, emailNotification, isSetToDefaultDisabled, handleSetToDefault, showEtaSettingList, isConfigurationPage, isUserConfigChanged, handleClickApplyOn, handleSelectAllConfig, selectAllConfig } = props
    const theme = useTheme();
    const { t } = useTranslation();
    let AllCheckboxIndex = 0;

    const [emailNotificationStatus, setEmailNotificationStatus] = useState({});

    useEffect(() => {
        let reorderedStatus = {
            "isDeliveryConfirmed": emailNotification?.isDeliveryConfirmed,
            "isDeliveryStarted": emailNotification?.isDeliveryStarted,
            "isDeliveryCanceled": emailNotification?.isDeliveryCanceled,
            "isEtaAnnounced": emailNotification?.isEtaAnnounced,
            "isEtaUpdated": emailNotification?.isEtaUpdated,
            "isEtaChanged": emailNotification?.isEtaChanged,
        };

        setEmailNotificationStatus(reorderedStatus)
    }, [emailNotification])

    return (
        <FormGroup>
            <Grid sx={{ marginTop: 0 }} container rowSpacing={isMobile ? 2 : 3}>
                {Object.keys(emailNotificationStatus).map((key, index) => {
                    if (showEtaSettingList[key]) {
                        AllCheckboxIndex++
                        return (
                            <Grid container item xs={12} rowSpacing={isMobile ? 2 : 3}>
                                {/* {AllCheckboxIndex === 3 &&
                                    <Grid item xs={12}>
                                        <Checkbox
                                            checked={selectAllConfig}
                                            onChange={handleSelectAllConfig}
                                            label={t('3_1-5-2_checkbox_text')}
                                        />
                                    </Grid>
                                } */}
                                <Grid item key={`eta-settings-checkbox-${key}`} xs={12}
                                // sx={{ paddingLeft: AllCheckboxIndex > 2 ? '32px' : 0 }}
                                >
                                    <Checkbox
                                        disabled={isCheckboxDisabled(key, showEtaSettingList)}
                                        checked={emailNotificationStatus[key]}
                                        onChange={handleIsChecked(key)}
                                        label={getCheckboxLabel(key, t)}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </Grid>

            {isConfigurationPage ?
                <Box sx={{ display: 'flex', gap: 4, mt: '36px', mb: '24px', flexDirection: isMobile ? 'column' : '' }}>
                    {!isAllSettingsDisabled && (
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: FontFamily.PLUSJAKARTASANS,
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    cursor: isSetToDefaultDisabled ? '' : 'pointer',
                                    color: isSetToDefaultDisabled ? theme.palette.text.setToDefaultDisabled : theme.palette.variables.primaryMain,
                                    textDecoration: 'underline'
                                }}
                                onClick={handleSetToDefault}
                            >
                                {t("1_1-5-2_button_tertiary")}
                            </Typography>
                        </Box>
                    )}
                    {isUserConfigChanged &&
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: FontFamily.PLUSJAKARTASANS,
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    cursor: isSetToDefaultDisabled ? '' : 'pointer',
                                    color: isSetToDefaultDisabled ? theme.palette.text.setToDefaultDisabled : theme.palette.variables.primaryMain,
                                    textDecoration: 'underline'
                                }}
                                onClick={handleClickApplyOn}
                            >
                                {t("2_1-5-2_button_tertiary")}
                            </Typography>
                        </Box>
                    }
                </Box>
                :
                <Typography sx={{
                    pb: paddingBottom,
                    textDecoration: 'underline',
                    marginTop: isMobile ? 3 : 6,
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    cursor: 'pointer',
                    fontSize: isMobile ? 14 : 21,
                    color: isSetToDefaultDisabled ? theme.palette.text.setToDefaultDisabled : theme.palette.variables.primaryP50,
                    width: 'fit-content'
                }} onClick={handleSetToDefault} >
                    {t("1_1-5-2_button_tertiary")}
                </Typography>
            }
        </FormGroup>
    )
}

export default EmailAutomationForm;