import React, { useContext } from "react";
import { AppContext } from "../../App";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/system";
import { ButtonSizeEnum } from "./ButtonSizeEnum";
import { ButtonVariantEnum } from "./ButtonVariantEnum";
import { Box, Typography } from "@mui/material";
import { FontFamily } from "../../Constants/FontFamily";

const getPadding = (size, variant, customPadding = false) => {
    if (size === ButtonSizeEnum.SMALL) {
        switch (variant) {
            case ButtonVariantEnum.PRIMARY:
                return '12px 40px'
            case ButtonVariantEnum.SECONDARY:
                return '12px 40px'
            case ButtonVariantEnum.TERTIARY:
                return '0'
            default:
                return '12px 40px'
        }
    }

    if (size === ButtonSizeEnum.MEDIUM) {
        switch (variant) {
            case ButtonVariantEnum.PRIMARY:
                return '16px 40px'
            case ButtonVariantEnum.SECONDARY:
                return '16px 40px'
            case ButtonVariantEnum.TERTIARY:
                return '0'
            default:
                return '16px 40px'
        }
    }

    if (size === ButtonSizeEnum.LARGE) {
        switch (variant) {
            case ButtonVariantEnum.PRIMARY:
                return '16px 40px'
            case ButtonVariantEnum.SECONDARY:
                return '16px 40px'
            case ButtonVariantEnum.TERTIARY:
                return '0'
            default:
                return '16px 40px'
        }
    }

    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return '16px 40px'
        case ButtonVariantEnum.SECONDARY:
            return '16px 40px'
        case ButtonVariantEnum.TERTIARY:
            return '0'
        default:
            return '16px 40px'
    }
}

const getDisabledBackgroundColor = (variant, theme) => {
    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return theme.palette.variables.buttonBgDisabled
        case ButtonVariantEnum.SECONDARY:
            return 'transparent'
        case ButtonVariantEnum.TERTIARY:
            return 'transparent'
        default:
            return theme.palette.variables.buttonBgDisabled
    }
}

const getBackgroundColor = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getDisabledBackgroundColor(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return theme.palette.variables.buttonBgDefault
        case ButtonVariantEnum.SECONDARY:
            return 'transparent'
        case ButtonVariantEnum.TERTIARY:
            return 'transparent'
        default:
            return theme.palette.variables.buttonBgDefault
    }
}

const getBackgroundColorHover = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getDisabledBackgroundColor(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return theme.palette.variables.buttonBgHover
        case ButtonVariantEnum.SECONDARY:
            return 'transparent'
        case ButtonVariantEnum.TERTIARY:
            return 'transparent'
        default:
            return theme.palette.variables.buttonBgHover
    }
}

const getBackgroundColorActive = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getDisabledBackgroundColor(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return theme.palette.variables.buttonBgClicked
        case ButtonVariantEnum.SECONDARY:
            return 'transparent'
        case ButtonVariantEnum.TERTIARY:
            return 'transparent'
        default:
            return theme.palette.variables.buttonBgClicked
    }
}

const getBorderDisabled = (variant, theme) => {
    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return 'none'
        case ButtonVariantEnum.SECONDARY:
            return `2px solid ${theme.palette.variables.buttonBgDisabled}`
        default:
            return 'none'
    }
}

const getBorderColor = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getBorderDisabled(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.SECONDARY:
            return `2px solid ${theme.palette.variables.buttonBgDefault}`
        default:
            return 'none'
    }
}

const getBorderHover = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getBorderDisabled(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.SECONDARY:
            return `2px solid ${theme.palette.variables.buttonBgHover}`
        default:
            return 'none'

    }
}

const getBorderActive = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getBorderDisabled(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.SECONDARY:
            return `2px solid ${theme.palette.variables.buttonBgClicked}`
        default:
            return 'none'
    }
}

const getBorderBottom = (variant, theme, isDisabled) => {
    if (isDisabled && variant === ButtonVariantEnum.TERTIARY) {
        return `1px solid ${theme.palette.variables.disabled}`
    }
    if (variant === ButtonVariantEnum.TERTIARY) {
        return `1px solid ${theme.palette.variables.primaryMain}`
    }
    return ''
}

const getBorderBottomHover = (variant, theme, isDisabled) => {
    if (isDisabled && variant === ButtonVariantEnum.TERTIARY) {
        return `1px solid ${theme.palette.variables.disabled}`
    }
    if (variant === ButtonVariantEnum.TERTIARY) {
        return `1px solid ${theme.palette.variables.primaryP40}`
    }
    return ''
}

const getBorderBottomActive = (variant, theme, isDisabled) => {
    if (isDisabled && variant === ButtonVariantEnum.TERTIARY) {
        return `1px solid ${theme.palette.variables.disabled}`
    }
    if (variant === ButtonVariantEnum.TERTIARY) {
        return `1px solid ${theme.palette.variables.primaryP60}`
    }
    return ''
}

const getTypographyColorDisabled = (variant, theme) => {
    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return 'rgba(244, 244, 244, 0.4)'
        case ButtonVariantEnum.SECONDARY:
            return theme.palette.variables.buttonFgDisabled
        case ButtonVariantEnum.TERTIARY:
            return theme.palette.variables.disabled
        default:
            return 'rgba(244, 244, 244, 0.4)'
    }
}

const getTypographyColorHover = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getTypographyColorDisabled(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return theme.palette.variables.buttonFg
        case ButtonVariantEnum.SECONDARY:
            return theme.palette.variables.prominent
        case ButtonVariantEnum.TERTIARY:
            return '#78A9FF'
        default:
            return theme.palette.variables.buttonFg
    }
}

const getTypographyColorActive = (variant, theme, isDisabled) => {
    if (isDisabled) {
        return getTypographyColorDisabled(variant, theme)
    }

    switch (variant) {
        case ButtonVariantEnum.PRIMARY:
            return theme.palette.variables.buttonFg
        case ButtonVariantEnum.SECONDARY:
            return theme.palette.variables.prominent
        case ButtonVariantEnum.TERTIARY:
            return theme.palette.variables.primaryP60
        default:
            return theme.palette.variables.buttonFg
    }
}

const StyledButton = styled(Box)(({ size, variant, theme, isDisabled, isFullWidth }) => ({
    display: 'flex',
    width: isFullWidth && variant !== ButtonVariantEnum.TERTIARY ? '100%' : 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: isDisabled ? '' : 'pointer',
    border: getBorderColor(variant, theme, isDisabled),
    borderBottom: getBorderBottom(variant, theme, isDisabled),
    boxSizing: 'border-box',
    backgroundColor: getBackgroundColor(variant, theme, isDisabled),
    padding: getPadding(size, variant),
    ':hover': {
        border: getBorderHover(variant, theme, isDisabled),
        borderBottom: getBorderBottomHover(variant, theme, isDisabled),
        backgroundColor: getBackgroundColorHover(variant, theme, isDisabled)
    },
    ':active': {
        border: getBorderActive(variant, theme, isDisabled),
        borderBottom: getBorderBottomActive(variant, theme, isDisabled),
        backgroundColor: getBackgroundColorActive(variant, theme, isDisabled)
    }
}))

const StyledTypography = styled(Typography)(({ theme, variant, isDisabled }) => ({
    ':hover': {
        color: getTypographyColorHover(variant, theme, isDisabled)
    },
    ':active': {
        color: getTypographyColorActive(variant, theme, isDisabled)
    }
}))

const NewButton = (props) => {
    const { isDesktop } = useContext(AppContext)

    const theme = useTheme()

    const {
        children,
        size,
        variant,
        onClick,
        isDisabled,
        isFullWidth
    } = props

    const getFontSize = () => {
        if (variant !== ButtonVariantEnum.TERTIARY) {
            switch (size) {
                case ButtonSizeEnum.SMALL:
                    return '14px'
                case ButtonSizeEnum.MEDIUM:
                    return '16px'
                case ButtonSizeEnum.LARGE:
                    return '20px'
                default:
                    return '16px'
            }
        }

        if (isDesktop) {
            return '18px'
        }
        return '14px'
    }

    const getLineHeight = () => {
        if (variant !== ButtonVariantEnum.TERTIARY) {
            switch (size) {
                case ButtonSizeEnum.SMALL:
                    return '17px'
                case ButtonSizeEnum.MEDIUM:
                    return '22px'
                case ButtonSizeEnum.LARGE:
                    return '28px'
                default:
                    return '22px'
            }
        }

        if (isDesktop) {
            return '22px'
        }
        return '17px'
    }

    const getTypographyColor = () => {
        if (isDisabled) {
            switch (variant) {
                case ButtonVariantEnum.PRIMARY:
                    return 'rgba(244, 244, 244, 0.4)'
                case ButtonVariantEnum.SECONDARY:
                    return theme.palette.variables.buttonFgDisabled
                case ButtonVariantEnum.TERTIARY:
                    return theme.palette.variables.disabled
                default:
                    return 'rgba(244, 244, 244, 0.4)'
            }
        }


        switch (variant) {
            case ButtonVariantEnum.PRIMARY:
                return theme.palette.variables.buttonFg
            case ButtonVariantEnum.SECONDARY:
                return theme.palette.variables.prominent
            case ButtonVariantEnum.TERTIARY:
                return theme.palette.variables.primaryMain
            default:
                return theme.palette.variables.buttonFg
        }
    }

    return (
        <StyledButton
            size={size}
            variant={variant}
            onClick={isDisabled ? () => { } : onClick}
            theme={theme}
            isDisabled={isDisabled}
            isFullWidth={isFullWidth}
        >
            <StyledTypography
                theme={theme}
                variant={variant}
                isDisabled={isDisabled}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANS,
                    fontWeight: 600,
                    fontSize: getFontSize(),
                    lineHeight: getLineHeight(),
                    color: getTypographyColor(),
                    margin: 0
                }}
            >
                {children}
            </StyledTypography>
        </StyledButton>
    )
}

export default NewButton