import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { Box, Typography } from "@mui/material";
import EmailAutomationForm from "../../Components/EmailAutomationForm";
import moment from "moment";
import { FontFamily } from "../../Constants/FontFamily";
import { areAllValuesFalse } from "../../utils/util";

const EmailAutomationData = (props) => {
    const theme = useTheme()
    const { t } = useTranslation()
    moment.locale('de')

    const [isAllSettingsDisabled, setIsAllSettingsDisabled] = useState(false)

    const {
        isMobile,
    } = useContext(AppContext)

    const {
        emailNotification,
        showEtaSettingList,
        handleIsChecked,
        handleSetToDefault,
        isSetToDefaultDisabled,
        isUserConfigChanged,
        handleClickApplyOn,
        selectAllConfig,
        handleSelectAllConfig
    } = props

    useEffect(() => {
        const allFalse = areAllValuesFalse(showEtaSettingList);
        if (allFalse) {
            setIsAllSettingsDisabled(true)
        }
    }, [showEtaSettingList])
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '32px', pl: 3 }}>
            <Box sx={{ display: 'flex', textAlign: isMobile ? 'left' : 'left' }}>
                <Typography sx={{ color: theme.palette.text.heading1, fontSize: isMobile ? 14 : 18, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                    {isAllSettingsDisabled ? t("etaAllDisabled") : t("latestEmailEtaNotification")}
                </Typography>
            </Box>
            <Box>
                <EmailAutomationForm
                    emailNotification={emailNotification}
                    showEtaSettingList={showEtaSettingList}
                    isSetToDefaultDisabled={isSetToDefaultDisabled}
                    handleIsChecked={(data, outletId) => handleIsChecked(data, outletId)}
                    handleSetToDefault={(outletId) => handleSetToDefault(outletId)}
                    paddingBottom={6}
                    isConfigurationPage={true}
                    isUserConfigChanged={isUserConfigChanged}
                    handleClickApplyOn={(outletId) => handleClickApplyOn(outletId)}
                    selectAllConfig={selectAllConfig}
                    handleSelectAllConfig={(outletId) => handleSelectAllConfig(outletId)}
                    isAllSettingsDisabled={isAllSettingsDisabled}
                />
            </Box>
        </Box>
    )
}

export default EmailAutomationData