import { createContext, useContext, useEffect, useState } from "react"
import SignUpBackground from "../../assets/images/signup_background.jpeg"
import loginBackground from "../../assets/images/loginBackground.png"
import { AppContext } from "../../App"
import { Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";

// import otp input library
// import OtpInput from "react-otp-input";
import { useParams, useSearchParams } from "react-router-dom"
import axios from "axios"
import SignUpMobile from "./SignUpMobile"
import SignUpDesktop from "./SignUpDesktop"
import { SignUpPages } from "../../Constants/SignUpPages"
import { TimerResendActivation } from "../../Constants/TimerResendActivation";
import EmailNotificationForEmailValidationDialog from "../../Components/DialogComponent/EmailNotificationForEmailValidationDialog";
import { CORE_ACTION_GET_SIGN_UP_WITH_KEY, CORE_ACTION_POST_SIGN_UP_EMAIL_CHECK, CORE_ACTION_POST_SIGN_UP_SEND_ACTIVATION_EMAIL } from "../../Actions/CoreActions";


// SignUpContext
export const SignUpContext = createContext({})

// const InputOtp = styled('input')((props) => ({
//     height: props.isMobile ? 35 : 50,
//     border: props.isOtpFalse ? `1px solid #da1e28` : `1px solid ${props.theme.palette.background.borderForm}`,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     textAlign: 'center',
//     fontFamily: FontFamily.PLUSJAKARTASANS,
//     fontSize: props.isMobile ? 12 : 20,
//     color: props.theme.palette.text.inputText,
//     backgroundColor: props.theme.palette.background.dialog,
//     ":focus": {
//         outline: 'none',
//         border: props.isOtpFalse ? `1px solid #da1e28` : `1px solid ${props.theme.palette.background.borderFormActive}`,
//     }
// }))

// const InputEmailFlow = props => {
//     const theme = props.theme

//     return (
//         <>
//             <div style={{ marginTop: 80 }}>
//                 <Typography
//                     fontSize={14}
//                     color={theme.palette.text.primary}
//                     sx={{
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         lineHeight: '40px',
//                         fontWeight: 600,
//                         fontSize: 32
//                     }}
//                 >
//                     {`Tracking Your Order`}
//                     <br />
//                     {`Better Than Ever!`}
//                 </Typography>
//             </div>

//             <div style={{ marginTop: 24 }}>
//                 <Typography
//                     fontSize={14}
//                     color={theme.palette.text.primary}
//                     sx={{
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         fontWeight: 400,
//                         fontSize: 18
//                     }}
//                 >
//                     {`To receive ETA and payload information via email for every delivery,`}
//                     <br />
//                     {`please register your email here.`}
//                 </Typography>
//             </div>

//             <div style={{ marginTop: 80 }}>
//                 <Typography
//                     fontSize={14}
//                     color={theme.palette.text.primary}
//                     sx={{
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         fontWeight: 600,
//                         fontSize: 16,
//                         marginRight: '13.5px',
//                         marginBottom: '8px'
//                     }}
//                 >
//                     {`Email`}
//                 </Typography>
//                 <TextFieldStyled
//                     onChange={props.handleChangeEmail}
//                     style={{ width: 505 }}
//                     placeholder="example@email.com"
//                 />
//             </div>

//             <div style={{ marginTop: 40 }}>
//                 <Button
//                     style={{ width: 208 }}
//                     onClick={props.handleSubmitEmail}
//                 >
//                     {`Submit`}
//                 </Button>
//             </div>
//         </>
//     )
// }

// const InputOTPFlow = props => {
//     const { theme, email, otpCode, isOtpFalse, handleChangeOtpCode, isMobile, isEmailSubmit } = props

//     useEffect(() => {
//         if (isEmailSubmit == true) {
//             onCountdownStart()
//         }
//     }, [isEmailSubmit])

//     const timerRef = useRef(null)
//     const [timer, setTimer] = useState('00');
//     const [enableResendOTP, setEnableResendOTP] = useState(false)

//     const getTimeRemaining = (e) => {
//         const total = Date.parse(e) - Date.parse(new Date());
//         const seconds = Math.floor((total / 1000) % 60);
//         const minutes = Math.floor((total / 1000 / 60) % 60);
//         const hours = Math.floor((total / 1000 / 60 / 60) % 24);
//         return {
//             total, hours, minutes, seconds
//         };
//     }

//     const startTimer = (e) => {
//         let { total, hours, minutes, seconds } = getTimeRemaining(e);

//         if (total >= 0) {
//             setTimer(seconds)
//         }

//         if (total == 0) {
//             setEnableResendOTP(true)
//         }
//     }

//     const clearTimer = (e) => {
//         setTimer('59');
//         setEnableResendOTP(false)

//         if (timerRef.current) clearInterval(timerRef.current);
//         const id = setInterval(() => {
//             startTimer(e);
//         }, 1000)
//         timerRef.current = id;
//     }

//     const getInitTime = () => {
//         let deadline = new Date();
//         deadline.setSeconds(deadline.getSeconds() + 59);
//         return deadline;
//     }

//     const onCountdownStart = () => {
//         clearTimer(getInitTime());
//     }

//     return (
//         <>
//             <div style={{ marginTop: 80 }}>
//                 {isOtpFalse && (
//                     <Banner>
//                         {`The OTP code has expired. Please resend the OTP code.`}
//                     </Banner>
//                 )}
//             </div>

//             <div style={{ marginTop: 40 }}>
//                 <Typography
//                     color={theme.palette.text.primary}
//                     sx={{
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         fontWeight: 400,
//                         fontSize: 18
//                     }}
//                 >
//                     {`We have sent OTP code to`}
//                     <span
//                         style={{
//                             fontFamily: FontFamily.PLUSJAKARTASANS,
//                             fontWeight: 700,
//                             fontSize: 18,
//                             marginLeft: 6
//                         }}
//                     >
//                         {`${email}.`}
//                     </span>
//                     {` The OTP code will expire in`}
//                 </Typography>
//                 <Typography
//                     color={theme.palette.text.primary}
//                     sx={{
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         fontWeight: 400,
//                         fontSize: 18
//                     }}
//                 >
//                     {`5 minutes. Please check your email.`}
//                 </Typography>
//             </div>

//             <div style={{ marginTop: 80 }}>
//                 <Typography
//                     color={theme.palette.text.primary}
//                     sx={{
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         fontWeight: 400,
//                         fontSize: 18
//                     }}
//                 >
//                     {`Input your OTP here`}
//                 </Typography>
//                 <div style={{ width: 452, marginTop: 16 }}>
//                     <CustomOtpInput
//                         inputLength={6}
//                         handleChangeInput={handleChangeOtpCode}
//                         isOtpFalse={false}
//                     />
//                 </div>
//             </div>

//             <div style={{ marginTop: 40 }}>
//                 <Typography
//                     color={theme.palette.text.primary}
//                     sx={{
//                         width: 'fit-content',
//                         fontFamily: FontFamily.PLUSJAKARTASANS,
//                         fontWeight: 400,
//                         fontSize: 21,
//                         textDecorationLine: 'underline',
//                         fontStyle: 'normal',
//                         color: !enableResendOTP && '#8D8D8D',
//                         userSelect: !enableResendOTP ? 'none' : 'auto',
//                         cursor: !enableResendOTP ? 'not-allowed' : 'pointer'
//                     }}
//                     onClick={onCountdownStart}
//                 >
//                     {`Resend OTP Code (${timer})`}
//                 </Typography>
//             </div>

//             <div style={{ marginTop: 40 }}>
//                 <Button
//                     style={{ width: 208 }}
//                     onClick={props.handleSubmitEmail}
//                 >
//                     {`Submit`}
//                 </Button>
//             </div>
//         </>
//     )
// }

const SignUp = () => {
    const { setShowAppBar, setShouldShowSettings, logo, isLoadingLogin, setIsLoadingLogin, setIsError, isDesktop, isLogin,
        handleOpenDialog,
        currentPage,
        setCurrentPage,
        expiredTimer,
        setExpiredTimer,
        resendTimer,
        setResendTimer,
        signUpImage,
        getOutletWithMappedOutletEmails,
        isHasGetOutletWithConfig,
        setIsEmailAutomationDialogOpen,
        isEmailAutomationDialogOpen
    } = useContext(AppContext)
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    const [searchParams, setSearchParams] = useSearchParams()

    const urlParam = useParams()

    // Constant timer var (s)
    // const RESEND_TIMER = 60
    // const EXPIRED_TIMER = 5 * 60

    // Flow
    const [isFromQRScan, setIsFromQRScan] = useState(false)
    // const [currentPage, setCurrentPage] = useState("")
    // Email
    const [email, setEmail] = useState("")
    const [isEmailSubmit, setIsEmailSubmit] = useState(false)
    const [isEmailEmpty, setIsEmailEmpty] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isEmailExist, setIsEmailExist] = useState(false)

    // OTP (legacy)
    const [isOtpFalse, setIsOtpFalse] = useState(false)
    const [otpCode, setOtpCode] = useState("")
    // Registration code
    const [registrationCode, setRegistrationCode] = useState("")
    const [isRegCodeEmpty, setIsRegCodeEmpty] = useState(false)
    const [isRegCodeNotFound, setIsRegCodeNotFound] = useState(false)


    const [resendActivationEmailLoading, setResendActivationEmailLoading] = useState(false)

    const [regOutletId, setRegOutletId] = useState(null)
    // Email activation countdown
    // const [resendTimer, setResendTimer] = useState(TimerResendActivation.RESEND_TIMER)
    // const [expiredTimer, setExpiredTimer] = useState(TimerResendActivation.EXPIRED_TIMER)

    const handleCloseEmailAutomationDialog = () => setIsEmailAutomationDialogOpen(false)

    useEffect(() => {
        let registrationCode = searchParams.get("key")

        setIsLoadingLogin(true)

        setShowAppBar(false)
        setShouldShowSettings(false)

        // set sign up flow by its source
        setIsFromQRScan(false)

        if (typeof registrationCode !== 'undefined' && registrationCode && registrationCode !== "") {
            setRegistrationCode(registrationCode)
            CORE_ACTION_GET_SIGN_UP_WITH_KEY(
                registrationCode,
                res => {
                    setRegOutletId(res.data.outlet_id)
                    setIsRegCodeNotFound(false)
                    setCurrentPage(SignUpPages.INPUT_REG_EMAIL)
                },
                err => {
                    // console.log(err)
                    setIsRegCodeNotFound(true)
                    setCurrentPage(SignUpPages.INPUT_REG_CODE)
                })
        }

        // if (typeof urlParam.registrationCode !== 'undefined' && urlParam.registrationCode) {
        //     setRegistrationCode(urlParam.registrationCode)
        //     CORE_ACTION_GET_SIGN_UP_WITH_KEY(
        //         urlParam.registrationCode,
        //         res => {
        //             // console.log(res)
        //             setIsRegCodeNotFound(false)
        //             setCurrentPage(SignUpPages.INPUT_REG_EMAIL)
        //         },
        //         err => {
        //             // console.log(err)
        //             setIsRegCodeNotFound(true)
        //             setCurrentPage(SignUpPages.INPUT_REG_CODE)
        //         })
        // }
    }, [])

    useEffect(() => {
        if (otpCode.length === 6) {
            setIsOtpFalse(false)
            setIsLoadingLogin(true)

            const body = {
                otp: otpCode,
                email: email
            }

            axios.post(`http://localhost:3002/api/core/eta/signUp/otp/validate`, body)
                // axios.get(`https://gotraces-api.gotraces.de/api/core/eta/signUp/${urlParam.key}`)
                .then(function (response) {
                    // console.log(response.status)
                    setIsLoadingLogin(false)
                    if (response.data.message === "ERROR") {
                        setIsOtpFalse(true)
                    }
                    else {
                        alert("OTP successfully validated!")
                    }
                })
                .catch(function (err) {
                    setIsLoadingLogin(false)
                    setIsOtpFalse(true)
                    console.error(err)
                })
        }
    }, [otpCode])

    useEffect(() => {
        if (isFromQRScan) {
            setCurrentPage(SignUpPages.SCAN_QR_EMAIL)
        }
        else {
            setCurrentPage(SignUpPages.INPUT_REG_CODE)
        }
    }, [isFromQRScan])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer((prevResendTimer) => prevResendTimer - 1)
            }
            else {
                clearInterval(intervalId)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [resendTimer])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (expiredTimer > 0) {
                setExpiredTimer((prevExpiredTimer) => prevExpiredTimer - 1)
            }
            else {
                clearInterval(intervalId)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [expiredTimer])

    useEffect(() => {
        if (isHasGetOutletWithConfig) {
            setIsEmailAutomationDialogOpen(true)
        }
    }, [isHasGetOutletWithConfig])

    const isValidEmail = (value) => {
        return /\S+@\S+\.\S+/.test(value);
    }

    const isRegCodeValid = (value) => {
        if (value.length >= 9) { // temporary artificial check
            return true
        }
        return false
    }

    // const handleChangeOtpCode = (e) => {
    //     setOtpCode(otpCode + e.target.value);
    // };

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    }

    const handleChangeRegCode = e => {
        setRegistrationCode(e.target.value.toUpperCase())
    }

    const handleSubmitEmail = () => {
        if (email.length > 0) {
            setIsEmailEmpty(false)

            if (isValidEmail(email)) {
                setIsEmailSubmit(true)
                setIsEmailInvalid(false)
                setIsEmailExist(false)

                CORE_ACTION_POST_SIGN_UP_EMAIL_CHECK(
                    registrationCode,
                    email,
                    res => {
                        getOutletWithMappedOutletEmails(regOutletId, email)
                    },
                    err => {
                        if (err.response && err.response.status === 500) {
                            setIsEmailExist(true)
                        }
                    }
                )
            }
            else {
                setIsEmailInvalid(true)
            }
        }
        else {
            setIsEmailEmpty(true)
        }
    }

    const handleSubmitRegCode = () => {
        if (registrationCode.length > 0) {
            setIsRegCodeEmpty(false)

            CORE_ACTION_GET_SIGN_UP_WITH_KEY(
                registrationCode,
                res => {
                    if (res?.data) {
                        setRegOutletId(res.data.outlet_id)
                    }
                    setIsRegCodeNotFound(false)
                    setCurrentPage(SignUpPages.INPUT_REG_EMAIL)
                },
                err => {
                    setIsRegCodeNotFound(true)
                    setCurrentPage(SignUpPages.INPUT_REG_CODE)
                })
        }
        else {
            setIsRegCodeEmpty(true)
        }
    }

    const handleUseAnotherEmail = () => {
        if (isFromQRScan) {
            setCurrentPage(SignUpPages.SCAN_QR_EMAIL)
        }
        else {
            setCurrentPage(SignUpPages.INPUT_REG_EMAIL)
        }
    }

    const resendActivation = () => {
        if (resendTimer <= 0) {
            if (isValidEmail(email)) {
                setResendActivationEmailLoading(true)
                setIsEmailSubmit(true)
                setIsEmailInvalid(false)
                setIsEmailExist(false)

                CORE_ACTION_POST_SIGN_UP_SEND_ACTIVATION_EMAIL(
                    registrationCode,
                    email,
                    res => {
                        setResendActivationEmailLoading(false)
                        setResendTimer(TimerResendActivation.RESEND_TIMER)
                        setExpiredTimer(TimerResendActivation.EXPIRED_TIMER)
                    },
                    err => {
                        setResendActivationEmailLoading(false)
                        console.error(err)
                    }
                )
            }
            else {
                setIsEmailInvalid(true)
            }
        }
    }

    const SignUpContextValue = {
        currentPage,
        setCurrentPage,

        email,
        isEmailSubmit,
        isEmailEmpty,
        isEmailInvalid,
        isEmailExist,
        setIsEmailExist,
        handleChangeEmail,
        handleSubmitEmail,

        registrationCode,
        isRegCodeEmpty,
        isRegCodeNotFound,
        handleChangeRegCode,
        handleSubmitRegCode,

        handleUseAnotherEmail,

        resendTimer,
        expiredTimer,
        resendActivation,
        resendActivationEmailLoading,

        regOutletId
    }

    return (
        <SignUpContext.Provider value={SignUpContextValue}>
            <Box container sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}>
                {!isLoadingLogin && (
                    isDesktop ?
                        <>
                            {/* <Box sx={{ position: isDesktop ? 'relative' : 'fixed', backgroundColor: 'gray', minHeight: '100vh', height: '100%' }}>
                            <img src={isMobile ? SignUpBackgroundMobile : SignUpBackground} style={{ objectFit: 'cover', objectPosition: 'center', height: '100%', width: 'auto', minHeight: '100vh' }} />
                        </Box> */}

                            {/* // <div style={{ width: '60vw', height: '100vh', paddingLeft: 115, paddingTop: 104, boxSizing: 'border-box' }}>
                    //     <div
                    //         style={{ width: 207, height: 63 }}
                    //     >
                    //         <img src={logo} alt="company logo" style={{ height: 63, width: 207 }} />
                    //     </div>

                    //     <>
                    //         {isEmailSubmit ? (
                    //             <InputEmailFlow
                    //                 theme={theme}
                    //                 handleChangeEmail={handleChangeEmail}
                    //                 handleSubmitEmail={handleSubmitEmail}
                    //             />
                    //         ) : (
                    //             <InputOTPFlow
                    //                 theme={theme}
                    //                 email={email}
                    //                 otpCode={otpCode}
                    //                 isOtpFalse={isOtpFalse}
                    //                 handleChangeOtpCode={handleChangeOtpCode}
                    //                 isMobile={isMobile}
                    //                 isEmailSubmit={isEmailSubmit}
                    //             />
                    //         )}
                    //     </>
                    // </div> */}
                            <SignUpDesktop img={signUpImage ? signUpImage : loginBackground} />
                        </>
                        :
                        <SignUpMobile img={signUpImage ? signUpImage : loginBackground} />
                )}
            </Box>

            {isEmailAutomationDialogOpen && (
                <EmailNotificationForEmailValidationDialog
                    isOpen={isEmailAutomationDialogOpen}
                    handleClose={handleCloseEmailAutomationDialog}
                />
            )}
        </SignUpContext.Provider>
    )
}

export default SignUp