import React, { useContext, useEffect } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import material UI
import { Box, TableContainer, Typography } from "@mui/material";
// import table from material ui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// import Icon
import DeleteIcon from "../../assets/icons/DeleteIcon";
import EditIcon from "../../assets/icons/EditIcon";
import HintIcon from "../../assets/icons/HintIcon";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";


// import react router dom
import { Outlet, useParams } from "react-router-dom";

// import context
import { AppContext } from "../../App";

// import  component
import DivFlexCenter from "../DivFlexCenter";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import CustomDialog from "./DialogContainer/CustomDialog";
import Button from "../Button";

// import styled and theme
import { styled, useTheme } from "@mui/material/styles";
import DivFlexStart from "../DivFlexStart";
import { CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES, CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID } from "../../Actions/CoreActions";
import { convertRole, getTextRoles } from "../../utils/util";
import { RoleEnum } from "../../Constants/RoleEnum";

/**========== Styled Component ========== */
// table head component
const TableHeadCustom = styled(TableCell)((props) => ({
    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
    fontSize: 21,
    // paddingLeft: 0,
    top: -1,
    backgroundColor: props.theme.palette.background.headDialog,
    color: props.theme.palette.text.primary,
    borderBottom: 'none',
    padding: 0,
    // paddingBottom: 16
}))

const TableCellCustom = styled(TableCell)((props) => ({
    fontFamily: FontFamily.PLUSJAKARTASANS,
    color: props.theme.palette.text.primary,
    paddingLeft: 0,
    fontSize: 20,
    borderBottom: props.isLastItem ? 'none' : `1px solid ${props.theme.palette.background.tableRowSeparator}`
}))

// table data container component
const TableDataContainer = styled('div')((props) => ({
    maxHeight: 300,
    overflowY: 'auto'
}))


/**========== EOL Styled Component ========== */

const RolesTableHead = (props) => {
    const { handleOpenDialog } = useContext(AppContext)
    const { theme } = props

    const { t } = useTranslation()

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    fontSize: 21
                }}
            >
                {t("roles")}
            </Typography>
            <HintIcon
                htmlColor={theme.palette.background.iconColor}
                onClick={() => handleOpenDialog('rolesAccess')}
                sx={{
                    cursor: 'pointer',
                    mt: '-4px'
                }}
            />
        </Box>
    )
}

const NoEmailAdded = (props) => {
    const { isDesktop } = useContext(AppContext)
    const { theme, marginTop = 5, marginBottom = 10 } = props

    return (
        <DivFlexCenter sx={{ flexDirection: 'column', gap: isDesktop ? 2 : 1, mt: marginTop, mb: marginBottom, textAlign: 'center' }}>
            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isDesktop ? 21 : 12, color: theme.palette.text.primary }}>
                {`No Email Added`}
            </Typography>

            <Typography sx={{ fontFamily: isDesktop ? FontFamily.PLUSJAKARTASANS : FontFamily.PLUSJAKARTASANSLIGHT, fontSize: isDesktop ? 21 : 12, color: theme.palette.text.primary }}>
                {`Please click the button below to add new email.`}
            </Typography>
        </DivFlexCenter>
    )
}

const EmailsListDialog = (props) => {

    const { outletId, userRole, customerName, currentEmail, emailList, setEmailList, handleCloseDialog, handleOpenDialog, handleSetCurrentEmailForDelete, handleSetCurrentEmailForEdit, isDesktop } = useContext(AppContext)

    const { isOpen } = props;

    const { t } = useTranslation()

    const theme = useTheme()

    const urlParam = useParams()

    useEffect(() => {
        let dataToSend = {
            userRole
        }

        if (isOpen) {
            if (outletId) {
                CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID(
                    dataToSend,
                    outletId,
                    res => {
                        if (res.data) {
                            let newEmailList = []
                            res.data.forEach((userEmail, index) => {
                                if (userEmail.isDeletedAt === null || userEmail.isDeletedAt === undefined) {
                                    newEmailList.push({
                                        id: userEmail.id,
                                        email: userEmail.email,
                                        role: userEmail.role
                                    })
                                }
                            })
                            if (currentEmail.role !== RoleEnum.SUPER_ADMIN) {
                                newEmailList = newEmailList.filter(data => data.role !== RoleEnum.SUPER_ADMIN);
                            }
                            setEmailList(newEmailList)
                        }
                    },
                    err => {
                        console.error(err)
                    }
                )
            } else {
                CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES(
                    dataToSend,
                    "",
                    res => {
                        if (res.data) {
                            let newEmailList = []
                            res.data.forEach((userEmail, index) => {
                                if (userEmail.isDeletedAt === null || userEmail.isDeletedAt === undefined) {
                                    newEmailList.push({
                                        id: userEmail.id,
                                        email: userEmail.email,
                                        role: userEmail.role
                                    })
                                }
                            })
                            if (currentEmail.role !== RoleEnum.SUPER_ADMIN) {
                                newEmailList = newEmailList.filter(data => data.role !== RoleEnum.SUPER_ADMIN);
                            }
                            setEmailList(newEmailList)
                        }
                    },
                    err => {
                        console.error(err)
                    }
                )
            }
        }
    }, [isOpen])

    // email list component based on device type
    let emailListDeviceVersion;

    if (!isDesktop) {
        emailListDeviceVersion =
            <>
                <DivFlexCenter>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 22, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, textAlign: 'center' }}>
                        {`${customerName} E-Mail-Liste`}
                    </Typography>
                </DivFlexCenter>


                {emailList?.length > 0 ? (
                    <>
                        <DivFlexStart sx={{ mt: 2, mb: 0.5, gap: 1 }}>
                            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 12 }}> {`${t("email")}/${t("roles")}`} </Typography>
                            <HintIcon
                                htmlColor={theme.palette.background.iconColor}
                                onClick={() => handleOpenDialog('rolesAccess')}
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    mt: '-4px'
                                }}
                            />
                        </DivFlexStart>
                        <TableDataContainer>
                            {emailList.map((list, index) => (
                                <DivFlexSpaceBetween
                                    key={list.id}
                                    sx={{
                                        mt: 1.5,
                                        borderBottom: (index === emailList.length - 1) ? 'none' : `1px solid ${theme.palette.background.separatorTitle}`,
                                        pb: 1
                                    }}
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                        <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontSize: 12, color: theme.palette.text.primary }}>{list.email}</Typography>
                                        <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANS, fontSize: 12, color: theme.palette.text.primary }}>{getTextRoles(list.role, t)}</Typography>
                                    </Box>
                                    {list.email !== currentEmail.email && (
                                        <DivFlexCenter >
                                            <EditIcon color={theme.palette.background.iconAction} sx={{ fontSize: 24, mr: 3 }} onClick={() => handleSetCurrentEmailForEdit(list)} />
                                            <DeleteIcon color={theme.palette.background.iconAction} sx={{ fontSize: 24 }} onClick={() => handleSetCurrentEmailForDelete(list)} />
                                        </DivFlexCenter>
                                    )}
                                </DivFlexSpaceBetween>
                            ))
                            }
                        </TableDataContainer>
                    </>
                ) : (
                    <NoEmailAdded marginTop={5} marginBottom={10} theme={theme} />
                )}

                {/* <Typography
                    sx={{ textDecoration: 'underline', mt: 2, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', fontSize: 12, color: theme.palette.text.primary }}
                    onClick={() => handleOpenDialog('addNewEmail')}
                >
                    + Add New Email
                </Typography> */}

                <Button style={{ mt: 7, mb: 1.75, py: '12px', height: '' }} onClick={() => handleOpenDialog('addNewEmail')}>
                    <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 14, color: theme.palette.background.buttonColor, textAlign: 'center' }}>
                        {t("addNewEmail")}
                    </Typography>
                </Button>
            </>
    } else {
        emailListDeviceVersion =
            <>
                <DivFlexCenter sx={{ mb: 9.75 }}>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 36, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                        {`${customerName} E-Mail-Liste`}
                    </Typography>
                </DivFlexCenter>

                {emailList?.length > 0 ? (
                    <TableContainer sx={{ maxHeight: 250 }}>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableHeadCustom theme={theme}>{`Email`}</ TableHeadCustom>
                                    <TableHeadCustom theme={theme}>
                                        <RolesTableHead theme={theme} />
                                    </TableHeadCustom>
                                    <TableHeadCustom theme={theme}></TableHeadCustom>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emailList.map((list, index) => (
                                    <TableRow key={list.id}>
                                        <TableCellCustom isLastItem={index === emailList.length - 1} component="th" scope="row" theme={theme}>
                                            {list.email}
                                        </TableCellCustom>
                                        <TableCellCustom isLastItem={index === emailList.length - 1}> {getTextRoles(list.role, t)}</TableCellCustom>
                                        <TableCellCustom isLastItem={index === emailList.length - 1} sx={{ width: 90 }}>
                                            {list.email !== currentEmail.email && (
                                                <DivFlexSpaceBetween sx={{ mr: -2, ml: 1 }}>
                                                    <EditIcon sx={{ cursor: 'pointer', fontSize: 24 }} color={theme.palette.background.iconAction} onClick={() => handleSetCurrentEmailForEdit(list)} />
                                                    <DeleteIcon sx={{ cursor: 'pointer', fontSize: 24 }} color={theme.palette.background.iconAction} onClick={() => handleSetCurrentEmailForDelete(list)} />
                                                </DivFlexSpaceBetween>
                                            )}
                                        </TableCellCustom>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <NoEmailAdded marginTop={12} marginBottom={13.375} theme={theme} />
                )}

                {/* <Typography sx={{ textDecoration: 'underline', mt: 12, fontFamily: FontFamily.PLUSJAKARTASANSBOLD, cursor: 'pointer', fontSize: 20, color: theme.palette.text.primary }} onClick={() => handleOpenDialog('addNewEmail')} >
                    + Add New Email
                </Typography> */}
                <Button style={{ mt: 8, py: '16px', height: '' }} onClick={() => handleOpenDialog('addNewEmail')}>
                    <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 24, color: theme.palette.background.buttonColor }}>
                        {t("addNewEmail")}
                    </Typography>
                </Button>
            </>
    }


    return (
        <>
            <CustomDialog width={857} open={isOpen} onClose={handleCloseDialog} backgroundColor={theme.palette.background.dialog} paddingRight={isDesktop && 6.75}>
                <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                    <Box sx={{ padding: !isDesktop ? '12px 16px 24px 16px' : '4px 54px 80px 54px' }}>
                        {emailListDeviceVersion}
                    </Box>
                </Box>
            </CustomDialog>

            <Outlet />
        </>

    )
}


export default EmailsListDialog