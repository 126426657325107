import { styled } from '@mui/system';

import { Dialog } from '@mui/material'


const DialogBlanket = styled(Dialog)(({ theme, width, height, isLoading, loadingBackgroundColor }) => ({
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: width || "100%",
            maxWidth: width,
            borderRadius: 0,
            background: isLoading ? 'transparent' : theme.palette.background.dialog,
            boxShadow: 'none',
            height: height || '',
        },
        backdropFilter: "blur(20px)",
        background: loadingBackgroundColor || theme.palette.background.dialogBlanket
    },
}));



export default DialogBlanket