import React from "react"

import { SvgIcon } from "@mui/material"

export const LogoutIcon = props => {

    return (
        <SvgIcon {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H13C13.7957 21 14.5587 20.6839 15.1213 20.1213C15.6839 19.5587 16 18.7957 16 18V16C16 15.4477 15.5523 15 15 15C14.4477 15 14 15.4477 14 16V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H13C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6V8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8V6C16 5.20435 15.6839 4.44129 15.1213 3.87868C14.5587 3.31607 13.7956 3 13 3H6ZM19.7071 8.29289C19.3166 7.90237 18.6834 7.90237 18.2929 8.29289C17.9024 8.68342 17.9024 9.31658 18.2929 9.70711L19.5858 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H19.5858L18.2929 14.2929C17.9024 14.6834 17.9024 15.3166 18.2929 15.7071C18.6834 16.0976 19.3166 16.0976 19.7071 15.7071L22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L19.7071 8.29289Z" />
        </SvgIcon>
    )
}

export default LogoutIcon


