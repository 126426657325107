export const FontFamily = {
    EINA04REGULAR: 'Eina04-Regular',
    EINA04LIGHT: 'Eina04-Light',
    EINA04LIGHTITALIC: 'Eina04-LightItalic',
    EINA04REGULARITALIC: 'Eina04-RegularItalic',
    EINA04SEMIBOLD: 'Eina04-SemiBold',
    EINA04BOLD: 'Eina04-Bold',
    ROBOTOREGULAR: 'Roboto-Regular',
    PLUSJAKARTASANS: 'Plus-Jakarta-Sans',
    PLUSJAKARTASANSLIGHT: 'Plus-Jakarta-Sans-Light',
    PLUSJAKARTASANSLIGHTITALIC: 'Plus-Jakarta-Sans-LightItalic',
    PLUSJAKARTASANSREGULARITALIC: 'Plus-Jakarta-Sans-RegularItalic',
    PLUSJAKARTASANSSEMIBOLD: 'Plus-Jakarta-Sans-SemiBold',
    PLUSJAKARTASANSBOLD: 'Plus-Jakarta-Sans-Bold',
}