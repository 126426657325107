import React from "react"

import { SvgIcon } from "@mui/material"

export const ErrorIcon = props => {
    return (
        <SvgIcon {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M9.17317 2.7612C10.3864 2.25866 11.6868 2 13 2C14.3132 2 15.6136 2.25866 16.8268 2.7612C18.0401 3.26375 19.1425 4.00035 20.0711 4.92893C20.9997 5.85752 21.7362 6.95991 22.2388 8.17317C22.7413 9.38642 23 10.6868 23 12C23 13.3132 22.7413 14.6136 22.2388 15.8268C21.7362 17.0401 20.9997 18.1425 20.0711 19.0711C19.1425 19.9997 18.0401 20.7362 16.8268 21.2388C15.6136 21.7413 14.3132 22 13 22C11.6868 22 10.3864 21.7413 9.17317 21.2388C7.95991 20.7362 6.85752 19.9997 5.92893 19.0711C5.00035 18.1425 4.26375 17.0401 3.7612 15.8268C3.25866 14.6136 3 13.3132 3 12C3 10.6868 3.25866 9.38642 3.7612 8.17317C4.26375 6.95991 5.00035 5.85752 5.92893 4.92893C6.85752 4.00035 7.95991 3.26375 9.17317 2.7612ZM13 4C11.9494 4 10.9091 4.20693 9.93853 4.60896C8.96793 5.011 8.08601 5.60028 7.34315 6.34315C6.60028 7.08601 6.011 7.96793 5.60896 8.93853C5.20693 9.90914 5 10.9494 5 12C5 13.0506 5.20693 14.0909 5.60896 15.0615C6.011 16.0321 6.60028 16.914 7.34315 17.6569C8.08601 18.3997 8.96793 18.989 9.93853 19.391C10.9091 19.7931 11.9494 20 13 20C14.0506 20 15.0909 19.7931 16.0615 19.391C17.0321 18.989 17.914 18.3997 18.6569 17.6569C19.3997 16.914 19.989 16.0321 20.391 15.0615C20.7931 14.0909 21 13.0506 21 12C21 10.9494 20.7931 9.90914 20.391 8.93853C19.989 7.96793 19.3997 7.08602 18.6569 6.34315C17.914 5.60028 17.0321 5.011 16.0615 4.60896C15.0909 4.20693 14.0506 4 13 4ZM13 7C13.5523 7 14 7.44772 14 8V12C14 12.5523 13.5523 13 13 13C12.4477 13 12 12.5523 12 12V8C12 7.44772 12.4477 7 13 7ZM13 15C12.4477 15 12 15.4477 12 16C12 16.5523 12.4477 17 13 17H13.01C13.5623 17 14.01 16.5523 14.01 16C14.01 15.4477 13.5623 15 13.01 15H13Z" />
        </SvgIcon>
    )

}







export default ErrorIcon