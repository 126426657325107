import React, { useContext, useState, useEffect } from "react";

// import material UI
import { Typography } from "@mui/material";

// import icon
import ErrorIcon from "../assets/icons/ErrorIcon";

import { useTranslation } from "react-i18next";

// import component
import DivFlexStart from "./DivFlexStart";

// import Constants
import { FontFamily } from "../Constants/FontFamily";

const ErrorMessage = (props) => {
    const { t } = useTranslation()

    const { isEmailEmpty, isEmailInvalid, isEmailExist, isError, isDesktop, isMobile } = props;

    const showErrorMessage = () => {
        if (isEmailEmpty) {
            return t("emailIsEmpty")
        } else if (isEmailInvalid) {
            return t("emailIsInvalid")
        } else if (isEmailExist) {
            return t("emailIsExist")
        } else if (isError) {
            return t("error")
        }
    }

    return (
        <DivFlexStart sx={{ width: isMobile ? '100%' : '60%', mt: 1 }}>
            <DivFlexStart>
                <ErrorIcon sx={{ color: '#FA4D56', fontSize: isMobile ? 12 : 24, mr: 1, mt: -0.4 }} />
                <Typography sx={{ fontSize: isMobile ? 12 : 20, fontFamily: FontFamily.PLUSJAKARTASANS }} color={'#DA1E28'}>
                    {showErrorMessage()}
                </Typography>
            </DivFlexStart>
        </DivFlexStart>
    )
}

export default ErrorMessage;