import React, { useContext, useEffect, useState } from "react";

// import material UI
import {
  Typography,
  FormControl,
  Box,
} from "@mui/material";

// import i18n
import { useTranslation } from "react-i18next";

// import icon
import ErrorIcon from "../../assets/icons/ErrorIcon";

// import react router dom
import { Outlet } from "react-router-dom";

// import context
import { AppContext } from "../../App";

// import component
import DivFlexCenter from "../DivFlexCenter";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import TextFieldStyled from "../TextField/TextFieldStyle";
import Button from "../Button";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

// import style and theme
import { useTheme } from "@mui/material/styles";
import DivFlexStart from "../DivFlexStart";
import { CORE_ACTION_POST_SIGN_IN } from "../../Actions/CoreActions";

const LoginDialog = (props) => {
  const {
    isMobile,
    handleCloseDialog,
    handleButtonOtpDialog,
    // sendOtp,
    currentEmail,
    setCurrentEmail,
    setLoggedinEmail
  } = useContext(AppContext);

  const { isOpen } = props;

  const theme = useTheme();

  const { t } = useTranslation()

  // email validation

  const [email, setEmail] = useState("");
  const [emailSubmit, setEmailSubmit] = useState("");

  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isSubmitEmailLoading, setIsSubmitEmailLoading] = useState(false)

  useEffect(() => {
    setEmail("")
    setIsEmailEmpty(false)
    setIsEmailInvalid(false)
    setIsError(false)
    setIsSubmitEmailLoading(false)
  }, [isOpen])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChangeInput = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailSubmit(value);

    const newCurrentEmail = { ...currentEmail }
    newCurrentEmail.email = value
    setCurrentEmail(newCurrentEmail)
  };

  const handleCloseLoginDialog = () => {
    handleCloseDialog();
    setEmail("");
    setIsEmailEmpty(false);
    setIsEmailInvalid(false);
  };

  const handlePressSendOtp = () => {
    if (email !== "") {
      setIsSubmitEmailLoading(true)
      setIsEmailInvalid(false)
      setIsEmailEmpty(false)
      setIsError(false)
      if (isValidEmail(emailSubmit)) {
        CORE_ACTION_POST_SIGN_IN(
          email,
          res => {
            if (res.status === 200 && res.data) {
              if (res.data.message !== null && res.data.message === 'OK' && res.data.email === email) {
                setLoggedinEmail(email)
                handleCloseDialog();
                handleButtonOtpDialog();
                setIsEmailInvalid(false);
              }
              else if (res.data.message !== null && res.data.message === 'BAD_REQUEST') {
                setIsError(true);
              }
              else if (res.data.message != null && res.data.message === 'EMAIL_NOT_FOUND') {
                setIsEmailInvalid(true);
              }
            }
            setIsSubmitEmailLoading(false)
          },
          err => {
            setIsError(true);
            setIsSubmitEmailLoading(false)
          }
        )
      } else {
        setIsEmailInvalid(true);
        setIsSubmitEmailLoading(false)
      }
    }
    if (email === "") {
      setIsEmailEmpty(true);
      setIsSubmitEmailLoading(false)
    }
  };

  return (
    <>
      <CustomDialog
        open={isOpen}
        onClose={handleCloseLoginDialog}
        theme={theme}
        maxWidth={'sm'}
      >
        <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
          <CustomDialogContent
            isMobile={isMobile}
            sx={{
              backgroundColor: theme.palette.background.dialog,
              padding: isMobile ? '0px 16px 24px 16px' : '0px 64px 64px 64px'
            }}
            theme={theme}
          >
            <DivFlexCenter
              sx={{ mb: isMobile ? '38px' : '52px' }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: isMobile ? 22 : 48,
                  fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                  lineHeight: isMobile ? 'normal' : '40px'
                }}
              >
                {t("signIn")}
              </Typography>
            </DivFlexCenter>
            <Typography
              sx={{
                fontSize: isMobile ? 14 : 24,
                fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                color: theme.palette.text.primary,
                marginBottom: isMobile ? '8px' : '12px'
              }}
            >
              {`${t("email")} *`}
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <TextFieldStyled
                id="email"
                placeholder="example@email.com"
                value={email}
                onChange={handleChangeInput}
                isMobile={isMobile}
                height='unset'
                fontSize={isMobile ? 16 : 24}
                padding={isMobile ? '16px' : '28px 24px'}
                warning={isEmailEmpty || isEmailInvalid || isError}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handlePressSendOtp();
                  }
                }}
              />
            </FormControl>


            <DivFlexStart sx={{ mt: isMobile ? '8.5px' : '12px' }}>
              {/* <Box sx={{ width: 'isMobile ? "" : 55' }} /> */}

              {(isEmailEmpty || isEmailInvalid || isError) && (
                <DivFlexStart>
                  <ErrorIcon
                    sx={{
                      color: "#FA4D56",
                      fontSize: isMobile ? 20 : 24,
                      mr: isMobile ? 0.5 : 1,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: isMobile ? 14 : 24,
                      fontFamily: FontFamily.PLUSJAKARTASANS,
                      paddingTop: 0.5
                    }}
                    color={"#da1e28"}
                  >
                    {isEmailEmpty ? t("emailIsEmpty") : (isEmailInvalid ? t('emailIsInvalid') : t('emailIsError'))}
                  </Typography>
                </DivFlexStart>
              )}
            </DivFlexStart>

            <Button
              isLoading={isSubmitEmailLoading}
              onClick={handlePressSendOtp}
              style={{ marginTop: isMobile ? '50px' : '32px', width: "100%" }}
              ref={(node) => (this.btn = node)}
            >
              {/* {`Send OTP`} */}
              <Typography
                color={theme.palette.text.primaryButtonText}
                sx={{
                  fontSize: isMobile ? 16 : 24,
                  fontFamily: FontFamily.PLUSJAKARTASANS,
                  fontWeight: 600
                }}
              >
                {t("submit")}
              </Typography>
            </Button>
          </CustomDialogContent>
        </Box>
      </CustomDialog>
      {/* <Snackbar
        open={sendOtp}
        message={
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: isMobile ? 12 : 14,
              fontFamily: FontFamily.PLUSJAKARTASANS,
            }}
          >
            OTP code has been sent to{" "}
            <span style={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD}}>
              {emailSubmit}{" "}
            </span>
          </Typography>
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: isMobile ? 8 : 10, zIndex: 3000 }}
        ContentProps={{
          sx: {
            background: theme.palette.background.dialog,
            width: isMobile ? "100%" : 600,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 72,
            borderRadius: 0,
          },
        }}
      /> */}
      <Outlet />
    </>
  );
};

export default LoginDialog;
