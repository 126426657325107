
/**Animation for sun and moon */
import sunRiseDark from "../../assets/lotties/SUNRISE_DARKMODE.json"
import sunRiseLight from "../../assets/lotties/SUNRISE_LIGHTMODE.json";
import sunLight from "../../assets/lotties/SUN_LIGHTMODE.json";
import sunDark from "../../assets/lotties/SUN_DARKMODE.json";
import moonLight from "../../assets/lotties/MOON_LIGHTMODE.json";
import moonDark from "../../assets/lotties/MOON_DARKMODE.json";


/**Animation for delivery simlation */
import lightModeAnimationFixed from "../../assets/animations/Light Mode_Expiry Page.json";
import darkModeAnimationFixed from "../../assets/animations/Dark Mode_Expiry Page.json";
import blueModeAnimationFixed from "../../assets/animations/Blue Mode_Expiry Page.json";
import yellowModeAnimationFixed from "../../assets/animations/Yellow Mode_Expiry Page.json";
import darkModeAnimationTransparent from "../../assets/animations/Dark Mode_Delivery Animation_Transparent.json";
import lightModeAnimationTransparent from "../../assets/animations/Light Mode_Delivery Animation_Transparent.json";

import PageNotFoundAnimationDark from "../../assets/animations/Animated 404_Dark.json";
import PageNotFoundAnimationLight from "../../assets/animations/Animated 404-Light.json";

export const PageNotFoundDark = {
    loop: true,
    autoplay: true,
    animationData: PageNotFoundAnimationDark,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const PageNotFoundLight = {
    loop: true,
    autoplay: true,
    animationData: PageNotFoundAnimationLight,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const sunriseLightAnimation = {
    loop: true,
    autoplay: true,
    animationData: sunRiseLight,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const sunriseDarkAnimation = {
    loop: true,
    autoplay: true,
    animationData: sunRiseDark,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const sunLightAnimation = {
    loop: true,
    autoplay: true,
    animationData: sunLight,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const sunDarkAnimation = {
    loop: true,
    autoplay: true,
    animationData: sunDark,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const moonLightAnimation = {
    loop: true,
    autoplay: true,
    animationData: moonLight,
    renderSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const moonDarkAnimation = {
    loop: true,
    autoplay: true,
    animationData: moonDark,
    renderSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};


export const deliverySimlationLightAnimation = {
    loop: true,
    autoplay: true,
    animationData: lightModeAnimationFixed,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const deliverySimlationBlueAnimation = {
    loop: true,
    autoplay: true,
    animationData: blueModeAnimationFixed,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    }
}

export const deliverySimlationYellowAnimation = {
    loop: true,
    autoplay: true,
    animationData: yellowModeAnimationFixed,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    }
}

export const deliverySimlationDarkAnimation = {
    loop: true,
    autoplay: true,
    animationData: darkModeAnimationFixed,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const deliveryAnimationTransparentDarkMode = {
    loop: true,
    autoplay: true,
    animationData: darkModeAnimationTransparent,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    }
}

export const deliveryAnimationTransparentLightMode = {
    loop: true,
    autoplay: true,
    animationData: lightModeAnimationTransparent,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    }
}