import React, { useContext } from "react";

// import material UI
import { Typography } from "@mui/material";

// import react router dom
import { Outlet } from "react-router-dom";

// import context
import { AppContext } from "../../App";

// import component
import DivFlexCenter from "../DivFlexCenter";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import Button from "../Button";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

// import style and theme
import { useTheme } from "@mui/material/styles";

// import i18n
import { useTranslation } from "react-i18next";

const DeleteEmailDialog = (props) => {

    const { selectedEmail, isMobile, setOpenDialog, loggedinEmail } = useContext(AppContext)

    const { handleDeleteSelfEmailAndLogout, handleDeleteEmailTempState, isOpen } = props;

    const { t } = useTranslation()

    const theme = useTheme()

    const handleCloseDialogDelete = () => {
        // handleOpenDialog('emailList')
        setOpenDialog("")
    }

    const handleDelete = (selectedEmail) => {
        if (loggedinEmail === selectedEmail?.email) {
            handleDeleteSelfEmailAndLogout(selectedEmail)
        } else {
            handleDeleteEmailTempState(selectedEmail)
        }
    }

    return (
        <>
            <CustomDialog width={700} open={isOpen} onClose={handleCloseDialogDelete} theme={theme}>
                <CustomDialogContent isMobile={isMobile}>
                    <DivFlexCenter sx={{ height: isMobile ? 20 : 40, mb: isMobile ? 5 : 8 }}>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: isMobile ? 22 : 48, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                            {t("deleteEmail")}
                        </Typography>
                    </DivFlexCenter>
                    <Typography sx={{ fontSize: isMobile ? 12 : 21, fontFamily: FontFamily.PLUSJAKARTASANS, color: theme.palette.text.primary, textAlign: isMobile ? 'center' : 'left' }}>
                        {loggedinEmail === selectedEmail?.email ? (
                            <>
                                Sind Sie sicher, dass Sie Ihre eigene E-Mail löschen wollen? Sie werden dann automatisch abgemeldet und verlieren den Zugang zum ETA-Portal.
                            </>
                        ) : (
                            <>
                                {t("deleteConfirmation")}&nbsp;<span style={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, color: theme.palette.text.primary }}>{`${selectedEmail?.email}`}</span>{t("wantToDelete")}
                            </>
                        )}
                    </Typography>

                    <DivFlexSpaceBetween sx={{ mt: isMobile ? 10.5 : 10, flexDirection: isMobile ? 'column-reverse' : 'row', gap: isMobile ? 1.5 : 0 }}>
                        <Typography sx={{ textDecoration: 'underline', fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', fontSize: isMobile ? 14 : 21, color: theme.palette.text.primary }} onClick={handleCloseDialogDelete} >
                            {t("cancel")}
                        </Typography>
                        <Button style={{ width: isMobile ? '100%' : '35%', py: isMobile ? '12px' : '16px', height: '' }} onClick={() => handleDelete(selectedEmail)} >
                            <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 24 }}>
                                {t("delete")}
                            </Typography>
                        </Button>
                    </DivFlexSpaceBetween>
                </CustomDialogContent>
            </CustomDialog>

            <Outlet />
        </>

    )
}


export default DeleteEmailDialog