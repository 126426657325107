import React, { useCallback, useEffect, useState } from "react";

// MUI
import { useTheme } from "@mui/material/styles";

// Components
import DivFlexCenter from "../DivFlexCenter";

export const useBulletButton = (emblaApi) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])

    const onBulletButtonClick = useCallback((e, index) => {
        e.stopPropagation()
        if (!emblaApi) return
        emblaApi.scrollTo(index)
    }, [emblaApi])

    const onInit = useCallback((emblaApi) => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback((emblaApi) => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)
        emblaApi.on('reInit', onInit)
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
    }, [emblaApi, onInit, onSelect])

    return {
        selectedIndex,
        scrollSnaps,
        onBulletButtonClick
    }
}

export const BulletWrapper = (props) => {
    const { children } = props

    const theme = useTheme()

    return (
        <DivFlexCenter
            sx={{
                width: 'fit-content',
                padding: '8px 16px',
                backgroundColor: theme.palette.background.carouselBulletBackground,
                borderRadius: '24px',
                gap: '10px',
                justifySelf: 'center',
                position: 'absolute',
                bottom: 0,
                marginBottom: '8px'
            }}
        >
            {children}
        </DivFlexCenter>
    )
}

export const BulletButton = (props) => {
    const { isSelected, ...restProps } = props

    const theme = useTheme()

    return (
        <div
            {...restProps}
            style={{
                height: '8px',
                width: isSelected ? '24px' : '8px',
                backgroundColor: isSelected ?
                    theme.palette.background.carouselBulletSelected :
                    theme.palette.background.carouselBullet,
                borderRadius: isSelected ? '12px' : '50%',
                cursor: 'pointer'
            }}
        />
    )
}