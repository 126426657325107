import { blueTheme, darkTheme, lightTheme, yellowTheme, greenTheme, orangeTheme } from "../Components/Theme/Theme"
import { ColorTheme } from "../Constants/ColorTheme"
import { RoleEnum } from "../Constants/RoleEnum"

export const getAppTheme = mode => {
    switch (mode) {
        case ColorTheme.DARK:
            return darkTheme
        case ColorTheme.LIGHT:
            return lightTheme
        case ColorTheme.YELLOW:
            return yellowTheme
        case ColorTheme.BLUE:
            return blueTheme
        case ColorTheme.GREEN:
            return greenTheme
        case ColorTheme.ORANGE:
            return orangeTheme
        default:
            return lightTheme
    }
}

export const convertRole = role => {
    switch (role) {
        case RoleEnum.SUPER_ADMIN:
            return "superAdmin"
        case RoleEnum.ADMIN:
            return "admin"
        case RoleEnum.STANDARD:
            return "standard"
        default:
            return "superAdmin"
    }
}

export const getTextRoles = (roles, t) => {
    switch (roles) {
        case RoleEnum.ADMIN:
            return t("admin")
        case RoleEnum.SUPER_ADMIN:
            return t("superAdmin")
        default:
            return t("standard")
    }
}

export const normalizeEmail = (email) => {
    return email.replace(/\s/g, '').toLowerCase();
}

export const isPWAiOS = () => {
    // Check if it's iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Check if it's in standalone mode (PWA)
    const isStandalone = window.navigator.standalone === true;

    // Combine checks
    return isIOS && isStandalone;
}

export const areAllValuesFalse = (obj) => {
    return Object.values(obj).every(value => value === false);
}

// export const setFavicon = favicon => {
//     let link = document.querySelector("link[rel~='icon']");
//     if (!link) {
//         link = document.createElement('link');
//         link.rel = 'icon';
//         document.getElementsByTagName('head')[0].appendChild(link);
//     }

//     link.href = favicon;
// }

// export const handleGetTheme = () => {
//     if (localStorage.getItem("mode")) {
//         return localStorage.getItem("mode")
//     }
// }