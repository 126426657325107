import React from "react"

import { SvgIcon } from "@mui/material"

export const CloseIcon = props => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M20.1653 5.95098C20.6116 5.50467 20.6116 4.78105 20.1653 4.33474C19.719 3.88842 18.9953 3.88842 18.549 4.33474L12.5 10.3838L6.45098 4.33474C6.00467 3.88842 5.28105 3.88842 4.83474 4.33474C4.38842 4.78105 4.38842 5.50467 4.83474 5.95098L10.8838 12L4.83474 18.049C4.38842 18.4953 4.38842 19.219 4.83474 19.6653C5.28105 20.1116 6.00467 20.1116 6.45098 19.6653L12.5 13.6162L18.549 19.6653C18.9953 20.1116 19.719 20.1116 20.1653 19.6653C20.6116 19.219 20.6116 18.4953 20.1653 18.049L14.1162 12L20.1653 5.95098Z" />
        </SvgIcon>
    )

}





export default CloseIcon