import React, { useContext } from "react";

import { Typography, MenuItem, Select } from "@mui/material";

import { AppContext } from "../../../App";

import { FontFamily } from "../../../Constants/FontFamily";

// import style and theme
import { useTheme } from "@mui/material/styles";

import ChevronDown from "../../../assets/icons/ChevronDown";

const SelectMenuItem = (props) => {

    const { id, name, handleChangeInput, defaultValue, options, fontSize, value } = props


    const theme = useTheme()

    const { isMobile } = useContext(AppContext)

    return (
        <Select
            id={id}
            defaultValue={defaultValue}
            value={value}
            name={name}
            onChange={handleChangeInput}
            inputProps={{
                MenuProps: {
                    MenuListProps: {
                        sx: {
                            backgroundColor: theme.palette.background.dialog,
                            pt: 0, pb: 0,
                            "&& .Mui-selected": {
                                backgroundColor: theme.palette.background.hoverDeliveryCard,
                                "&:hover": {
                                    backgroundColor: theme.palette.background.hoverDeliveryCard
                                }
                            },
                        }
                    }
                }
            }}
            sx={{
                // height: isMobile ? 40 : 53,
                width: '100%',
                borderRadius: 0,
                alignItems: 'center',
                // paddingTop: isMobile ? 0.8 : 0.4,
                "& .MuiSvgIcon-root": {
                    color: theme.palette.text.primary,
                },
                ".MuiOutlinedInput-notchedOutline":
                {
                    border: `2px solid ${theme.palette.background.borderForm}`,
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                    border: `2px solid  ${theme.palette.background.borderFormHover}`,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                    border: `2px solid ${theme.palette.background.borderFormActive}`,
                },
                "& .MuiInputBase-input":
                {
                    padding: isMobile ? '16px' : '28px 24px'
                }
            }}
            IconComponent={(props) => (
                <ChevronDown
                    style={{
                        fontSize: isMobile ? 20 : 24,
                        right: isMobile ? '16px' : '24px',
                        color: theme.palette.background.iconColor
                    }}
                    {...props}
                />
            )}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}
                    sx={{
                        background: theme.palette.background.dialog,
                        "&:hover": {
                            backgroundColor: theme.palette.background.hoverDeliveryCard
                        }
                    }}>
                    <Typography
                        sx={{
                            fontSize: fontSize ?
                                fontSize :
                                isMobile ? 12 : 20,
                            fontFamily: FontFamily.PLUSJAKARTASANS
                        }}
                    >
                        {option.label}
                    </Typography>
                </MenuItem>
            ))}

        </Select>
    )
}

export default SelectMenuItem