import { useContext } from "react";

import { useTheme } from "@emotion/react";

import { Box, Collapse, Typography } from "@mui/material";

import { styled } from "@mui/system";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

// import reusable component
import DivFlexStart from "../../Components/DivFlexStart";
import DivFlexSpaceBetween from "../../Components/DivFlexSpacebetween";
import DivFlexCenter from "../../Components/DivFlexCenter";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";

// import icons
import ChevronUp from "../../assets/icons/ChevronUp";
import DropdownSelect from "../../Components/DropdownSelect";

// delivery title div as button
export const DeliverStickyTitle = styled("div")((props) => ({
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "space-between",
    top: 62,
    width: "100%",
    backgroundColor: props.theme.palette.background.default,
    zIndex: 1000,
    padding: "16px 24px",

}));

// promo title div as button
export const PromoStickyTitle = styled("div")((props) => ({
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "space-between",
    top: "calc(62px + 53px)",
    width: "100%",
    backgroundColor: props.theme.palette.background.default,
    borderTop: `1px solid ${props.theme.palette.background.separatorTitle}`,
    zIndex: 1000,
    padding: "16px 24px",
}));



export const AnimationContainer = (props) => {

    const { isDesktop } = useContext(AppContext)

    return (
        <DivFlexStart
            sx={{
                padding: isDesktop ? "0px" : "0px 24px",
                mt: 3,
                height: "32px",
            }}
        >
            {props.children}
        </DivFlexStart>
    )

}


export const Greeting = (props) => {

    const theme = useTheme()

    const { isDesktop } = useContext(AppContext)

    return (
        <DivFlexStart
            sx={{
                padding: isDesktop ? "" : "16px 24px 0px 24px",
                pt: isDesktop ? 4.5 : 3,
            }}
        >
            <Typography
                fontSize={"32px"}
                color={theme.palette.text.primary}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANS,
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "125%",
                    letterSpacing: 0

                }}
            >
                {props.children}
            </Typography>

        </DivFlexStart>
    )

}

export const DeliveryInformation = (props) => {

    const { isDesktop, } = useContext(AppContext)

    const theme = useTheme()

    return (
        <DivFlexStart
            sx={{
                padding: isDesktop ? "" : "0px 24px 0px 24px",
                mt: isDesktop ? 5 : 3,
            }}
        >
            <Typography
                fontSize={32}
                color={theme.palette.text.primary}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    fontStyle: "normal",
                    lineHeight: "40px",
                    pb: 3,
                    letterSpacing: 0.2
                }}
            >
                {props.children}
            </Typography>
        </DivFlexStart>
    )

}

export const UpcomingDeliveriesGroup = (props) => {
    const {
        handleClickDataGroup,
        upcomingData,
        upcomingOpen,
        children
    } = props

    const { isDesktop } = useContext(AppContext)

    const theme = useTheme()

    const { t } = useTranslation()

    return (
        <Box>
            <DivFlexSpaceBetween
                id="upcoming-deliveries-group"
                onClick={() => handleClickDataGroup('upcoming')}
                sx={{
                    backgroundColor: theme.palette.background.drawerColor,
                    mr: isDesktop ? "30px" : 0,
                    px: "16px",
                    py: "12px",
                    cursor: 'pointer'
                }}
            >
                <Typography
                    fontSize={16}
                    color={theme.palette.text.primary}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        textTransform: "uppercase",
                        fontWeight: 600,
                        lineHeight: '22px'
                    }}
                >
                    {`${t("upcoming")} (${upcomingData.length})`}
                </Typography>

                {upcomingData.length > 0 &&
                    <ChevronUp
                        style={{
                            fontSize: 24,
                            color: theme.palette.background.dropdownChevron,
                            rotate: upcomingOpen && '180deg',
                            transition: '0.3s all ease-in-out'
                        }}
                    />
                }
            </DivFlexSpaceBetween>

            {upcomingData.length === 0 &&
                <NoDeliveryText />
            }

            <Collapse in={upcomingOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Box>
    )
}

export const PastDeliveriesGroup = (props) => {
    const {
        handleClickDataGroup,
        upcomingOpen,
        upcomingData,
        pastData,
        pastOpen,
        children
    } = props

    const { isDesktop } = useContext(AppContext)

    const theme = useTheme()

    const { t } = useTranslation()

    const getContainerMarginTop = () => {
        if (isDesktop) {
            if (upcomingOpen) {
                return "48px"
            }
            return "16px"
        }
        else {
            if (upcomingOpen && upcomingData.length > 0) {
                return "32px"
            }
            else if (upcomingOpen && upcomingData.length === 0) {
                return "24px"
            }
            return "8px"
        }
    }

    return (
        <Box>
            <DivFlexSpaceBetween
                onClick={() => handleClickDataGroup('past')}
                sx={{
                    backgroundColor: theme.palette.background.drawerColor,
                    mr: isDesktop ? "30px" : 0,
                    mt: getContainerMarginTop(),
                    px: "16px",
                    py: "12px",
                    cursor: 'pointer'
                }}
            >
                <Typography
                    fontSize={16}
                    color={theme.palette.text.primary}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        textTransform: "uppercase",
                        fontWeight: 600,
                        lineHeight: '22px'
                    }}
                >
                    {`${t("past")} (${pastData.length})`}
                </Typography>

                {pastData.length > 0 &&
                    <ChevronUp
                        style={{
                            fontSize: 24,
                            color: theme.palette.background.dropdownChevron,
                            rotate: pastOpen && '180deg',
                            transition: '0.3s all ease-in-out'
                        }}
                    />
                }
            </DivFlexSpaceBetween>

            {pastData.length === 0 &&
                <NoDeliveryText />
            }

            <Collapse in={pastOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Box>
    )
}

export const NoDeliveryText = () => {
    const { isDesktop } = useContext(AppContext)

    const theme = useTheme()

    const { t } = useTranslation()

    return (
        <DivFlexCenter
            sx={{
                mr: isDesktop ? "30px" : 0,
                pt: isDesktop ? "16px" : "24px"
            }}
        >
            <Typography
                fontSize={16}
                color={theme.palette.text.setToDefaultDisabled}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    lineHeight: "22px"
                }}
            >
                {t("noDelivery")}
            </Typography>
        </DivFlexCenter>
    )
}

export const EmptyStateDesktop = (props) => {
    const {
        getEmptyStateIllustration
    } = props

    const theme = useTheme()

    const { t } = useTranslation()

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
                px: "8%",
                pt: "25%"
            }}
        >
            <Box
                component="img"
                alt="no delivery"
                src={getEmptyStateIllustration()}
                sx={{
                    width: "461px",
                }}
            />
            <Typography
                fontSize={28}
                color={theme.palette.text.setToDefaultDisabled}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    textAlign: "center"
                }}
            >
                {t("noUpcomingAndPastDelivery")}
            </Typography>
        </Box>
    )
}

export const EmptyStateMobile = (props) => {
    const {
        getEmptyStateIllustration
    } = props

    const theme = useTheme()

    const { t } = useTranslation()

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
                mx: "24px",
            }}
        >
            <Box
                component="img"
                alt="no delivery"
                src={getEmptyStateIllustration()}
                sx={{
                    width: "100%",
                    maxWidth: "312px"
                }}
            />
            <Typography
                fontSize={16}
                color={theme.palette.text.setToDefaultDisabled}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    textAlign: "center"
                }}
            >
                {t("noUpcomingAndPastDelivery")}
            </Typography>
        </Box>
    )
}

export const OutletOption = (props) => {
    const {
        theme,
        isDesktop,
        outletOptions,
        selectedOutlet,
        setSelectedOutlet
    } = props

    const { t } = useTranslation()

    return (
        <Box sx={{ margin: isDesktop ? '0 30px 40px 0' : '0 24px 40px 24px' }}>
            <Typography
                fontSize={isDesktop ? 20 : 14}
                color={theme.palette.text.primary}
                sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANS,
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: isDesktop ? "28px" : "17px",
                    mb: isDesktop ? "12px" : "8px"
                }}
            >
                {t("1_1-1-11")}
            </Typography>

            <DropdownSelect
                value={selectedOutlet}
                handleChangeSelect={(e) => setSelectedOutlet(e.target.value)}
                options={outletOptions}
            />
        </Box>
    )
}