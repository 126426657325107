import { styled } from '@mui/material/styles';

// Create your styled components
export const SnackbarWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: 85,
  zIndex: 500,
}));

export const SnackbarContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginRight: 40,
  marginLeft: 40,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const AlertIcon = styled('div')(({ theme }) => ({
  fontSize: 14,
  marginRight: 6,
}));

export const FlexCenter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
