import React from "react"

import { SvgIcon } from "@mui/material"

export const CalendarIcon = props => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path fill={props.color} fillRule="evenodd" clipRule="evenodd" d="M14 1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1V2H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.31607 3.44129 0 4.20435 0 5V9V17C0 17.7957 0.31607 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H15C15.7957 20 16.5587 19.6839 17.1213 19.1213C17.6839 18.5587 18 17.7957 18 17V9V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H14V1ZM16 8V5C16 4.73478 15.8946 4.48043 15.7071 4.29289C15.5196 4.10536 15.2652 4 15 4H14V5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5V4H6V5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5V4H3C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5V8H16ZM2 10H16V17C16 17.2652 15.8946 17.5196 15.7071 17.7071C15.5196 17.8946 15.2652 18 15 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10Z" />
        </SvgIcon>
    )

}

export default CalendarIcon