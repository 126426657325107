class DeliveryData {
    constructor(id, tourStart, tourDate, stopStatus, stopStart, stopEnd, address, tourNumber, tourNumberGL, tripNumber, customerText, orders, tourStopNotifications, tourStopNotificationHistory, unformattedAddress, outlet) {
        this.id = id
        this.tourStart = tourStart
        this.tourDate = tourDate
        this.stopStatus = stopStatus
        this.stopStart = stopStart
        this.stopEnd = stopEnd
        this.address = address
        this.tourNumber = tourNumber
        this.tourNumberGL = tourNumberGL
        this.tripNumber = tripNumber
        this.customerText = customerText
        this.orders = orders
        this.tourStopNotifications = tourStopNotifications
        this.tourStopNotificationHistory = tourStopNotificationHistory
        this.unformattedAddress = unformattedAddress
        this.outlet = outlet
    }
}

export default DeliveryData