import React, { useContext, useState, useRef } from "react";

import { styled } from "@mui/system";

import {
  Typography,
  FormControl,
  Grid,
  CircularProgress,
  circularProgressClasses,
  Box,
} from "@mui/material";

// import react-router-dom
import { useNavigate } from "react-router-dom";

// Lottie Animation
import Lottie from "react-lottie";

// import useTheme
import { useTheme } from "@mui/material/styles";

// import appcontext
import { AppContext } from "../../App";

// import icon
import ErrorIcon from "../../assets/icons/ErrorIcon";

// import i18n
import { useTranslation } from "react-i18next";

// import delivery simlation animations
import {
  deliverySimlationLightAnimation,
  deliverySimlationDarkAnimation,
  deliverySimlationBlueAnimation,
  deliverySimlationYellowAnimation,
  deliveryAnimationTransparentDarkMode,
  deliveryAnimationTransparentLightMode
} from "../../Components/Animations/Animations";

// import component
import LinkExpiredStatus from "../LinkExpiredStatus/LinkExpiredStatus";
import DivFlexStart from "../../Components/DivFlexStart";
import TextFieldStyled from "../../Components/TextField/TextFieldStyle";
import DivFlexCenter from "../../Components/DivFlexCenter";
import Button from "../../Components/Button";
import PromoNews from "../../Components/PromoNews/PromoNews";

// import constanst
import { ColorTheme } from "../../Constants/ColorTheme";
import { UrlPage } from "../../Constants/UrlPage";
import { FontFamily } from "../../Constants/FontFamily";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";

// import illustration backgrounds
import TrackingBackgroundDark from "../../assets/images/tracking-background-dark.png";
import TrackingBackgroundLight from "../../assets/images/tracking-background-light.png";
import TrackingBackgroundBlue from "../../assets/images/tracking-background-blue.png";
import TrackingBackgroundYellow from "../../assets/images/tracking-background-yellow.png";


// warning component
const Warning = styled("div")((props) => ({
  width: props.isMobile ? "calc(100% - 11.5%)" : "calc(100% - 6.5%)",
  padding: 10,
  backgroundColor: "#DA1E28",
  marginTop: 25,
}));

// input tracking number component
const InputTrackingNumberContainer = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: props.isDesktop ? 680 : "100%",
  marginTop: props.isDesktop ? 56 : 16,
  border: `1px solid ${props.theme.palette.background.borderTrackingNumber}`,
  padding: props.isDesktop ? "48px 24px 48px 40px" : "24px 8px 24px 24px",
  flexWrap: "wrap",
  backgroundColor: props.theme.palette.background.dialog,
}));



const TrackingPage = () => {

  const {
    isLinkExpired,
    isMobile,
    promoNewsData,
    isDesktop,
    mode,
    stopNumber,
    isLogin,
    handleOpenNavigationDrawer,
    handleOpenDialog,
    shouldShowSettings
  } = useContext(AppContext);

  const theme = useTheme();

  const { t } = useTranslation()

  let navigate = useNavigate();

  const trackingNumberRef = useRef()

  /** ========= State ============ */
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  const isPromoDataAvailable = promoNewsData?.length > 0

  const goToDeliveryPage = () => {
    navigate(`/${stopNumber}`);
  };

  const onClickSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (trackingNumberRef.current.value == "123456890AB") {
        setWarning(false);
        goToDeliveryPage();
      } else {
        setWarning(true);
      }
      setIsLoading(false);
    }, 1000);
  };

  const getAnimationByColorTheme = () => {
    if (!isMobile) {
      switch (mode) {
        case ColorTheme.DARK:
          return deliverySimlationDarkAnimation
        case ColorTheme.BLUE:
          return deliverySimlationBlueAnimation
        case ColorTheme.YELLOW:
          return deliverySimlationYellowAnimation
        default:
          return deliverySimlationLightAnimation
      }
    }

    switch (mode) {
      case ColorTheme.DARK:
        return deliveryAnimationTransparentDarkMode
      default:
        return deliveryAnimationTransparentLightMode
    }
  }

  const getMobileIllustrationBackground = () => {
    switch (mode) {
      case ColorTheme.DARK:
        return TrackingBackgroundDark
      case ColorTheme.BLUE:
        return TrackingBackgroundBlue
      case ColorTheme.YELLOW:
        return TrackingBackgroundYellow
      default:
        return TrackingBackgroundLight
    }
  }

  const isShowBottomIllustration = () => {
    if (isDesktop || !isPromoDataAvailable) {
      return true
    }
    return false
  }

  return (
    <>
      <Box
        sx={{
          padding: isDesktop ? "37.5px 40px 0 40px" : 0,
          position: "sticky",
          top: 0,
          zIndex: 100
        }}>
        <TopNavbar
          shouldShowSettings={shouldShowSettings}
          theme={theme}
          isLogin={isLogin}
          handleOpenNavigationDrawer={handleOpenNavigationDrawer}
          handleOpenDialog={handleOpenDialog}
        />
      </Box>

      <Grid container sx={{ height: '100%', zIndex: 1, position: 'relative' }}>

        <Grid item xs={12} lg={isPromoDataAvailable ? 9 : 12} sx={{ pl: isDesktop ? 5 : 3, pr: 3 }}>
          {isLinkExpired ? (
            <LinkExpiredStatus />
          ) : (
            <Typography
              fontSize={isMobile ? 32 : 32}
              sx={{
                width: "100%",
                fontFamily: FontFamily.PLUSJAKARTASANS,
                mt: 15,
                color: theme.palette.text.tex4,
              }}
            >
              {t("pleaseInsertTrackingNumber")}
            </Typography>
          )}
          {!isLinkExpired && (
            <InputTrackingNumberContainer isDesktop={isDesktop} theme={theme}>
              <DivFlexStart sx={{ width: isMobile ? "100%" : "20%" }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                    mb: isDesktop ? "" : 1,
                  }}
                >
                  {t("trackingNumber")}
                </Typography>
              </DivFlexStart>
              <DivFlexStart sx={{ width: isMobile ? "60%" : "40%" }}>
                <FormControl sx={{ width: "100%" }}>
                  <TextFieldStyled
                    id="tracking-number"
                    placeholder="Up to 12 codes"
                    inputRef={trackingNumberRef}
                    warning={warning}
                    theme={theme}
                    sx={{ input: { fontSize: 16, height: isMobile ? 5 : 14 } }}
                  />
                </FormControl>
              </DivFlexStart>
              <Button
                onClick={onClickSubmit}
                style={{ width: isMobile ? "25%" : "30%" }}
                height={isMobile ? 38 : 47}
                isLoading={isLoading}
                isPromo={true}
              >
                {t("track")}
              </Button>
              <DivFlexCenter sx={{ width: isMobile ? "8%" : "5%" }}>
                {isLoading && (
                  <CircularProgress
                    size={isMobile ? "20px" : "30px"}
                    sx={{
                      color: theme.palette.background.buttonSecondary,
                      // animationDuration: "0ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                  />
                )}
              </DivFlexCenter>
              {warning && (
                <Warning isMobile={isMobile}>
                  <DivFlexCenter>
                    <ErrorIcon color={"#FF8389"} sx={{ mr: 1, fontSize: 24 }} />
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: 12,
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                      }}
                    >
                      {t("trackingAttemptFailed")}
                    </Typography>
                  </DivFlexCenter>
                </Warning>
              )}
            </InputTrackingNumberContainer>
          )}

        </Grid>

        {isPromoDataAvailable > 0 &&
          <Grid item md={12} lg={3}
            sx={{ zIndex: 10, pl: isDesktop ? 1 : "" }}
          >
            {isMobile &&
              <Typography
                fontSize={28}
                color={theme.palette.text.primary}
                sx={{
                  padding: "0 24px 16px 24px",
                  fontFamily: FontFamily.PLUSJAKARTASANS,
                  fontWeight: 600,
                  lineHeight: "36px"
                }}
              >
                {`Promo und News`}
              </Typography>
            }
            <Box
              sx={{
                maxHeight: isDesktop ? "calc(100vh - 111.5px)" : "",
                overflowY: isDesktop ? "scroll" : "",
                padding: isDesktop ? '0px 40px 0px 24px' : '0px',
                scrollbarWidth: "none", // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                  display: 'none', // Hide the scrollbar for IE
                },
              }}
            >
              <PromoNews promoData={promoNewsData} isLinkExpiredPage />
            </Box>
          </Grid>
        }

      </Grid>

      {isShowBottomIllustration() && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            bottom: 0,
            paddingBottom: 0,
            lineHeight: 0,
            overflow: "hidden"
          }}
        >
          {isMobile &&
            <Box
              component="img"
              alt="illustration background"
              src={getMobileIllustrationBackground()}
              sx={{
                width: "100vw",
                position: "absolute",
                bottom: 0
              }}
            />
          }
          <Box
            sx={{
              width: isMobile ? "120%" : "100%",
              position: "absolute",
              bottom: 0
            }}
          >
            <Lottie options={getAnimationByColorTheme()} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default TrackingPage;
