import React, { useContext, useEffect, useState } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import material UI
import { Typography, FormControl, Box } from "@mui/material";

// import react router dom
import { Outlet } from "react-router-dom";

// import context
import { AppContext } from "../../App";

// import component
import DivFlexCenter from "../DivFlexCenter";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import DivFlexStart from "../DivFlexStart";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import TextFieldStyled from "../TextField/TextFieldStyle";
import Button from "../Button";

// import style and theme
import { useTheme } from "@mui/material/styles";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";
import { RoleEnum } from "../../Constants/RoleEnum";
import ErrorMessage from "../ErrorMessage";
import { normalizeEmail } from "../../utils/util";

const AddNewEmailDialog = (props) => {

    const { selectedOutlet, isMobile, isDesktop, userRole, setRolesOption, setOpenDialog } = useContext(AppContext)
    const { isOpen, handleAddNewEmailTempState, outletList } = props;

    const { t } = useTranslation()

    const theme = useTheme()

    const initEmail = { id: null, email: '', role: RoleEnum.ADMIN }

    const [email, setEmail] = useState(initEmail)

    // validation for email
    const [isEmailEmpty, setIsEmailEmpty] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isEmailExist, setIsEmailExist] = useState(false)

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target
        setEmail({ ...email, [name]: value })
    }

    const handleSubmit = (e) => {
        let emailExist = handleCheckIfEmailExist()
        if (emailExist) {
            setIsEmailExist(true);
            return;
        }
        if (email.email === '') {
            setIsEmailEmpty(true)
        }
        if (email.email !== '') {
            setIsEmailEmpty(false)
            if (isValidEmail(email.email)) {
                e.preventDefault()
                // addNewEmail(email)
                handleAddNewEmailTempState(email)
                // handleOpenDialog('emailList');
                setOpenDialog("");
                setEmail(initEmail)
                setIsEmailInvalid(false)
                setIsEmailExist(false)
                setIsEmailEmpty(false)
            }
            else {
                setIsEmailInvalid(true)
            }
        }
    }

    const handleCheckIfEmailExist = () => {
        let emailExist = false;
        let currentModifiedOutlet = outletList.filter((eachData) => eachData.id === selectedOutlet.id)

        currentModifiedOutlet[0].outlet_emails.forEach((eachData) => {
            if (normalizeEmail(eachData.email) === normalizeEmail(email.email)) {
                emailExist = true;
            }
        })

        //this is for Admin View Logic
        // emailList.forEach((eachData) => {
        //     if (normalizeEmail(eachData.email) === normalizeEmail(email.email)) {
        //         emailExist = true;
        //     }
        // });

        return emailExist;
    }

    const handleCloseDialogAddNewEmail = () => {
        setEmail(initEmail)
        // handleOpenDialog('emailList');
        setIsEmailInvalid(false)
        setIsEmailExist(false)
        setIsEmailEmpty(false)
        setOpenDialog("");
    }

    useEffect(() => {
        const roles = [
            {
                value: RoleEnum.ADMIN,
                label: t("admin"),
            },
            {
                value: RoleEnum.SUPER_ADMIN,
                label: t("superAdmin"),
            },
            {
                value: RoleEnum.STANDARD,
                label: t("standard"),
            },
        ];

        const rolesOptionForAdmin = [
            {
                value: RoleEnum.ADMIN,
                label: t("admin"),
            },
            {
                value: RoleEnum.STANDARD,
                label: t("standard"),
            },
        ];

        if (userRole === RoleEnum.SUPER_ADMIN) {
            setRolesOption(roles)
        } else {
            setRolesOption(rolesOptionForAdmin)
        }
    }, [userRole])

    return (
        <>
            <CustomDialog width={900} open={isOpen} onClose={handleCloseDialogAddNewEmail} theme={theme} >
                <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                    <CustomDialogContent isMobile={isMobile} sx={{ padding: isMobile ? '12px 16px 24px 16px' : '0px 40px 64px 40px' }}>
                        <DivFlexCenter sx={{ height: isMobile ? 22 : 40, mb: isMobile ? 5 : 8 }}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: isMobile ? 22 : 48, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                {t("addNewEmail")}
                            </Typography>
                        </DivFlexCenter>
                        <FormControl sx={{ width: '100%' }} >
                            <DivFlexSpaceBetween sx={{ flexWrap: 'wrap', width: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: isMobile ? 1 : 1.5, mb: (!isDesktop && !isEmailEmpty && !isEmailInvalid) && 2 }}>
                                    <Typography sx={{ fontSize: isMobile ? 14 : 24, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, color: theme.palette.text.primary }}>
                                        {`${t("email")} *`}
                                    </Typography>
                                    <TextFieldStyled
                                        onChange={handleChangeInput}
                                        id="basic"
                                        placeholder="example@mail.com"
                                        name="email"
                                        sx={{ width: '100%' }}
                                        isMobile={isMobile}
                                        padding={isMobile ? '16px' : '28px 24px'}
                                        fontSize={isMobile ? 16 : 24}
                                    />
                                </Box>

                                {!isDesktop && (isEmailEmpty || isEmailExist || isEmailInvalid) &&
                                    <ErrorMessage
                                        isEmailEmpty={isEmailEmpty}
                                        isEmailInvalid={isEmailInvalid}
                                        isEmailExist={isEmailExist}
                                        isDesktop={isDesktop}
                                        isMobile={isMobile}
                                    />
                                }

                                {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: isDesktop ? '35%' : '100%', gap: isMobile ? 1 : 1.5 }}>
                                    <Typography sx={{ fontSize: isMobile ? 14 : 24, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, color: theme.palette.text.primary }}>
                                        {`${t("roles")} *`}
                                    </Typography>

                                    <SelectMenuItem
                                        id={"roles"}
                                        name={"role"}
                                        defaultValue={email?.role}
                                        handleChangeInput={handleChangeInput}
                                        options={rolesOption}
                                        fontSize={isMobile ? 14 : 24}
                                    />

                                </Box> */}
                            </DivFlexSpaceBetween>
                        </FormControl>

                        {isDesktop && (isEmailEmpty || isEmailExist || isEmailInvalid) &&
                            <ErrorMessage
                                isEmailEmpty={isEmailEmpty}
                                isEmailInvalid={isEmailInvalid}
                                isEmailExist={isEmailExist}
                                isDesktop={isDesktop}
                                isMobile={isMobile}
                            />
                        }

                        <DivFlexSpaceBetween sx={{ mt: isMobile ? 9.75 : 10, flexDirection: isMobile ? 'column-reverse' : 'row', gap: isMobile ? 1.5 : 0 }}>
                            <Typography sx={{ fontSize: isMobile ? 14 : 21, textDecoration: 'underline', fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', color: theme.palette.text.primary }} onClick={handleCloseDialogAddNewEmail} >
                                {t("cancel")}
                            </Typography>
                            <Button style={{ width: isMobile ? '100%' : '35%', py: isMobile ? '12px' : '16px', height: '' }} onClick={handleSubmit}>
                                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 24 }}>
                                    {t("save")}
                                </Typography>
                            </Button>
                        </DivFlexSpaceBetween>
                    </CustomDialogContent>
                </Box>
            </CustomDialog>
            <Outlet />
        </>

    )
}


export default AddNewEmailDialog