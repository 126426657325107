import React, { useContext, useEffect } from "react";

// import i18n
import { useTranslation } from "react-i18next";

// import context
import { AppContext } from "../../App";
import { SignUpContext } from "./SignUp";

// import constant
import { FontFamily } from "../../Constants/FontFamily";
import { BannerVariant } from "../../Constants/BannerVariant";
import { SignUpPages } from "../../Constants/SignUpPages";

// import theme
import { useTheme } from "@mui/material/styles";

// import icons
import ErrorIcon from "../../assets/icons/ErrorIcon";
import AlertCircle from "../../assets/icons/AlertCircle";

// import components
import { Box, CircularProgress, Typography, circularProgressClasses } from "@mui/material";
import TopNavbar from "../../Components/TopNavbar/TopNavbar";
import TextFieldStyled from "../../Components/TextField/TextFieldStyle";
import Button from "../../Components/Button";
import Banner from "../../Components/Banner/Banner";
import ErrorMessage from "../../Components/ErrorMessage";

const Header = (props) => {
    const { theme } = props
    const { logo, isLogin, isLogoImage } = useContext(AppContext)

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                height: 'fit-content',
                pl: '115px',
                pr: '40px',
                pt: '48px'
            }}
        >
            {isLogoImage ? (
                <img
                    src={logo}
                    alt="company logo"
                    style={{
                        height: '63px'
                    }}
                />
            ) : (
                <Typography
                    color={theme.palette.text.topNavbarText}
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSBOLD,
                        fontSize: '48px',
                        lineHeight: '48px',
                        textTransform: 'uppercase',
                        maxWidth: 500,
                    }}
                >
                    {logo}
                </Typography>
            )}

            <TopNavbar
                shouldShowSettings={false}
                theme={theme}
                isLogin={isLogin}
                isSignUp
            />
        </Box>
    )
}

const InputEmailQRFlow = props => {
    const {
        email,
        handleChangeEmail,
        handleSubmitEmail,
        isEmailEmpty,
        isEmailInvalid
    } = useContext(SignUpContext)

    const { theme } = props

    const { t } = useTranslation()

    const isFieldError = isEmailEmpty || isEmailInvalid

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 56,
                        lineHeight: '73px',
                        color: theme.palette.text.primary
                    }}
                >
                    {`Tracking Your Order`}<br />{`Better Than Ever!`}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontSize: 24,
                        color: theme.palette.text.primary
                    }}
                >
                    {`To receive ETA and payload information via email for every delivery, please input your email here.`}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 24, color: theme.palette.text.primary }}>
                    {`${t("email")} *`}
                </Typography>
                <TextFieldStyled
                    placeholder="example@mail.com"
                    style={{ width: '100%' }}
                    padding="28px 24px"
                    fontSize="24px"
                    warning={isFieldError}
                    onChange={handleChangeEmail}
                    value={email}
                />
                {isFieldError &&
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <ErrorIcon sx={{ color: '#FA4D56', fontSize: 24 }} />
                        <Typography
                            sx={{
                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                fontSize: 24,
                                color: '#DA1E28',
                                lineHeight: 0
                            }}
                        >
                            {isEmailEmpty ? `${t("emailIsEmpty")}` : `${t("emailIsInvalid")}`}
                        </Typography>
                    </Box>
                }
            </Box>

            <Button
                style={{
                    width: 'fit-content',
                    padding: '16px 62.5px',
                    height: 'unset',
                    boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.14)'
                }}
                onClick={handleSubmitEmail}
            >
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 24 }}>
                    {t("submit")}
                </Typography>
            </Button>
        </Box >
    )
}

const EmailConfirmationFlow = props => {
    const {
        handleUseAnotherEmail,
        resendTimer,
        expiredTimer,
        resendActivation,
        resendActivationEmailLoading
    } = useContext(SignUpContext)

    const { theme } = props

    const { t } = useTranslation()

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 56,
                        lineHeight: '73px',
                        color: theme.palette.text.primary
                    }}
                >
                    {t('oneMoreStepAndYouAreAllSet')}
                </Typography>
                {expiredTimer <= 0 &&
                    <Box sx={{ marginTop: '32px' }}>
                        <Banner
                            variant={BannerVariant.DANGER}
                            fontSize={18}
                            width={'100%'}
                            icon={<AlertCircle />}
                        >
                            {t("theActivationLinkIsExpired")}
                        </Banner>
                    </Box>
                }
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontSize: 24,
                        color: theme.palette.text.primary,
                        marginTop: '32px'
                    }}
                >
                    {t('activationEmailSentDesc1')}
                    <br /><br />
                    {t('activationEmailSentDesc2')}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginTop: '80px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: 21,
                            color: (resendTimer > 0 || resendActivationEmailLoading) ? theme.palette.text.disabled : theme.palette.text.primary,
                            textDecorationLine: 'underline',
                            cursor: (resendTimer <= 0 && !resendActivationEmailLoading) && 'pointer'
                        }}
                        onClick={resendActivationEmailLoading ? undefined : resendActivation}
                    >
                        {`${t("resendActivationEmail")} ${resendTimer > 0 ? `(${resendTimer})` : ``}`}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                            fontSize: 21,
                            color: theme.palette.text.primary,
                            textDecorationLine: 'underline',
                            cursor: 'pointer'
                        }}
                        onClick={handleUseAnotherEmail}
                    >
                        {t("useAnotherEmailAddress")}
                    </Typography>
                </Box>
            </Box>
        </Box >
    )
}

const InputRegistrationCode = (props) => {
    const {
        registrationCode,
        isRegCodeEmpty,
        isRegCodeNotFound,
        handleChangeRegCode,
        handleSubmitRegCode
    } = useContext(SignUpContext)

    const { theme } = props

    const { t } = useTranslation()

    const isFieldInvalid = isRegCodeEmpty || isRegCodeNotFound

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 56,
                        lineHeight: '73px',
                        color: theme.palette.text.primary
                    }}
                >
                    {t("trackingYourOrder")}<br />{t("betterThanEver")}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontSize: 24,
                        color: theme.palette.text.primary,
                        marginTop: '32px'
                    }}
                >
                    {t("enterRegistrationCodeToDiscover")}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 20,
                        color: theme.palette.text.primary,
                        marginTop: '80px',
                        marginBottom: '12px'
                    }}
                >
                    {`${t("registrationCode")} *`}
                </Typography>
                <TextFieldStyled
                    placeholder={t("input9DigitsCode")}
                    style={{ width: '100%' }}
                    padding="24px 16px"
                    fontSize="20px"
                    warning={isFieldInvalid}
                    value={registrationCode}
                    onChange={handleChangeRegCode}
                />
                {isFieldInvalid &&
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                        <ErrorIcon sx={{ color: '#FA4D56', fontSize: 20, marginRight: '8px', marginTop: '-4px' }} />
                        <Typography
                            sx={{
                                fontFamily: FontFamily.PLUSJAKARTASANS,
                                fontSize: 20,
                                color: '#DA1E28',
                                lineHeight: '28px'
                            }}
                        >
                            {isRegCodeEmpty ? `${t("registrationCodeIsEmpty")}` : `${t("registrationCodeNotFound")}`}
                        </Typography>
                    </Box>
                }
            </Box>

            <Button
                style={{
                    width: 'fit-content',
                    padding: '16px 62.5px',
                    height: 'unset',
                    boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.14)',
                    marginTop: '80px'
                }}
                onClick={handleSubmitRegCode}
            >
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 20 }}>
                    {t("submit")}
                </Typography>
            </Button>
        </Box>
    )
}

const InputEmailManualFlow = (props) => {
    const {
        email,
        isEmailEmpty,
        isEmailInvalid,
        isEmailExist,
        handleChangeEmail,
        handleSubmitEmail
    } = useContext(SignUpContext)

    const { t } = useTranslation()

    const { theme } = props

    const isFieldInvalid = isEmailEmpty || isEmailInvalid || isEmailExist

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 56,
                        lineHeight: '73px',
                        color: theme.palette.text.primary
                    }}
                >
                    {t("shareYourEmailForDeliveryInfo")}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANS,
                        fontSize: 24,
                        color: theme.palette.text.primary,
                        marginTop: '32px'
                    }}
                >
                    {t("enterEmailToValidateAccount")}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontSize: 20,
                        color: theme.palette.text.primary,
                        marginTop: '80px',
                        marginBottom: '12px'
                    }}
                >
                    {`${t("email")} *`}
                </Typography>
                <TextFieldStyled
                    placeholder="example@mail.com"
                    style={{ width: '100%' }}
                    padding="24px 16px"
                    fontSize="20px"
                    value={email}
                    onChange={handleChangeEmail}
                    warning={isFieldInvalid}
                />
                {isFieldInvalid &&
                    <ErrorMessage
                        isEmailEmpty={isEmailEmpty}
                        isEmailInvalid={isEmailInvalid}
                        isEmailExist={isEmailExist}
                        isDesktop={true}
                        isMobile={false}
                    />
                }
            </Box>

            <Button
                style={{
                    width: 'fit-content',
                    padding: '16px 62.5px',
                    height: 'unset',
                    boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.14)',
                    marginTop: '80px'
                }}
                onClick={handleSubmitEmail}
            >
                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: 20 }}>
                    {t("submit")}
                </Typography>
            </Button>
        </Box >
    )
}

const SignUpDesktop = (props) => {
    const { currentPage } = useContext(SignUpContext)
    const { img } = props

    const theme = useTheme()

    const selectPageContent = () => {
        switch (currentPage) {
            case SignUpPages.INPUT_REG_CODE:
                return <InputRegistrationCode theme={theme} />
            case SignUpPages.INPUT_REG_EMAIL:
                return <InputEmailManualFlow theme={theme} />
            case SignUpPages.SCAN_QR_EMAIL:
                return <InputEmailQRFlow theme={theme} />
            case SignUpPages.EMAIL_CONFIRMATION:
                return <EmailConfirmationFlow theme={theme} />
            default:
                return <InputRegistrationCode theme={theme} />
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: 'calc(100vw - 840px)', height: '100vh' }}>
                <img src={img} alt="company logo" style={{ objectFit: 'cover', width: '100%', height: '100vh' }} />
            </Box>
            <Box sx={{ height: '100vh', width: '840px', overflow: 'auto' }}>
                <Header theme={theme} />
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        minHeight: 'calc(100% - 122px)',
                        padding: '104px 131px 80px 115px',
                    }}
                >
                    {selectPageContent()}
                </Box>
            </Box>
        </Box>
    )
}

export default SignUpDesktop