import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { Box, CardMedia } from '@mui/material'

import {
    PrevButton,
    NextButton,
    usePrevNextButtons
} from './CarouselArrow'

import { BulletButton, BulletWrapper, useBulletButton } from './CarouselBullet'

const ImageCarousel = (props) => {
    const { images, isDialog } = props
    const OPTIONS = { align: 'start', containScroll: 'trimSnaps' }

    const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS)

    const {
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    const { selectedIndex, scrollSnaps, onBulletButtonClick } = useBulletButton(emblaApi)

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {images.map((img, index) => {
                        if (img.preview !== '') {
                            return (
                                <div className="embla__slide" key={index}>
                                    <CardMedia
                                        component="img"
                                        className="embla__slide__img"
                                        image={img.preview}
                                        alt={`promo-${index}`}
                                        sx={{ objectFit: "cover", width: isDialog ? '553px' : '100%', height: isDialog ? '779px' : '100%' }}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>
            </div>

            {images.length > 1 && (
                <>
                    <Box
                        sx={{
                            padding: '0 8px',
                            position: 'absolute',
                            top: 'calc(50% - 14px)',
                        }}
                    >
                        <PrevButton onClick={(e) => onPrevButtonClick(e)} />
                    </Box>
                    <Box
                        sx={{
                            padding: '0 8px',
                            position: 'absolute',
                            top: 'calc(50% - 14px)',
                            right: 0
                        }}
                    >
                        <NextButton onClick={(e) => onNextButtonClick(e)} />
                    </Box>

                    <BulletWrapper>
                        {scrollSnaps.map((_, index) => (
                            <BulletButton
                                key={index}
                                onClick={(e) => onBulletButtonClick(e, index)}
                                isSelected={selectedIndex === index}
                            />
                        ))}
                    </BulletWrapper>
                </>
            )}
        </div>
    )
}

export default ImageCarousel