import React, { useContext, useEffect } from "react";

// import context
import { AppContext } from "../../App";

// import component
import { Box, Button, Typography } from "@mui/material";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import DivFlexCenter from "../DivFlexCenter";

// import style and theme
import { useTheme } from "@mui/material/styles";

// import constant
import { FontFamily } from "../../Constants/FontFamily";

// import i18n
import { useTranslation } from "react-i18next";

const SettingDisabledDialog = (props) => {
    const {
        isDesktop,
        handleCloseDialog
    } = useContext(AppContext)

    const { t } = useTranslation()

    const { isOpen } = props

    const theme = useTheme()

    useEffect(() => {
        if (isDesktop && isOpen && handleCloseDialog) {
            handleCloseDialog()
        }
    }, [isDesktop, isOpen, handleCloseDialog])

    return (
        <>
            <CustomDialog
                open={isOpen}
                onClose={handleCloseDialog}
                theme={theme}
                maxWidth={'md'}
            >
                <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                    <CustomDialogContent
                        isMobile={true}
                        sx={{
                            padding: '8px 16px 24px 16px',
                            backgroundColor: theme.palette.background.dialog
                        }}
                        theme={theme}
                    >
                        <DivFlexCenter>
                            <Typography
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: 22,
                                    fontFamily: FontFamily.PLUSJAKARTASANS,
                                    fontWeight: 600
                                }}
                            >
                                {`Setting Disabled`}
                            </Typography>
                        </DivFlexCenter>

                        <DivFlexCenter sx={{ mt: '40px' }}>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontFamily: FontFamily.PLUSJAKARTASANS,
                                    fontWeight: 400,
                                    color: theme.palette.text.primary,
                                    textAlign: 'center'
                                }}
                            >
                                {`${t("settingIsNotAvailableForStandardRole")} ${t("pleaseUpgradeToAdmin")}`}
                            </Typography>
                        </DivFlexCenter>

                        <DivFlexCenter
                            sx={{
                                mt: '83px',
                                backgroundColor: theme.palette.background.buttonDefault
                            }}
                            onClick={handleCloseDialog}
                        >
                            <Button>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontFamily: FontFamily.PLUSJAKARTASANS,
                                        fontWeight: 600,
                                        color: theme.palette.text.primaryButtonText,
                                        padding: '6px 0'
                                    }}
                                >
                                    {`OK`}
                                </Typography>
                            </Button>
                        </DivFlexCenter>
                    </CustomDialogContent>
                </Box>
            </CustomDialog>
        </>
    )
}

export default SettingDisabledDialog