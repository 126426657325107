const ENV = {
    DEV: 'DEV',
    DEMO: 'DEMO',
    TEST_PRODUCTION: 'TEST_PRODUCTION',
    PRODUCTION: 'PRODUCTION',
}

const etaPortalMiddlewareServiceConfig = {
    DEV: 'http://localhost:3005/api',
    DEMO: 'https://gotraces-api.gotraces.de/api',
    TEST_PRODUCTION: 'https://gotraces-api-gotraces-de.commsult.dev/api',
    PRODUCTION: 'https://gotraces-api.gotraces.de/api'
}

const etaPwaUrlConfig = {
    DEV: 'behn.gotraces.de',
    DEMO: 'drinksandmore.gotraces.de',
    TEST_PRODUCTION: window.location.host,
    PRODUCTION: window.location.host
}

export const ACTIVE_STATIC_URL = '/static/aSk9EQSvRD6rjyK3zWjmp1e'

// Only need to change ACTIVE_ENV to switch services
export const ACTIVE_ENV = ENV[window.ACTIVE_ENV];

export const ACTIVE_SERVICE_URL = etaPortalMiddlewareServiceConfig[ACTIVE_ENV]
export const ACTIVE_ETA_PWA_URL = etaPwaUrlConfig[ACTIVE_ENV]

// export const ACTIVE_SERVICE_URL = 'https://gotraces-api-gotraces-de.commsult.dev/api'
// export const ACTIVE_ETA_PWA_URL = 'behn.gotraces-de.commsult.dev'
