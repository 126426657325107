import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../App";

// import i18n
import { useSSR, useTranslation } from "react-i18next";

// import style and theme
import { useTheme } from "@mui/styles";

// import components
import DialogBlanket from "./DialogContainer/DialogBlanket";
import { CircularProgress, circularProgressClasses, Typography } from "@mui/material";
import DivFlexCenter from "../DivFlexCenter";

// import constants
import { FontFamily } from "../../Constants/FontFamily";
import { ColorTheme } from "../../Constants/ColorTheme";

const LoadingDialog = () => {

    const { isMobile, mode } = useContext(AppContext)

    const { t } = useTranslation()

    const theme = useTheme()

    const [loadingBackgroundColor, setLoadingBackgroundColor] = useState("#FFFFFF")
    const [loaderColor, setLoaderColor] = useState("#6F6F6F")
    const [loaderTextColor, setLoaderTextColor] = useState("#1A1919")

    const getBackgroundColor = () => {
        switch (mode) {
            case ColorTheme.BLUE:
                return "#EBF2FD"
            case ColorTheme.YELLOW:
                return "#FFFDF4"
            case ColorTheme.DARK:
                return "#262626"
            case ColorTheme.LIGHT:
                return "#FFFFFF"
            default:
                return "#FFFFFF"
        }
    }

    const getLoaderColor = () => {
        switch (mode) {
            case ColorTheme.BLUE:
                return "#183769"
            case ColorTheme.YELLOW:
                return "#8E6A00"
            case ColorTheme.DARK:
                return "#A8A8A8"
            case ColorTheme.LIGHT:
                return "#6F6F6F"
            default:
                return "#6F6F6F"
        }
    }

    const getLoaderTextColor = () => {
        switch (mode) {
            case ColorTheme.BLUE:
                return "#1A1919"
            case ColorTheme.YELLOW:
                return "#1A1919"
            case ColorTheme.DARK:
                return "#F7F5F5"
            case ColorTheme.LIGHT:
                return "#1A1919"
            default:
                return "#1A1919"
        }
    }

    useEffect(() => {
        setLoadingBackgroundColor(getBackgroundColor())
        setLoaderColor(getLoaderColor())
        setLoaderTextColor(getLoaderTextColor())
    }, [mode])

    return (
        <DialogBlanket loadingBackgroundColor={loadingBackgroundColor} open={true} height={'100%'} isLoading>
            <DivFlexCenter sx={{ height: '100%', flexDirection: 'column' }}>
                <CircularProgress
                    size={isMobile ? "56px" : "72px"}
                    sx={{
                        color: loaderColor,
                        // animationDuration: "0ms",
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    }}
                    thickness={4.6}
                    disableShrink
                />
                <Typography sx={{ mt: isMobile ? 2 : 2.5, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 16 : 18, color: loaderTextColor }}>
                    {`${t("1_1-7-8_loader_text")}..`}
                </Typography>
            </DivFlexCenter>
        </DialogBlanket>
    )
}

export default LoadingDialog