import React, { useContext } from 'react';

import DivFlexEnd from '../../DivFlexEnd';

// import icon
import CloseIcon from '../../../assets/icons/CloseIcon';

import { AppContext } from '../../../App';

import { useTheme } from '@mui/material';

import DialogBlanket from './DialogBlanket';

export const CustomDialog = (props) => {
    const { children, open, onClose, width, maxWidth, paddingRight ,withoutClose =false} = props

    const { isMobile } = useContext(AppContext)

    const theme = useTheme()

    return (
        <DialogBlanket open={open} width={width} theme={theme} maxWidth={maxWidth}>
            <>
                <DivFlexEnd
                    sx={{
                        pr: paddingRight ?
                            paddingRight :
                            isMobile ? 3 : '40px',
                        pt: isMobile ? 3 : '32px',
                        background: theme.palette.background.dialog
                    }}
                >
                    {!withoutClose && (
                    <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', fontSize: 20 }} />
                    )}
                </DivFlexEnd>
                {children}
            </>
        </DialogBlanket>
    )
}


export default CustomDialog
