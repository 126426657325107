import React, { useContext, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";

// import material component
import { Box, Collapse, InputAdornment, Typography } from "@mui/material";

// import AppContext
import { AppContext } from "../../App";

// import Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClockIcon from "../../assets/icons/ClockIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import SearchIcon from '@mui/icons-material/Search';
import ButtonPage from "../../assets/images/radioButtonDefault.png"
import ButtonPageHover from "../../assets/images/radioButtonHover.png"
import ButtonPageDangerDefault from "../../assets/images/radioButtonDangerDefault.png"
import ButtonPageDangerHover from "../../assets/images/radioButtonDangerHover.png"
import ButtonPageWarningDefault from "../../assets/images/radioButtonWarningDefault.png"
import ButtonPageWarningHover from "../../assets/images/radioButtonWarningHover.png"
import ButtonPageActiveDefault from "../../assets/images/radioButtonActiveDefault.png"
import ButtonPageActiveHover from "../../assets/images/radioButtonActiveHover.png"

// import components
import ItemList from "../ItemList/ItemList";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import DivFlexStart from "../DivFlexStart";
import TextFieldDeliveryCardMenu from "../ReusableComponents/TextFieldDeliveryCardMenu";

// import Utils
import { getFormatDate } from "../../connector/Utils/DateUtils";
import { getTimeFormat } from "../../connector/Utils/DateUtils";
import moment from "moment";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";
import { CustomTooltip } from "../CustomTooltip";
import { BannerVariant } from "../../Constants/BannerVariant";
// import { deliverySimlationDarkAnimation } from "../Animations/Animations";

//import styles
import "../../index.css"
import { useTranslation } from "react-i18next";
import ErrorIcon from "../../assets/icons/ErrorIcon";
import { getStatusChip } from "../../utils/componentHelper";
import Banner from "../Banner/Banner";

const RootDeliveryCard = styled("div")((props) => ({
  backgroundColor: props.isDesktop
    ? props.data.id === props.deliveryId
      ? props.theme.palette.background.deliveryCardMenu
      : props.isOpenItemList
        ? props.theme.palette.background.deliveryCardMenu
        : props.theme.palette.background.default
    : props.theme.palette.background.deliveryCardMenu,
  padding: props.isDesktop ?
    props.promoLength === 0 ? "0px 100px 0px 100px" : 0
    : props.openDetail ? "16px 24px 16px 24px" : 0,
  paddingTop: "40px",
  cursor: props.isDesktop
    ? props.isOpenItemList
      ? "default"
      : "pointer"
    : "pointer",
  ":hover": {
    backgroundColor: props.isDesktop
      ? props.isOpenItemList
        ? undefined
        : props.theme.palette.background.hoverDeliveryCard
      : undefined,
  },
}));

export const ItemStickyTitle = styled("div")((props) => ({
  position: "-webkit- sticky",
  position: "sticky",
  top: -1,
  width: "100%",
  display: 'flex',
  alignItems: 'center',
  zIndex: 10,
  backgroundColor: props.theme.palette.background.deliveryCardMenu
}));

const DeliveryCardMenu = (props) => {
  const { totalDelivery, numberOfDeliver, data, handleSearchByOrderNumberOrPositionName, isOpenItemList, deliveryId, promoLength, searchBarValue } =
    props;

  const { isMobile, isTablet, isDesktop } = useContext(AppContext);

  // theme color
  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const { innerWidth: width } = window;



  // local state for open itemlist
  const [openDetail, setOpenDetail] = useState(false)
  const [activeRadioButton, setActiveRadioButton] = useState(data?.orders[0]?.orderNumber);
  const [hoverState, setHoverState] = useState("");
  const [allHeight, setAllHeight] = useState(0);
  const [reduceHeight, setReduceHeight] = useState(0);

  const handleChangeRadio = (order) => {
    var section = document.getElementById(`content-${order.orderNumber}`)
    section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center', scrollBehavior: "smooth" });
  }

  const toggleVisible = () => {
    const container = document.getElementById('parentScroll');
    var containerTop = container.scrollTop + 1;
    const isScrollAtMax = containerTop >= (container.scrollHeight - container.clientHeight)
    if (isScrollAtMax) {
      containerTop = containerTop + 450
    }

    const itemsHeight = []
    data.orders?.forEach((order) => {
      itemsHeight.push(
        document.getElementById(`content-${order.orderNumber}`).offsetHeight);
    })

    if (containerTop >= allHeight) {
      let tempHeight = 0;
      let tempReduceHeight = 0;
      for (let i = 0; i < itemsHeight.length; i++) {
        tempHeight += itemsHeight[i];
        tempReduceHeight += itemsHeight[i];
        if (tempHeight > allHeight) {
          setAllHeight(tempHeight)
          tempReduceHeight -= itemsHeight[i];
          setReduceHeight(tempReduceHeight)
          setActiveRadioButton(data.orders[i].orderNumber)
          break;
        }
      }
    }
    else if (containerTop <= reduceHeight) {
      let tempHeight = 0;
      let tempReduceHeight = 0;
      for (let i = 0; i < itemsHeight.length; i++) {
        tempHeight += itemsHeight[i];
        tempReduceHeight += itemsHeight[i];
        if (reduceHeight === tempReduceHeight) {
          setReduceHeight(tempReduceHeight - itemsHeight[i]);
          setActiveRadioButton(data.orders[i].orderNumber);
          setAllHeight(tempHeight);
          break;
        }
      }
    }
  }

  useEffect(() => {
    toggleVisible()
  })

  useEffect(() => {
    if (totalDelivery === 1) {
      setOpenDetail(true);
    } else {
      setOpenDetail(false);
    }
  }, [totalDelivery]);

  // useEffect(() => {
  //   setActiveRadioButton(data.orders[0].orderNumber)
  // }, [data])

  // data

  const timeStart = data.tourStopNotifications ? getTimeFormat(data.tourStopNotifications.actual.twStart) : null
  const timeEnd = data.tourStopNotifications ? getTimeFormat(data.tourStopNotifications.actual.twEnd) : null

  /**
   * Temporary logic for displaying ETA coming soon.
   * Might use twType later
   */
  const displayETATime = () => {
    const isComingSoon = moment(data.tourStopNotifications?.notificationLive?.twStart).isSameOrBefore(moment())

    if (data.stopStatus === "FINISHED") {
      return `${t("deliveryFinished")} (${moment(data.stopEnd).format("HH:mm")})`
    }
    if (isComingSoon && data.stopStatus === "APPROACHED") {
      return t("comingSoon")
    }
    if (timeStart && timeEnd) {
      return `${timeStart} - ${timeEnd}`
    }

    return "-"
  }

  return (
    <Box sx={{ width: openDetail || isDesktop ? "100%" : "calc(100% - 48px)", height: "100%" }}>
      <RootDeliveryCard
        onClick={isDesktop ? undefined : () => setOpenDetail(!openDetail)}
        isDesktop={isDesktop}
        isOpenItemList={isOpenItemList}
        deliveryId={deliveryId}
        data={data}
        openDetail={openDetail}
        promoLength={promoLength}
      >
        {(isMobile || isTablet) && (
          <DivFlexSpaceBetween sx={{ mb: 1 }}>
            <Typography
              fontSize={12}
              color={theme.palette.text.primary}
              sx={{ fontFamily: FontFamily.PLUSJAKARTASANSITALIC }}
            >
              {`${numberOfDeliver} of ${totalDelivery}`}
            </Typography>
            {getStatusChip(data, theme, t)}
          </DivFlexSpaceBetween>
        )}

        {data.stopStatus === "CANCELED" && (
          <Banner width={"100%"} marginBottom={48} variant={BannerVariant.DANGER}>
            {t("deliveryIsCanceledPleaseWait")}
          </Banner>
        )}
        <DivFlexSpaceBetween
          sx={{ mb: isDesktop ? 0 : undefined, flexWrap: "wrap" }}
        >
          {/* <Typography
            fontSize={"14px"}
            sx={{
              fontFamily: FontFamily.PLUSJAKARTASANS,
              letterSpacing: "0.15em",
              fontWeight: 400,
              lineHeight: "19.32px",
              fontStyle: "normal",
            }}
            color={theme.palette.text.primary}
          >
            {data.orderNumber}
          </Typography> */}
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Box sx={{ display: isDesktop ? "block" : "none", ml: -1.2 }}>
              {getStatusChip(data, theme, t)}
            </Box>
            <Typography
              fontSize={12}
              sx={{
                fontFamily: FontFamily.PLUSJAKARTASANS,
                letterSpacing: "1.8px",
                fontStyle: "normal",
                mt: 0.5
              }}
              color={theme.palette.text.primary}
            >
              {`${t('tourNumber')}: ${data?.tourNumberGL} / ${data?.tripNumber}`}
            </Typography>
          </Box>
        </DivFlexSpaceBetween>

        <Box sx={{ marginTop: "16px" }}>
          <Typography
            fontSize={16}
            color={theme.palette.text.primary}
            sx={{
              fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
            }}
          >
            {data?.customerText}
          </Typography>
        </Box>

        <DivFlexStart sx={{ marginTop: "16px", marginBottom: "8px" }}>
          <Typography
            fontSize={16}
            color={theme.palette.text.primary}
            sx={{
              fontFamily: FontFamily.PLUSJAKARTASANS
            }}
          >
            {data.address}
          </Typography>
        </DivFlexStart>

        {data.stopStatus === "Late" ? (
          <DivFlexSpaceBetween sx={{ height: 16, pt: 1 }}>
            <Typography
              fontSize={10}
              color={theme.palette.text.primary}
              sx={{
                fontFamily: FontFamily.PLUSJAKARTASANSLIGHT,
                textDecoration: "line-through",
                ml: 2.2,
              }}
            >
              mn
              {/* {displayETATime()} */}
            </Typography>
          </DivFlexSpaceBetween>
        ) : (
          <DivFlexStart sx={{ height: 16 }} />
        )}

        <DivFlexSpaceBetween>
          <DivFlexStart>
            <Box>
              <CalendarIcon
                color={data.stopStatus === "CANCELED" ?
                  theme.palette.background.iconColorDisabled :
                  theme.palette.background.iconColor
                }
                sx={{
                  marginRight: "9.5px",
                  padding: "0px",
                  fontSize: width <= 1260 ? "22px" : "25px",
                }}
              />
            </Box>
            <Box>
              <Typography
                fontSize={width <= 1260 ? '17px' : width <= 1460 ? "21px" : "28px"}
                color={data.stopStatus === "CANCELED" ?
                  theme.palette.text.disabled :
                  theme.palette.text.primary
                }
                sx={{
                  fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                  fontStyle: "normal",
                  textDecorationLine: data.stopStatus === "CANCELED" && "line-through"
                }}
              >
                {getFormatDate(i18n, data.tourDate)}
              </Typography>
            </Box>
            {data.stopStatus === "Late" ? (
              <DivFlexStart sx={{ width: 40 }}>
                <ArrowDropDownIcon
                  sx={{ width: 20, height: 20, color: "#da1e28" }}
                />
                <Typography
                  fontSize={10}
                  color={"#da1e28"}
                  sx={{
                    fontFamily: FontFamily.PLUSJAKARTASANSLIGHT,
                    textTransform: "uppercase",
                    mt: 0.1,
                  }}
                >
                  {`Late`}
                </Typography>
              </DivFlexStart>
            ) : data.stopStatus === "Early" ? (
              <>
                <DivFlexStart sx={{ width: 40 }}>
                  {/* <ArrowDropUpIcon sx={{ width: 20, height: 20, color: '#58d632' }} />
                            <Typography fontSize={10} color={'#31711e'} sx={{ fontFamily: FontFamily.PLUSJAKARTASANSLIGHT, textTransform: 'uppercase' }}>
                                Early
                            </Typography> */}
                </DivFlexStart>
                <></>
              </>
            ) : (
              <DivFlexStart sx={{ width: 40 }} />
            )}
          </DivFlexStart>

          {/* <DivFlexStart sx={{ display: isOpenItemList || openDetail ? '' : 'none' }}>
                        <CalendarIcon sx={{
                            fontSize: 12,
                            mr: 0.5,
                            ml: 0.1,
                            color: '#959499'
                        }} />
                        <Typography fontSize={14} color={theme.palette.text.primary} sx={{ fontFamily: FontFamily.PLUSJAKARTASANSLIGHT }}>
                            {getFormatDate(data.stopStart)}
                        </Typography>
                    </DivFlexStart> */}
          <DivFlexStart>
            <Box>
              <ClockIcon
                color={data.stopStatus === "CANCELED" ?
                  theme.palette.background.iconColorDisabled :
                  theme.palette.background.iconColor
                }
                sx={{
                  fontSize: width <= 1260 ? "22px" : "25px",
                  marginRight: "9.5px",
                  padding: "0px",
                }}
              />
            </Box>
            <Box>
              <Typography
                fontSize={width <= 1260 ? '17px' : width <= 1460 ? "21px" : "28px"}
                color={data.stopStatus === "CANCELED" ?
                  theme.palette.text.disabled :
                  theme.palette.text.primary
                }
                sx={{
                  fontFamily: FontFamily.PLUSJAKARTASANS,
                  fontStyle: "normal",
                  textDecorationLine: data.stopStatus === "CANCELED" && "line-through"
                }}
              >
                {displayETATime()}
              </Typography>
            </Box>
          </DivFlexStart>
        </DivFlexSpaceBetween>

        {/* <DivFlexStart style={{ paddingTop: "24px", paddingBottom: "40px" }}>
          <TextFieldDeliveryCardMenu
            sx={{
              backgroundColor: theme.palette.background.deliveryCardMenuSearchBar,
              "&::placeholder": {    // <----- Add this.
                opacity: 1,
                color: theme.palette.text.inputTextActive,
              },
            }}
            onChange={handleSearchByOrderNumberOrPositionName}
            value={searchBarValue}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ padding: "0px" }} position="start">
                  <SearchIcon sx={{ color: theme.palette.background.deliverycardSearchIcon }} />
                </InputAdornment>
              )
            }}
            placeholder={t('searchByReceiptNumberOrItemName')} />
        </DivFlexStart> */}

        <DivFlexSpaceBetween
          sx={{
            display: isOpenItemList || openDetail ? "none" : "",
            flexWrap: "wrap",
          }}
        >
          <DivFlexStart>
            {/* <CalendarIcon color={'#959499'} sx={{
                            fontSize: 12,
                            mr: 0.5,
                            ml: 0.1
                        }} /> */}
            {/* <Typography fontSize={10} color={theme.palette.text.primary} sx={{ fontFamily: FontFamily.PLUSJAKARTASANSLIGHT }}>
                            {getFormatDate(data.stopStart)}
                        </Typography> */}
          </DivFlexStart>
        </DivFlexSpaceBetween>
      </RootDeliveryCard>

      <Box
        sx={{
          display: 'flex',
          scrollbarWidth: "none", // Hide the scrollbar for firefox
          '&::-webkit-scrollbar': {
            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
          },
          '&-ms-overflow-style:': {
            display: 'none', // Hide the scrollbar for IE
          },
          paddingTop: "40px"
        }}
      >
        <Box
          sx={{
            scrollbarWidth: "none", // Hide the scrollbar for firefox
            '&::-webkit-scrollbar': {
              display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
            },
            '&-ms-overflow-style:': {
              display: 'none', // Hide the scrollbar for IE
            },
          }}
        >
          {data.orders?.map((order, orderIndex) => {
            return (
              <div key={orderIndex} style={{ display: 'flex', paddingBottom: "11px" }} onClick={() => { handleChangeRadio(order) }}>
                <CustomTooltip sx={{ whiteSpace: "pre-line" }} title={order.orderPositions.some((v) => v.warning === true) ? `${order.orderNumber} \n ${t("discrepancy")}` : order.orderNumber}>
                  <div id={order.orderNumber}
                    onMouseEnter={() => { setHoverState(order.orderNumber) }}
                    onMouseLeave={() => { setHoverState("") }}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "24px", height: "24px" }}>
                    {order.orderNumber === activeRadioButton ? (
                      <div>
                        {order.orderPositions.some((v) => v.warning === true) ? (
                          <Box className="icons">
                            {hoverState === order.orderNumber ? (
                              <img alt="" src={ButtonPageWarningHover} style={{ height: "24px", width: "24px", backgr: 'red' }}
                              />
                            ) : (
                              <img alt="" src={ButtonPageWarningDefault} style={{ height: "24px", width: "24px" }}
                              />
                            )}
                          </Box>
                        ) : (
                          <Box className="icons">
                            {hoverState === order.orderNumber ? (
                              <img alt="" src={ButtonPageActiveHover} style={{ height: "24px", width: "24px" }} />
                            ) : (
                              <img alt="" src={ButtonPageActiveDefault} style={{ height: "24px", width: "24px" }} />
                            )}
                          </Box>
                        )}
                      </div>
                    ) : (
                      <Box>
                        {order.orderPositions.some((v) => v.warning === true) ? (
                          <Box>
                            {hoverState === order.orderNumber ? (
                              <img alt="" src={ButtonPageDangerHover} style={{ height: "12px", width: "16px", marginLeft: "5px" }}
                              />
                            ) : (
                              <img alt="" src={ButtonPageDangerDefault} style={{ height: "12px", width: "16px", marginLeft: "5px" }}
                              />
                            )}

                          </Box>
                        ) : (
                          <Box>
                            {hoverState === order.orderNumber ? (
                              <img alt="" src={ButtonPageHover} style={{ height: "12px", width: "12px" }}
                              />
                            ) : (
                              <img alt="" src={ButtonPage} style={{ height: "12px", width: "12px" }}
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </div>
                </CustomTooltip>
              </div>
            )
          })}
        </Box>
        <Box
          id="parentScroll"
          className="deliveryCardMenuItems"
          sx={{
            paddingLeft: "60px",
            width: '100%',
            height: data.stopStatus === "CANCELED" ? "calc(100vh - 510px)" : "calc(100vh - 400px)",
            overflowY: "auto",
            scrollBehavior: "smooth",
            scrollbarWidth: "none", // Hide the scrollbar for firefox
            '&::-webkit-scrollbar': {
              display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
            },
            '&-ms-overflow-style:': {
              display: 'none', // Hide the scrollbar for IE
            },
          }}
        >
          {data.orders?.map((order, orderIndex) => (
            <div key={orderIndex}>
              <DivFlexSpaceBetween id={`content-${order.orderNumber}`} style={{ paddingBottom: "40px", alignItems: "none", display: 'flex', justifyContent: "start" }}>
                <div style={{ width: '100%' }}>
                  <ItemStickyTitle>
                    <Typography
                      fontSize={18}
                      sx={{
                        fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD,
                        fontStyle: "normal",
                        fontWeight: 700
                      }}
                    >
                      {`${t('receipt')} ${order.orderNumber}`}
                    </Typography>
                    {order.orderPositions.some((v) => v.warning === true) && (
                      <ErrorIcon sx={{ marginLeft: "8px", color: "#da1e28", fontSize: 20, marginTop: "-5px" }} />
                    )}
                  </ItemStickyTitle>
                  <Collapse style={{ width: "100%", marginTop: 10 }} in={(openDetail && !isDesktop) || isOpenItemList}>
                    {order.orderPositions?.map((orderPosition, index) => {
                      // orderPosition.amount = orderPosition.amount ?? 0
                      // orderPosition.newAmount = orderPosition.newAmount ?? 0
                      // orderPosition.warning = !orderPosition.isEmptyReturn && orderPosition.amount != orderPosition.newAmount
                      return (
                        <ItemList
                          item={orderPosition}
                          key={index}
                          index={index}
                          itemLength={order.orderPositions.length}
                          isOpenItemList={isOpenItemList}
                        />
                      )
                    })}
                  </Collapse>
                </div>
              </DivFlexSpaceBetween>
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

DeliveryCardMenu.defaultProps = {
  data: {
    stopStatus: "",
    stopStart: "-",
    twStart: "-",
    twEnd: "-",
    vehicle: "-",
    tourSorted: "",
    totalStops: 0,
    isCanceled: false,
    orderPositions: [0, 1, 2, 3],
    address: "-",
    customerText: "-",
    orderNumber: "-",
  },
};

export default DeliveryCardMenu;
