// React Lib
import React from "react";

// Component Styles
import './SnackbarStyles.css';

// @otg-one/ui_components/components
import Typography from "@mui/material/Typography";
import Button from "../Button";

// @otg-one/ui_components/constants
// import { Color, FONT_FAMILY } from "../../constants";

// Material UI Core
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { FontFamily } from "../../Constants/FontFamily";

const Snackbar = (props) => {
    const { id, show, onClick, icon, children, showButton, textButtonLabel, animationDuration, backgroundColor } = props;

    return (
        <Slide direction="up" in={show} mountOnEnter unmountOnExit>
            <div className="snackbarWrapper" style={{ backgroundColor: backgroundColor }} id={id}>
                <div className="meter" style={{ animationDuration: `${animationDuration}ms`, WebkitAnimationDuration: `${animationDuration}ms` }} />
                <div className="snackbarContainer">
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        <Grid item md={2}>
                            <div style={{ display: 'flex', alignItems: 'center', float: "left" }}>
                                <div className="snackbarIconContainer">
                                    {icon}
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={8}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                                <Typography
                                    fontFamily={FontFamily.PLUSJAKARTASANS}
                                    size={14}
                                // color={Color.neutral[0]}
                                >
                                    {children}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item md={2}>
                            <div style={{ float: "right" }}>
                                {showButton && (
                                    <Button
                                        color="white"
                                        onClick={onClick}
                                    >
                                        {textButtonLabel}
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Slide>
    );
}

Snackbar.defaultProps = {
    showButton: false,
    textButtonLabel: "Close",
    animationDuration: 1000
};

export default React.memo(Snackbar);
