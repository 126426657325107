import React, { useContext, useState, useEffect } from "react";

// import material UI
import { Typography, FormControl, Box } from "@mui/material";

// import icon
import ErrorIcon from "../../assets/icons/ErrorIcon";

// import react router dom
import { Outlet } from "react-router-dom";

// import context
import { AppContext } from "../../App";

// import i18n
import { useTranslation } from "react-i18next";

// import component
import DivFlexStart from "../DivFlexStart";
import DivFlexCenter from "../DivFlexCenter";
import DivFlexSpaceBetween from "../DivFlexSpacebetween";
import CustomDialog from "./DialogContainer/CustomDialog";
import CustomDialogContent from "./DialogContainer/CustomDialogContent";
import TextFieldStyled from "../TextField/TextFieldStyle";
import Button from "../Button";
import SelectMenuItem from "./SelectMenuItem/SelectMenuItem";

// import Constants
import { FontFamily } from "../../Constants/FontFamily";


// import style and theme
import { useTheme } from "@mui/material/styles";
import { RoleEnum } from "../../Constants/RoleEnum";
import ErrorMessage from "../ErrorMessage";
import { cloneDeep } from "lodash";

const EditEmailDialog = (props) => {

    const { isMobile, selectedEmail, setSelectedEmail, isDesktop, handleOpenDialog, rolesOption, emailList, setOpenDialog } = useContext(AppContext)
    const { isOpen, handleEditNewEmailTempState } = props;

    const { t } = useTranslation()

    const theme = useTheme()

    const [email, setEmail] = useState({
        id: null,
        email: "",
        role: RoleEnum.SUPER_ADMIN,
    })

    // validation for email
    const [isEmailEmpty, setIsEmailEmpty] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isEmailExist, setIsEmailExist] = useState(false)

    const [isEmailModified, setIsEmailModified] = useState(false);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }


    const handleChangeInput = (e) => {
        const { name, value } = e.target
        if (name === "email") {
            setIsEmailModified(true)
        }

        setEmail({ ...email, [name]: value })
    }

    const handleSubmit = (e) => {
        let checkedEmail = handleCheckIfEmailExist()

        if (checkedEmail.emailExist && isEmailModified) {
            setIsEmailExist(true);
            return;
        }
        if (email.email === '') {
            setIsEmailEmpty(true)
        }
        if (email.email !== '') {
            setIsEmailEmpty(false)
            if (isValidEmail(email.email)) {
                e.preventDefault()
                handleEditNewEmailTempState(email)
                // handleOpenDialog('emailList')
                setOpenDialog("");
                setIsEmailInvalid(false)
                setIsEmailExist(false)
                setIsEmailModified(false)

            } else {
                setIsEmailInvalid(true)
            }
        }
    }

    const handleCheckIfEmailExist = () => {
        let checkedEmail = {
            emailExist: false,
        };
        emailList.forEach((eachData) => {
            if (eachData.email === email.email) {
                checkedEmail.emailExist = true
            }
        });

        return checkedEmail;
    }

    const handleCloseDialogEditEmail = () => {
        // handleOpenDialog('emailList')
        setOpenDialog("")
        setIsEmailInvalid(false)
        setIsEmailEmpty(false)
        setIsEmailExist(false)
        setIsEmailModified(false)
        setEmail(selectedEmail)
        setSelectedEmail(null)
    }

    useEffect(() => {
        if (selectedEmail) {
            let tempEmail = cloneDeep(selectedEmail)
            setEmail(tempEmail)
        }
    }, [selectedEmail]
    )

    return (
        <>
            <CustomDialog width={900} open={isOpen} onClose={handleCloseDialogEditEmail} theme={theme} >
                <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                    <CustomDialogContent isMobile={isMobile} sx={{ padding: isMobile ? '12px 16px 24px 16px' : '0px 40px 64px 40px' }}>
                        <DivFlexCenter sx={{ height: isMobile ? 22 : 40, marginBottom: isMobile ? 5 : 8 }}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: isMobile ? 22 : 48, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                Edit Email
                            </Typography>
                        </DivFlexCenter>
                        <FormControl sx={{ width: '100%' }} >
                            <DivFlexSpaceBetween sx={{ flexWrap: 'wrap', width: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: isDesktop ? '60%' : '100%', gap: isMobile ? 1 : 1.5, mb: (!isDesktop && !isEmailEmpty && !isEmailInvalid) && 2 }}>
                                    <Typography sx={{ fontSize: isMobile ? 14 : 24, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, color: theme.palette.text.primary }}>
                                        {`${t("email")} *`}
                                    </Typography>
                                    <TextFieldStyled
                                        onChange={handleChangeInput}
                                        id="basic"
                                        placeholder=""
                                        name="email"
                                        sx={{ width: isDesktop ? '80%' : '100%' }}
                                        value={email?.email}
                                        isMobile={isMobile}
                                        padding={isMobile ? '16px' : '28px 24px'}
                                        fontSize={isMobile ? 16 : 24}
                                    />
                                </Box>

                                {!isDesktop && (isEmailEmpty || isEmailExist || isEmailInvalid) &&
                                    <ErrorMessage
                                        isEmailEmpty={isEmailEmpty}
                                        isEmailInvalid={isEmailInvalid}
                                        isEmailExist={isEmailExist}
                                        isDesktop={isDesktop}
                                        isMobile={isMobile}
                                    />
                                }

                                <Box sx={{ display: 'flex', flexDirection: 'column', width: isDesktop ? '35%' : '100%', gap: isMobile ? 1 : 1.5 }}>
                                    <Typography sx={{ fontSize: isMobile ? 14 : 24, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, color: theme.palette.text.primary }}>
                                        {`${t("roles")} *`}
                                    </Typography>

                                    <SelectMenuItem
                                        id={"roles"}
                                        name={"role"}
                                        value={email?.role}
                                        defaultValue={email?.role}
                                        handleChangeInput={handleChangeInput}
                                        options={rolesOption}
                                        fontSize={isMobile ? 14 : 24}
                                    />

                                </Box>

                            </DivFlexSpaceBetween>
                        </FormControl>

                        {isDesktop && (isEmailEmpty || isEmailExist || isEmailInvalid) &&
                            <ErrorMessage
                                isEmailEmpty={isEmailEmpty}
                                isEmailInvalid={isEmailInvalid}
                                isEmailExist={isEmailExist}
                                isDesktop={isDesktop}
                                isMobile={isMobile}
                            />
                        }

                        <DivFlexSpaceBetween sx={{ mt: isMobile ? 9.75 : 10, flexDirection: isMobile ? 'column-reverse' : 'row', gap: isMobile ? 1.5 : 0 }}>
                            <Typography sx={{ fontSize: isMobile ? 14 : 21, textDecoration: 'underline', fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, cursor: 'pointer', color: theme.palette.text.primary }} onClick={handleCloseDialogEditEmail} >
                                {t("cancel")}
                            </Typography>

                            <Button style={{ width: isMobile ? '100%' : '35%', py: isMobile ? '12px' : '16px', height: '' }} onClick={handleSubmit}>
                                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 24 }}>
                                    {t("save")}
                                </Typography>
                            </Button>
                        </DivFlexSpaceBetween>
                    </CustomDialogContent>
                </Box>
            </CustomDialog>

            <Outlet />
        </>

    )
}


export default EditEmailDialog