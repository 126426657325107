import React, { useContext, useEffect, useState } from 'react'
import CustomDialog from './DialogContainer/CustomDialog'
import { Box, FormControl, FormControlLabel, FormGroup, Grid, RadioGroup, Typography } from '@mui/material'
import CustomDialogContent from './DialogContainer/CustomDialogContent'
import { Outlet } from 'react-router-dom'
import { AppContext } from '../../App'
import { useTheme } from "@mui/material/styles";
import DivFlexCenter from '../DivFlexCenter'
import { FontFamily } from '../../Constants/FontFamily'
import Checkbox from '../Checkbox'
import Radio from '../Radio'
import DivFlexStart from '../DivFlexStart'
import Form from '../Form'
import DivFlexSpaceBetween from '../DivFlexSpacebetween'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import { SignUpPages } from '../../Constants/SignUpPages'
import { TimerResendActivation } from '../../Constants/TimerResendActivation'
import EmailAutomationForm from '../EmailAutomationForm'



const EmailNotificationDialog = (props) => {

    const { isMobile, isDesktop, handleCloseDialog, handleOpenDialog,
        currentPage,
        setCurrentPage,
        expiredTimer,
        setExpiredTimer,
        resendTimer,
        setResendTimer,
    } = useContext(AppContext)
    const { isOpen } = props;
    const theme = useTheme()

    const { t } = useTranslation()

    //IMPORTANT NOTES: isHourBeforeArrrival for 15minutes Flag and isTwoHoursBeforeArrrival FOR 1 and 2 hours before arriving flag, we do this so GL do not have to do any changes.
    const dummyData = {
        isDeliveryTimeFinalized: true,
        isDeliveryCanceled: true,
        isChangeInETA: false,
        isFifteenMinutesBeforeArrival: false,
        isOneHourBeforeArrival: false,
        isTwoHoursBeforeArrival: false,
        isHourAndTwoHoursBeforeArrrival: false,
        isDeliveryOnTheWay: false,
        changeInETA: null,
        etaTime: null
    }

    const [emailNotification, setEmailNotification] = useState(dummyData)

    const [allEtaEmailSelected, setAllEtaEmailSelected] = useState(false);

    const [radio, setRadio] = useState(dummyData?.changeInETA ? dummyData.changeInETA : '');
    const [etaTime, setEtaTime] = useState(dummyData?.etaTime ? dummyData.etaTime : 0);

    const [initialData, setInitialData] = useState({});

    const [isSetToDefaultDisabled, setIsSetToDefaultDisabled] = useState(true);

    const [isChangesHappened, setIsChangesHappened] = useState(false)

    const [isHourAndTwoHourEtaEmailSelected, setIsHourAndTwoHourEtaEmailSelected] = useState(false);

    useEffect(() => {
        setInitialData(dummyData)
    }, [])

    useEffect(() => {
        if (JSON.stringify(initialData) === JSON.stringify(emailNotification)) {
            setIsSetToDefaultDisabled(true)
        } else {
            setIsSetToDefaultDisabled(false)
        }
    }, [emailNotification])

    const handleIsChecked = (data) => () => {
        const value = emailNotification[data]

        setEmailNotification({
            ...emailNotification,
            [data]: !value
        })
        setIsChangesHappened(true)

        let clonedData = {
            ...emailNotification,
            [data]: !value
        }

        let isHourBeforeArrrival = clonedData.isOneHourBeforeArrival
        let isTwoHoursBeforeArrrival = clonedData.isTwoHoursBeforeArrival

        if (isTwoHoursBeforeArrrival) {
            setAllEtaEmailSelected(true)
            clonedData.isOneHourBeforeArrival = isTwoHoursBeforeArrrival
            setEmailNotification(clonedData)
        }

        if (isTwoHoursBeforeArrrival) {
            setIsHourAndTwoHourEtaEmailSelected(true)
        } else {
            setIsHourAndTwoHourEtaEmailSelected(false)
        }

        if (isTwoHoursBeforeArrrival && isHourBeforeArrrival) {
            setAllEtaEmailSelected(true)
        } else if (!isTwoHoursBeforeArrrival) {
            setAllEtaEmailSelected(false)
        }
    }

    const handleCheckAllTime = () => {
        setIsChangesHappened(true)

        setAllEtaEmailSelected(!allEtaEmailSelected)

        let clonedEmailNotification = cloneDeep(emailNotification)

        clonedEmailNotification["isOneHourBeforeArrival"] = allEtaEmailSelected === true ? false : true
        clonedEmailNotification["isTwoHoursBeforeArrival"] = allEtaEmailSelected === true ? false : true

        if (!allEtaEmailSelected) {
            setIsHourAndTwoHourEtaEmailSelected(true)
        } else {
            setIsHourAndTwoHourEtaEmailSelected(false)
        }

        setEmailNotification({ ...clonedEmailNotification })
    }

    const handleRadio = (event) => {
        setRadio(event.target.value);
    }

    const handleChangeSelect = (e) => {
        setEtaTime(e.target.value)
    }

    const options = [
        {
            value: 10,
            label: '10 mins',
        },
        {
            value: 20,
            label: '20 mins',
        },
        {
            value: 30,
            label: '30 mins',
        },
        {
            value: 40,
            label: '40 mins',
        },
        {
            value: 50,
            label: '50 mins',
        },
        {
            value: 60,
            label: '60 mins',
        },
    ];



    const handleSetToDefault = () => {
        setEmailNotification(initialData)
        setRadio(initialData.changeInETA);
        setEtaTime(initialData.etaTime)
    }

    const handleSubmit = (e) => {
        if (isChangesHappened) {
            handleOpenDialog('emailNotificationSave')
        }
        else {
            handleCloseDialog()
        }
        // setCurrentPage(SignUpPages.EMAIL_CONFIRMATION)
        // setResendTimer(TimerResendActivation.RESEND_TIMER)
        // setExpiredTimer(TimerResendActivation.EXPIRED_TIMER)
    }

    return (
        <>
            <CustomDialog width={900} open={isOpen} onClose={handleCloseDialog} theme={theme} withoutClose={true}>
                <Box sx={{ backgroundColor: theme.palette.background.dialog }}>
                    <CustomDialogContent isMobile={isMobile}>
                        <DivFlexCenter sx={{ height: isMobile ? 20 : 48, mb: isMobile ? 3 : 8, textAlign: 'center' }}>
                            <Typography sx={{ color: theme.palette.text.heading1, fontSize: isMobile ? 22 : 40, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                {t("ETAEmailAutomation")}
                            </Typography>
                        </DivFlexCenter>
                        <Box sx={{ display: 'flex', textAlign: isMobile ? 'center' : 'left' }}>
                            <Typography sx={{ color: theme.palette.text.heading1, fontSize: isMobile ? 12 : 21, fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD }}>
                                {t("latestEmailEtaNotification")}
                            </Typography>
                        </Box>
                        <Box>
                            <EmailAutomationForm
                                handleIsChecked={handleIsChecked}
                                emailNotification={emailNotification}
                                isSetToDefaultDisabled={isSetToDefaultDisabled}
                                handleSetToDefault={handleSetToDefault}
                            />
                        </Box>

                        <DivFlexSpaceBetween sx={{ mt: isMobile ? 3 : 7 }}>
                            <Button style={{ width: '100%', padding: '16px 40px', height: 'unset' }} onClick={handleSubmit}>
                                <Typography sx={{ fontFamily: FontFamily.PLUSJAKARTASANSSEMIBOLD, fontSize: isMobile ? 14 : 24 }}>
                                    {t("save")}
                                </Typography>
                            </Button>
                        </DivFlexSpaceBetween>

                    </CustomDialogContent>
                </Box>
            </CustomDialog>
            <Outlet />
        </>
    )
}

export default EmailNotificationDialog